import { UserTitles } from 'functions/src/models/db/Users';
import { Component } from 'vue-property-decorator';

import { TypedVue } from '../store/typed-vue';

@Component
export class UserMixin extends TypedVue {
  get state() {
    return this.$store.state;
  }

  get currentUserEmail() {
    return this.$store.state.currentUserEmail;
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  get isAdmin(): boolean {
    return this.currentUser?.isAdmin ?? false;
  }

  get allowShopRead(): boolean {
    return this.currentUser?.allowShopRead ?? false;
  }

  get allowPrintRead(): boolean {
    return this.currentUser?.allowPrintRead ?? false;
  }

  get allowPrintWrite(): boolean {
    return this.currentUser?.allowPrintWrite ?? false;
  }

  get allowUpload(): boolean {
    if (!this.currentUser) return false;
    if (!this.currentUser.allowedPresetsUpload) return false;
    return this.currentUser.allowedPresetsUpload.length > 0;
  }

  get allowDownloadLicensedPuzzles(): boolean {
    return this.currentUser?.allowDownloadLicensedPuzzles ?? false;
  }

  get allowDownloadExternalPuzzles(): boolean {
    return this.currentUser?.allowDownloadExternalPuzzles ?? false;
  }

  get currentUserTitles(): UserTitles {
    return this.currentUser?.titles ?? [];
  }
}
