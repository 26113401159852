







import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import NoAccess from '@/components/NoAccess.vue';

import { UserMixin } from '../mixins/User';

@Component({ components: { NoAccess } })
export default class MediaPage extends mixins(UserMixin) {}
