








import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ISamuraiSudokuPreset } from '../../../../../functions/src/models/puzzles/samuraisudoku/interfaces';

@Component
export default class SamuraiSudokuPresetEditor extends Vue {
  @PropSync('presetData') preset!: ISamuraiSudokuPreset;
}
