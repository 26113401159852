import { ITemplate } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import { FONTS } from '../../constants';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { colorCellLight, mezzaFonts, mezzaTemplates } from '../components/generic';

const scale = DEFAULT_SCALE;
const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: 2480,
      height: 3508,

      // ___json_compatible_comment: 'these values are here for easy reference :)',
      // ___unused___InnerWidth: 2304,
      // ___unused___InnerHeight: 3200,

      // ___unused___nrOfColumnParts: 36,
      // ___unused___nrOfRowParts: 49,

      margin: {
        top: 192,
        right: 0,
        bottom: 0,
        left: 88,
      },
      columns: 36,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      solutionsForThisSchema: ['content-1-1', 'content-2-1'],
      fonts: mezzaFonts,
    },
    templates: mezzaTemplates,
    elements: [
      // copyright swedish
      getCopyrightLabelByOrigin(1696, 1240, 'content-1-1'),
      // copyright quizfilippine
      getCopyrightLabelByOrigin(3008, 1256, 'content-2-1'),
      {
        type: '@content/renderer',
        path: '@content/image',
        contentId: 'puzzleAndWin',
        top: 184,
        left: 1272,
        width: 1128,
        height: 1904,
      },
      {
        type: 'line',
        lineWidth: 0.8 / scale,
        lines: [
          {
            coordinates: [
              { x: 88, y: 0.84 + 24 + 2125 },
              { x: 2304 + 88, y: 0.84 + 24 + 2125 },
            ],
          },
        ],
      },
      // Quizfillippine
      {
        type: 'custom',
        name: 'header',
        value: '@content/header',
        contentId: 'content-2-1',
        width: 384,
        left: 88,
        top: 2125,
      },
      {
        type: 'text',
        contentId: 'content-2-1',
        value: '@content/explanation',
        font: FONTS.LFT_ETICA_SEMI_BOLD,
        fontSize: 39,
        top: 2208,
        left: 88,
        width: 1128,
        height: 152,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2-1',
        renderType: 'clues',
        fontSize: 37,
        top: 2408,
        left: 88,
        width: 1168,
        height: 856,
        alignNumbers: 'right',
      },
      {
        type: '@content/renderer',
        contentId: 'content-2-1',
        renderType: 'filippine',
        blackCellColor: [0, 11, 24, 35],
        top: 2208,
        left: 1280,
        width: 1112,
        height: 768,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2-1',
        renderType: 'codeBar' as any,
        blackCellColor: [0, 11, 24, 35],
        top: 3000,
        left: 1280,
        width: 1112,
        height: 264,
      },
    ],
    rows: [
      {
        height: 1856,
        elements: [
          // {
          //   type: 'image',
          //   path: '@content/image',
          //   contentId: 'puzzleAndWin',
          //   width: 1088,
          //   height: 1856,
          //   left: 1216,
          // },
          {
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 10, y: 0.84 + 24 },
                  { x: 2304 - 650, y: 0.84 + 24 },
                ],
              },
            ],
          },
          {
            type: 'custom',
            name: 'header',
            value: '@content/header',
            contentId: 'content-1-1',
            width: 408,
          },
          {
            // swedish
            type: 'custom',
            name: '@content/renderer',
            top: 96,
            contentId: 'content-1-1',
            width: 1152,
            height: 1728,
            align: 'right',
            renderType: 'grid',
            textCellBackgroundColor: colorCellLight,
            blackCellColor: [0, 0, 0, 0],
            font: 'Helvetica.ttf',
            fontSize: 30,
            fontSizeFactor: 0.167871014, // makes 5.5
          } as any,
          {
            type: 'custom',
            name: '@content/renderer',
            contentId: 'content-1-1',
            top: 1792,
            height: 104,
            width: 640,
            align: 'left',
            font: FONTS.LFT_ETICA_REGULAR,
            renderType: 'bar',
            answerBarFont: FONTS.LFT_ETICA_REGULAR,
            answerBarFontSize: 6,
            answerBarCellOffsetTopModifier: 0.01,
          },
        ],
      },

      {
        height: 1152,
        elements: [
          // {
          //   type: 'custom',
          //   name: '@content/renderer',
          //   top: 96,
          //   contentId: 'content-2-1',
          //   width: 2304,
          //   height: 1056,
          //   valign: 'top',
          //   align: 'right',
          //   fontSize: 38,
          //   cluesView: 'newLine',
          // },
          {
            type: 'custom',
            name: 'page-identifier',
            top: 1177,
            left: 2104,
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
        ],
      },
    ],
  },
};

export default layout;
