import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import config from '../components/config';
import {
  copyright,
  getCopyrightLabel,
  getHardcodedHeader,
  smallDifficulty,
  smallText,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const swedishCrossword: AnyElement[] = [
  ...getHardcodedHeader(0, 0, 3152, 'Zweedse puzzel'),
  // Date of today
  {
    type: 'text',
    value: '@DD-@MM-@YY',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    color: config.colorWhite,
    align: 'right',
    baseline: 'middle',
    top: 52,
    left: 2800,
    width: 320,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'grid',
    highlightType: 'circle',
    align: 'center',
    gridBackgroundColor: config.colorWhite,
    blackCellColor: config.colorBlueBackground,
    circleCellBackgroundColor: config.colorGreyLight,
    textCellBackgroundColor: config.colorBlack,
    separatorOffsetLeft: 1,
    fontSizeFactor: 0.195,
    top: 80,
    // image is 3072 x 3226, calculate corresponding width & left
    left: (config.width - (3264 / 3226) * 3072) / 2,
    width: (3264 / 3226) * 3072,
    height: 3264,
  },
  {
    type: 'box',
    backgroundColor: config.colorWhite,
    top: 1328,
    left: 955,
    width: 1237,
    height: 1080,
  },
  {
    type: '@content/renderer',
    contentId: 'prize-banner',
    top: 1328,
    left: 955,
    width: 1237,
    height: 1080,
  },

  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 3344,
    left: 0,
    width: 3152,
    height: 224,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'bar',
    highlightType: 'circle',
    gridBackgroundColor: config.colorWhite,
    top: 3368,
    left: 16,
    width: 752,
    height: 120,
  },
  {
    type: '@content/renderer',
    contentId: 'supplier-banner',
    top: 3356,
    left: 792,
    width: 2320,
    height: 212,
  },
  getCopyrightLabel(3296, 3120, 'Puzzelaar'),
];

const crossword: AnyElement[] = [
  ...getHardcodedHeader(3488, 0, 768, 'Kruiswoord'),
  {
    type: '@content/renderer',
    contentId: 'content-2',
    onlyText: true,
    fontSize: 8 / DEFAULT_SCALE,
    top: 3584,
    left: 16,
    width: 1152,
    height: 816,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    onlyGrid: true,
    blackCellColor: config.colorBlueBackground,
    top: 3594,
    left: 1706,
    width: 1424,
    height: 816,
  },
  {
    type: 'custom',
    name: 'copyright',
    top: 4140,
    left: 1676,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN - Saturday 2 template',
    layout: layout,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2'],
      ...settings,
    },
    templates: {
      smallText,
      smallDifficulty,
      copyright,
    },
    elements: [...swedishCrossword, ...crossword],
  },
};

export default layoutTemplate;
