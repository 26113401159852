import { ContentTemplate } from '../../../models/puzzles/ContentTemplate';

const puzzles: ContentTemplate = {
  'content-1': {}, // Woordzoeker
  'content-2': {}, // Tectonic
  'content-3': {}, // PAC-MAN Puzzel
  'content-4': {}, // Zeeslag 1
  'content-5': {}, // Zeeslag 2
  'content-6': {}, // Tentje Boompje,
  'content-7': {}, // Oh No
  'content-8': {}, // Killer Sudoku
  'content-9': {}, // Koprol
  'content-10': {}, // Quento
  'play-image': {},
};

export default puzzles;
