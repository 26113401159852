import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import contentSat134 from './content/trouw-saturday-1-3-and-4.json';
import contentSat2 from './content/trouw-saturday-2.json';
import contentWeekdays from './content/trouw-weekdays-content.json';
import monday from './layout/1-maandag-trouw';
import tuesday from './layout/2-dinsdag-trouw';
import wednesday from './layout/3-woensdag-trouw';
import thursday from './layout/4-donderdag-trouw';
import friday from './layout/5-vrijdag-trouw';
import sat1 from './layout/6-zaterdag-trouw-1';
import sat2 from './layout/6-zaterdag-trouw-2';
import sat3 from './layout/6-zaterdag-trouw-3';
import sat4 from './layout/6-zaterdag-trouw-4';

const trouwLayouts: LayoutDb[] = [
  {
    id: layoutIds.trouwMaandag,
    name: 'Trouw - 1. Maandag',
    json: monday,
    content: contentWeekdays,
  },
  {
    id: layoutIds.trouwDinsdag,
    name: 'Trouw - 2. Dinsdag',
    json: tuesday,
    content: contentWeekdays,
  },
  {
    id: layoutIds.trouwWoensdag,
    name: 'Trouw - 3. Woensdag',
    json: wednesday,
    content: contentWeekdays,
  },
  {
    id: layoutIds.trouwDonderdag,
    name: 'Trouw - 4. Donderdag',
    json: thursday,
    content: contentWeekdays,
  },
  {
    id: layoutIds.trouwVrijdag,
    name: 'Trouw - 5. Vrijdag',
    json: friday,
    content: contentWeekdays,
  },
  {
    id: layoutIds.trouwZaterdag1,
    name: 'Trouw - 6. Zaterdag-1',
    json: sat1,
    content: contentSat134,
  },
  {
    id: layoutIds.trouwZaterdag2,
    name: 'Trouw - 6. Zaterdag-2',
    json: sat2,
    content: contentSat2,
  },
  {
    id: layoutIds.trouwZaterdag3,
    name: 'Trouw - 6. Zaterdag-3',
    json: sat3,
    content: contentSat134,
  },
  {
    id: layoutIds.trouwZaterdag4,
    name: 'Trouw - 6. Zaterdag-4',
    json: sat4,
    content: contentSat134,
  },
];

export default trouwLayouts;
