











































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import Login from '@/components/Login.vue';
import NavBar from '@/components/NavBar.vue';
import StudioBar from '@/components/StudioBar.vue';

import AssetModal from './components/media/AssetModal.vue';
import { UserMixin } from './mixins/User';
import { db, env } from './store/firestore-config';

@Component({
  components: {
    Login,
    NavBar,
    StudioBar,
    AssetModal,
  },
})
export default class App extends mixins(UserMixin) {
  env = env;
  db = db;

  get globalLoading() {
    return this.$store.state.globalLoading;
  }

  get banner() {
    if (env === 'LOCAL') {
      return `${env} (${db})`;
    }
    return env;
  }
}
