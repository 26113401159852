import { getDisplayNameByRenderer } from '../../utils/puzzleUtils';
import { IPuzzleId } from '../puzzles/IPuzzles';

interface IPageAction {
  schedulerId: string;
  publicationDate: string;
}

interface IDownloadPageAction extends IPageAction {
  actionType: 'downloadPage';
  user: string;
  setsPageToDownloaded: boolean;
  downloadsStorageUrl?: string; // Not strictly necessary, but used to determine if a download link exists
}

interface IRegeneratePageAction extends IPageAction {
  actionType: 'regeneratePage';
}

interface IResetPageToUnusedAction extends IPageAction {
  actionType: 'resetPageToUnused';
  user: string;
}

interface IRejectPuzzle extends IPageAction {
  actionType: 'rejectPuzzle';
  renderer: IPuzzleId;
  contentKey: string;
  user: string;
}

// To ad a type use a discriminating type so its nicely typed.
export type IPageActionLog =
  | IDownloadPageAction
  | IRegeneratePageAction
  | IRejectPuzzle
  | IResetPageToUnusedAction;

export type ActionLogDb = { date: string } & IPageActionLog;

export const actionLogDateFormat = 'yyyy-MM-dd HH:mm:ss';

export const getPageActionMessage = (log: IPageActionLog) => {
  switch (log.actionType) {
    case 'downloadPage':
      return `Gedownload  ${log.setsPageToDownloaded ? 'en status op gebruikt gezet' : ''} door: ${
        log.user
      }.`;
    case 'rejectPuzzle':
      return `${getDisplayNameByRenderer(log.renderer)} (${log.contentKey}) afgekeurd door: ${
        log.user
      }`;
    case 'regeneratePage':
      return 'Pagina opnieuw gegenereerd.';
    case 'resetPageToUnused':
      return `Status veranderd naar ongebruikt door: ${log.user}.`;
  }
};
