export const firebaseConfig = {
  apiKey: 'AIzaSyCR5ZGv39GHqsSdyGl_III8lyS1uDtrNxE',
  authDomain: 'q42puzzels.firebaseapp.com',
  databaseURL: 'https://q42puzzels.firebaseio.com',
  projectId: 'q42puzzels',
  storageBucket: 'q42puzzels.appspot.com',
  messagingSenderId: '755738922582',
  appId: '1:755738922582:web:b88fddae8c5759a27f35f7',
  measurementId: 'G-E83CQ2951R',
};

export const functionsUrl = 'https://europe-west2-q42puzzels.cloudfunctions.net/';

export const env: 'LOCAL' | 'TEST' | 'PROD' = 'PROD';
export const db: 'EMULATOR' | 'TEST' | 'PROD' = 'PROD';
