import { assertNever } from '../../utils';
import { getDisplayNameByRenderer } from '../../utils/puzzleUtils';
import { PuzzlePresetDb } from '../db/PuzzlePreset';
import { getDefaultPresetDataBattleship } from './battleship/utils';
import { getDefaultPresetDataBlokjeOm } from './blokjeom/utils';
import { getDefaultPresetDataCalcudoku } from './calcudoku/utils';
import { getDefaultPresetDataGroterKleiner } from './groterkleiner/utils';
import { getDefaultPresetDataHashi } from './hashi/utils';
import { getDefaultPresetDataIkura } from './ikura/utils';
import { getDefaultPresetDataImagePuzzle } from './imagepuzzle/utils';
import { IPuzzleId } from './IPuzzles';
import { getDefaultPresetDataKakuro } from './kakuro/utils';
import { getDefaultPresetDataPacMan } from './pacman/utils';
import { getDefaultPresetDataSamuraiSudoku } from './samuraisudoku/utils';
import { getDefaultPresetDataStepLadder } from './stepladder/utils';
import { getDefaultPresetDataSudokuMix } from './sudokumix/utils';
import { getDefaultPresetDataTectonic } from './tectonic/utils';
import { getDefaultPresetDataTentsTrees } from './tentstrees/utils';
import { getDefaultPresetDataToevoeganagram } from './toevoeganagram/utils';
import { getDefaultPresetDataWhoWhatWhere } from './whowhatwhere/utils';
import { getDefaultPresetDataWoordKraker } from './woordkraker/utils';
import { getDefaultPresetDataWordfind } from './wordfind/utils';

export function getDefaultPresetData(renderer: IPuzzleId): PuzzlePresetDb {
  const name = getDisplayNameByRenderer(renderer);
  const header = name.toUpperCase();
  switch (renderer) {
    case 'wordfind':
      return getDefaultPresetDataWordfind();
    case 'tectonic':
      return getDefaultPresetDataTectonic();
    case 'tentstrees':
      return getDefaultPresetDataTentsTrees();
    case 'battleship':
      return getDefaultPresetDataBattleship();
    case 'quento':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'Rechts staan negen antwoorden. Zoek bij elk antwoord de som op het spelbord. Ga van cijfer naar plus of min, weer naar een cijfer en zo verder indien nodig. Gebruik geen vakje twee keer. Er zijn meerdere oplossingen mogelijk.',
        },
      };
    case 'koprol':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'custom',
        needsApproval: true,
        generatorParams: {
          feeds: ['https://www.ad.nl/show/rss.xml'],
          language: 'nl',
          sizes: [
            { width: 7, height: 5 },
            { width: 8, height: 6 },
            { width: 9, height: 6 },
            { width: 10, height: 7 },
            { width: 11, height: 8 },
            { width: 12, height: 8 },
          ],
          maxTail: 2,
        },
        rendererParams: {
          header,
          explanation:
            'Een actuele krantenkop is uitgerold. Speel de Koprol en je weet om welk nieuws het gaat! \n\nVerbind alle letters en spaties (de rode stippen) om de zin te vormen. Begin bij de letter met de blauwe cirkel.',
        },
      };
    case '0hh1':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          size: 8,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'Er mogen maximaal 2 dezelfde naast elkaar. Elke rij en kolom moet even vaak een 0 als een 1 hebben. Geen twee rijen, en twee kolommen, zijn hetzelfde.',
        },
      };
    case '0hn0':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          size: 8,
        },
        rendererParams: {
          header,
          explanation:
            'Het getal in een cirkel geeft aan hoeveel andere witte cirkels deze "ziet", horizontaal en verticaal. Zwarte cirkels blokkeren het zicht. Teken alle overige witte en zwarte cirkels.',
        },
      };
    case 'crossword':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {
          width: 14,
          height: 11,
          wordPool: 'nl',
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'Vul het woord dat past bij de omschrijving in op het grid. Het getal voor de omschrijving verwijst naar het getal in de linker (rij) of bovenste (kolom) vakje van de puzzel. Het getal er achter naar de lengte van het woord.',
          hideWordCount: false,
        },
      };
    case 'sudoku':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          size: 9,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'In elk vakje hoort een cijfer van 1 tot en met 9 te staan. Vul de puzzel zo in, dat in elke rij, elke kolom en elk blok van 3 keer 3 vakjes de cijfers 1 tot en met 9 één keer voorkomen.',
        },
      };
    case 'lettersudoku':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: true,
        generatorParams: {
          size: 9,
          difficulty: 1,
          wordPool: 'nl',
        },
        rendererParams: {
          header,
          explanation: 'Gebruik in plaats van cijfers de letters: ${letters}',
        },
      };
    case 'horoscopesudoku':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          difficulty: 1,
          size: 6,
        },
        rendererParams: {
          header,
          explanation:
            'Vul het raster zo in dat elke rij, elke kolom en elk blokje van zes vakjes de zes verschillende symbolen bevatten. In de rij of kolom die overeenkomt met je sterrenbeeld staan de zes symbolen in toenemende orde van belangrijkheid: van 1 (minder belangrijk) tot en met 6 (zeer belangrijk).',
        },
      };
    case 'doorloper':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {
          width: 11,
          height: 17,
        },
        rendererParams: {
          header,
          explanation: 'Vul de gevraagde woorden achter elkaar in op elke rij en kolom.',
        },
      };
    case 'codekraker':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {
          width: 23,
          height: 11,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'Elk getal staat voor een letter en gelijke cijfers zijn gelijke letters. De gegeven letters komen minimaal een keer in het diagram voor. Om u op weg te helpen hebben wij alvast enkele letters ingevuld.',
        },
      };
    case 'knightmove':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: true,
        generatorParams: {
          wordPool: 'ad',
        },
        rendererParams: {
          header,
          explanation:
            'Maak zeven paardensprongen – net zoals bij schaken – om een achtletterig woord te onthullen.',
        },
      };
    case 'cryptofilippine':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {
          width: 18,
          height: 11,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation: '',
        },
      };
    case 'swedishcrossword':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {
          width: 12,
          height: 7,
          difficulty: 1,
          hasSolutionWord: true,
        },
        rendererParams: {
          header,
          explanation:
            'Vul de woorden in volgens de omschrijvingen. In ieder vakje gaat één letter, letter IJ wordt als één letter gezien.',
        },
      };
    case 'samuraisudoku':
      return getDefaultPresetDataSamuraiSudoku();
    case 'killersudoku':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'seed',
        needsApproval: false,
        generatorParams: {
          size: 9,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'De som van alle getallen in een vlak moet gelijk zijn aan het getal in de linker bovenhoek. In elk vlak mag een cijfer maar 1 keer voorkomen.',
        },
      };
    case 'filippine':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: true,
        generatorParams: {
          width: 18,
          height: 11,
          difficulty: 3,
        },
        rendererParams: {
          header,
          explanation: '',
        },
      };
    case 'jigsawCrossword':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: true,
        generatorParams: {
          width: 13,
          height: 11,
          difficulty: 1,
        },
        rendererParams: {
          header,
          explanation:
            'Leg alle woorden op de juiste plaats op het raster. Eén woord krijgt u alvast cadeau.',
        },
      };
    case 'spotthedifferences':
      return {
        renderer,
        name,
        stock: {
          available: 0,
          approved: 0,
        },
        usage: {
          expectedWeeklyUse: 0,
        },
        source: 'upload',
        needsApproval: false,
        generatorParams: {},
        rendererParams: {
          header,
          explanation: '',
        },
      };
    case 'toevoeganagram':
      return getDefaultPresetDataToevoeganagram();
    case 'kakuro':
      return getDefaultPresetDataKakuro();
    case 'calcudoku':
      return getDefaultPresetDataCalcudoku();
    case 'hashi':
      return getDefaultPresetDataHashi();
    case 'groterkleiner':
      return getDefaultPresetDataGroterKleiner();
    case 'whowhatwhere':
      return getDefaultPresetDataWhoWhatWhere();
    case 'blokjeom':
      return getDefaultPresetDataBlokjeOm();
    case 'ikura':
      return getDefaultPresetDataIkura();
    case 'sudokutwins':
      return getDefaultPresetDataSudokuMix();
    case 'sudokumix':
      return getDefaultPresetDataSudokuMix();
    case 'woordkraker':
      return getDefaultPresetDataWoordKraker();
    case 'pacman':
      return getDefaultPresetDataPacMan();
    case 'imagepuzzle':
      return getDefaultPresetDataImagePuzzle();
    case 'stepladder':
      return getDefaultPresetDataStepLadder();
    default:
      assertNever(renderer);
  }
}
