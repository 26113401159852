import { ITemplate } from '../../../models/ITemplate';
import { ADR, DEFAULT_SCALE } from '../../config';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { pageFonts, pageIdentifier, pageLayout } from '../components/generic';

const layout: ITemplate = {
  page: {
    layout: pageLayout,
    settings: {
      solutionsFromOtherSchema: true,
      solutionsForThisSchema: ['content-2-1', 'content-3-1', 'content-3-2', 'content-3-3'],
      fonts: pageFonts,
    },
    templates: {
      header: {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: [0, 20, 0, 72],
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 12,
            baseline: 'top',
          },
        ],
      },
      'highlight-header': {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: ADR.HighlightHeaderBackgroundColor,
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 12,
            baseline: 'top',
          },
        ],
      },
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 25,
            height: 24,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
        ],
      },
      wordfind: {
        elements: [
          {
            type: 'wordfind',
            font: '@page/settings/fonts/text',
            value: '@content/wordfind',
            fontSize: 38,
            lineGap: 24,
            wordsHeight: 705,
          },
        ],
      },
      crossword: {
        elements: [
          {
            type: 'crossword',
            font: '@page/settings/fonts/text',
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 34,
          },
        ],
      },
      quento: {
        elements: [
          {
            log: true,
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/quento',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'quento',
            valign: 'bottom',
          },
        ],
      },
      koprol: {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/koprol',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'koprol',
            font: '@page/settings/fonts/text',
            valign: 'bottom',
          },
        ],
      },
      '0hn0': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/0hn0',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            width: 689,
            left: 759,
          },
          {
            type: '0hn0',
          },
        ],
      },
      'page-identifier': {
        elements: pageIdentifier,
      },
    },
    rows: [
      {
        height: 1024,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'highlight-header',
                value: '@content/header',
                contentId: 'content-1-1',
                width: 1664,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                contentId: 'content-1-1',
                width: 1664,
                height: 896,
                columns: 2,
                align: 'right',
                hideSolutionGrid: true,
              },
              {
                // __comment: 'floating image, does not have to be in grid system',
                type: 'image',
                path: '@content/image',
                contentId: 'brand-image',
                width: 600,
                height: 200,
                left: 5,
                top: 825,
              },
              {
                type: 'image',
                path: '@content/image',
                contentId: 'puzzleAndWin',
                width: 1460,
                height: 1024,
                left: 1728,
              },
            ],
          },
        ],
      },

      {
        height: 1344,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: -32 },
                  { x: 3200, y: -32 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-2-1',
                width: 1472,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-2-1',
                height: 1216,
                width: 1442,
                top: 128,
                onlyText: true,
                useImagePuzzle: true,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-2-1',
                height: 1216,
                width: 1698,
                top: 128,
                left: 1500,
                offsetPuzzle: [-128, 0],
                onlyGrid: true,
              },
            ],
          },
        ],
      },

      {
        height: 1984,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: -30 },
                  { x: 3200, y: -30 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-1',
                width: 832,
              },
              {
                type: '@content/renderer',
                contentId: 'content-3-1',
                top: 128,
                left: 0,
                width: 832,
                height: 832,
                valign: 'top',
              },
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-2',
                width: 832,
                top: 1024,
              },
              {
                type: '@content/renderer',
                contentId: 'content-3-2',
                top: 1152,
                left: 0,
                width: 832,
                height: 832,
                valign: 'top',
              },
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-3',
                width: 2304,
                left: 896,
              },
              // {
              //   type: '@content/renderer',
              //   contentId: 'content-3-3',
              //   top: 128,
              //   left: 896,
              //   width: 2304,
              //   height: 1300,
              //   valign: 'top',
              //   align: 'right',
              // },
              {
                type: 'line',
                lines: [
                  {
                    coordinates: [
                      { x: 896 + 1200, y: 1472.67 },
                      { x: 3200, y: 1472.67 },
                    ],
                  },
                ],
              },
              {
                // __comment: 'De oplossingen starten halverwege een gridrij',
                type: 'custom',
                name: 'header',
                value: 'OPLOSSINGEN PUZZELS VORIGE DAG',
                width: 1200,
                left: 896,
                top: 1472,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-2-1',
                width: 576,
                top: 1600,
                left: 896,
              },
              {
                type: '@content/renderer',
                top: 1632,
                width: 576,
                height: 352,
                left: 896,
                valign: 'top',
                contentId: 'solution-1-2-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-3-1',
                width: 736,
                top: 1600,
                left: 1505,
              },
              {
                type: '@content/renderer',
                top: 1632,
                width: 736,
                height: 352,
                left: 1505,
                valign: 'top',
                contentId: 'solution-1-3-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-4-1',
                width: 352,
                top: 1600,
                left: 2273,
              },
              {
                type: '@content/renderer',
                top: 1632,
                width: 352,
                height: 352,
                left: 2273,
                valign: 'top',
                contentId: 'solution-1-4-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-4-2',
                width: 544,
                top: 1600,
                left: 2657,
              },
              {
                type: '@content/renderer',
                top: 1632,
                width: 544,
                height: 352,
                left: 2657,
                valign: 'top',
                contentId: 'solution-1-4-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'page-identifier',
                top: 1472,
                left: 3200 - 200,
              },
            ],
          },
        ],
      },
    ],
    elements: [
      // wordfind
      getCopyrightLabelByOrigin(1024, 1820, 'content-1-1'),
      // crossword
      getCopyrightLabelByOrigin(2424, 1620, 'content-2-1'),
      // filippine
      getCopyrightLabelByOrigin(3920, 1696, 'content-3-3'),
      // sudokus
      getCopyrightLabelByOrigin(3456, 992, 'content-3-1'),
      getCopyrightLabelByOrigin(4480, 992, 'content-3-2'),

      // cryptofilippine
      {
        type: 'text',
        value: '@content/explanation',
        font: '@page/settings/fonts/textbold',
        fontSize: 8 / DEFAULT_SCALE,
        contentId: 'content-3-3',
        top: 2872,
        left: 1048,
        width: 640,
        height: 112,
      },
      {
        type: '@content/renderer',
        contentId: 'content-3-3',
        renderType: 'clues',
        fontSize: 8 / DEFAULT_SCALE,
        lineGap: 10 * DEFAULT_SCALE,
        top: 3016 - 80,
        left: 1048,
        width: 640,
        height: 1168,
      },
      {
        type: '@content/renderer',
        contentId: 'content-3-3',
        renderType: 'filippine',
        blackCellColor: [0, 11, 24, 35],
        top: 2872,
        left: 1720,
        width: 1632,
        height: 1008,
      },
      {
        type: '@content/renderer',
        contentId: 'content-3-3',
        renderType: 'codeBar' as any,
        blackCellColor: [0, 11, 24, 35],
        top: 3904,
        left: 1720,
        width: 1632,
        height: 280,
      },
    ],
  },
};

export default layout;
