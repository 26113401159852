import { IGeneratedHashi, IHashiPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const HashiName = 'Bruggen bouwen';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedHashiIdInput(generated: IGeneratedHashi): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataHashi(): IHashiPreset {
  return {
    renderer: 'hashi',
    name: HashiName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      width: 10,
      height: 10,
    },
    rendererParams: {
      header: HashiName.toUpperCase(),
      explanation:
        'Verbind alle cirkels met horizontale of verticale rechte lijnen in één aaneengesloten groep. ' +
        'De cijfers tonen hoeveel lijnen met een cirkel verbonden zijn. ' +
        'Er kunnen niet meer dan twee lijnen in eenzelfde richting getrokken woden en de lijnen kunnen geen cirkels of andere lijnen kruisen.',
    },
  };
}
