import { AnyElement, IImageElement, ITemplate } from '../../../models/ITemplate';
import { getBlockWWW } from '../components/block-www';
import config from '../components/config';
import {
  copyright,
  copyrightQ42,
  getBox,
  getDifficulty,
  getHeader,
  getStaticDifficulty,
  smallDifficulty,
  smallText,
  staticSmallText,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';
import { blokjeOm } from './hln-saturday-1';

const wordFind: AnyElement[] = [
  // Content-1: wordfind
  ...(getHeader(0, 0, 1696, 80, 'content-1', undefined, '@content/header: @content/theme') as any),
  // Date of today
  {
    type: 'text',
    value: '@DD-@MM-@YY',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    color: config.colorWhite,
    align: 'right',
    baseline: 'middle',
    top: 52,
    left: 1344,
    width: 320,
    height: 48,
  },
  ...getBox(80, 0, 1696, 1376),
  {
    type: 'text',
    value: '@DD-@MM-@YY',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    align: 'right',
    baseline: 'middle',
    top: 48,
    left: 1632,
    width: 320,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    columns: 1,
    lineGap: -1,
    characterSpacing: 2,
    onlyText: true,
    hideSolutionGrid: true,
    top: 112,
    left: 32,
    width: 400,
    wordsHeight: 1344,
  },
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 112,
    left: 464,
    width: 1200,
    height: 112,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    hideSolutionGrid: true,
    gridBackgroundColor: config.colorWhite,
    font: config.fontBold,
    top: 208,
    left: 464,
    width: 1168,
    height: 1152,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlySolutionGrid: true,
    solutionGridType: 'circle',
    gridBackgroundColor: config.colorWhite,
    top: 1364,
    left: 460,
    width: 1120,
    height: 88,
  },
  {
    type: 'custom',
    name: 'copyright',
    top: 1104,
    left: 1632,
  },
];

const binaireBlocks: AnyElement[] = [
  ...(getHeader(1488, 0, 608, 80, 'content-3', 'dense') as any),
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 1584,
    left: 16,
    width: 576,
    height: 624,
  },
  {
    type: 'custom',
    name: 'copyrightQ42',
    top: 2040,
    left: 592,
  },

  ...getStaticDifficulty(1488, 624, 752, 56, 'Gemiddeld'),
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1542,
    left: 624,
    width: 752,
    height: 752,
  },

  ...getStaticDifficulty(1488, 1408, 752, 56, 'Gevorderden'),
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1542,
    left: 1408,
    width: 752,
    height: 752,
  },
];

const sudokuBlocks: AnyElement[] = [
  ...(getHeader(2304 + 12, 0, 608, 80, 'content-5', 'dense') as any),
  {
    type: 'text',
    contentId: 'content-5',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 2400 + 12,
    left: 16,
    width: 576,
    height: 624,
  },
  {
    type: 'custom',
    name: 'copyrightQ42',
    top: 2864 + 8,
    left: 592,
  },

  ...getDifficulty(2304 + 12, 624, 752, 56, 'content-5'),
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 2360 + 12,
    left: 624,
    width: 752,
    height: 752,
  },

  ...getDifficulty(2304 + 12, 1408, 752, 56, 'content-6'),
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 2360 + 12,
    left: 1408,
    width: 752,
    height: 752,
  },
];

const hashiBlocks: AnyElement[] = [
  ...(getHeader(3136 + 12, 0, 608, 80, 'content-7', 'dense') as any),
  {
    type: 'text',
    contentId: 'content-7',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 3232 + 12,
    left: 16,
    width: 576,
    height: 624,
  },
  {
    type: 'custom',
    name: 'copyright',
    top: 3696,
    left: 592,
  },

  ...getDifficulty(3136 + 12, 624, 752, 56, 'content-7'),
  {
    type: '@content/renderer',
    contentId: 'content-7',
    fontSizeFactor: 0.6,
    circleSizeFactor: 0.85,
    top: 3192 + 12,
    left: 624,
    width: 752,
    height: 752,
  },

  ...getDifficulty(3136 + 12, 1408, 752, 56, 'content-8'),
  {
    type: '@content/renderer',
    contentId: 'content-8',
    fontSizeFactor: 0.6,
    circleSizeFactor: 0.85,
    top: 3192 + 12,
    left: 1408,
    width: 752,
    height: 752,
  },
];

const solutionBlocks: AnyElement[] = [
  ...(getHeader(3974, 0, 2160, 80, null, 'dense', 'Oplossingen @SS') as any),
  // S:1-1 - Wordfind
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-1',
    top: 4096 - 32,
    left: 0,
    width: 528,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    textCellBackgroundColor: config.colorGreyLight,
    solution: true,
    onlyGrid: true,
    markWordsVisually: true,
    top: 4096,
    left: 0,
    width: 300,
    height: 592,
  },
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/solution',
    color: config.colorBlack,
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    baseline: 'top',
    lineGap: -1,
    top: 4380,
    left: 0,
    width: 300,
    height: 32,
  },

  // S:1-4 & 1-5 - Binaire
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-4',
    top: 4096 - 32,
    left: 328,
    width: 240,
    height: 32,
  },
  {
    type: 'custom',
    name: 'staticSmallText',
    value: 'Gemiddeld',
    top: 4096 - 32,
    left: 328 + 100,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 4096,
    left: 328,
    width: 280,
    height: 280,
  },
  {
    type: 'custom',
    name: 'staticSmallText',
    value: 'Gevorderden',
    top: 4096 - 32,
    left: 640,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 4096,
    left: 640,
    width: 280,
    height: 280,
  },

  // S:1-6 & 1-7 - Sudoku
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-6',
    top: 4096 - 32,
    left: 952,
    width: 240,
    height: 32,
  },
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-6',
    top: 4096 - 32,
    left: 952 + 108,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 4096,
    left: 952,
    width: 280,
    height: 280,
  },
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-7',
    top: 4096 - 32,
    left: 1264,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    solution: true,
    top: 4096,
    left: 1264,
    width: 280,
    height: 280,
  },

  // S:1-3 - WWW
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-3',
    top: 4096 - 32,
    left: 1600,
    width: 272,
    height: 80,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    renderType: 'answers',
    solution: true,
    top: 4096 + 40,
    left: 1600,
    width: 272,
    height: 512,
  },
];

const sudokuHoroscope: AnyElement[] = [
  ...(getHeader(1488, 2192, 960, 80, 'content-9', 'dense') as any),
  ...getBox(1568, 2192, 960, 1552),
  {
    type: 'text',
    contentId: 'content-9',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 1584,
    left: 2224,
    width: 896,
    height: 240,
  },
  {
    type: '@content/renderer',
    contentId: 'content-9',
    renderType: 'grid',
    gridBackgroundColor: config.colorWhite,
    top: 1856,
    left: 2224,
    width: 896,
    height: 896,
  },
  {
    type: '@content/renderer',
    contentId: 'content-9',
    renderType: 'legend',
    font: config.fontRegular,
    fontSize: 28,
    top: 2768,
    left: 2224,
    width: 896,
    height: 336,
  },
  {
    type: 'custom',
    name: 'copyrightQ42',
    top: 2848,
    left: 3120,
  },
];

const imagePencil: IImageElement = {
  type: 'image',
  path: 'img/hln/pencil.png',
  top: 1158,
  left: 2832,
  width: 192,
  height: 416,
};

const bridgePencil: IImageElement = {
  type: 'image',
  path: 'img/hln/bridge.png',
  top: 2812,
  left: 64,
  width: 464,
  height: 336,
};

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN - Saturday 1 template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
        'content-8',
        'content-9',
        'content-10',
      ],
      ...settings,
    },
    templates: {
      smallText,
      smallDifficulty,
      copyright,
      copyrightQ42,
      staticSmallText,
    },
    elements: [
      ...wordFind,
      ...getBox(80, 1728, 1424, 1376),
      ...getBlockWWW('content-2'),
      ...binaireBlocks,
      ...sudokuBlocks,
      ...hashiBlocks,
      bridgePencil,
      ...sudokuHoroscope,
      imagePencil,
      ...blokjeOm,
      ...solutionBlocks,
    ],
  },
};

export default layoutTemplate;
