import { PageDb } from '../models/db/Page';
import { Content, PuzzlePresetGenerated } from '../models/puzzles/Content';
import { ContentTemplateImage, ContentTemplateText } from '../models/puzzles/ContentTemplate';

export function getVariantContent(
  puzzlePage: Pick<PageDb, 'content'> & Partial<Pick<PageDb, 'variants'>>,
  variant: string,
  pageIndex = 0
): Content {
  const variantContent = {
    ...(puzzlePage.content[pageIndex] as Content),
  };
  if (!puzzlePage.variants) {
    return variantContent;
  }
  const v = (puzzlePage.variants as Record<string, { content: Content }>)[variant]?.content;
  if (v) {
    Object.keys(v).forEach((key) => {
      // if it's a puzzle and it's filled
      const vc = (v as Record<string, PuzzlePresetGenerated>)[key];
      if (vc && vc.chosenPuzzlePresetId && vc.generatorParams && vc.generatorParams.id) {
        variantContent[key] = vc;
      }

      // or if it's an image or tekst
      const vit = (v as Record<string, ContentTemplateImage | ContentTemplateText>)[key];
      if (vit && (vit.renderer === 'image' || vit.renderer === 'text')) {
        variantContent[key] = vc;
      }
    });
  } else {
    console.error('Variant not found', { variant, variants: Object.keys(puzzlePage.variants) });
  }

  return variantContent;
}
