














import firebase from 'firebase/compat';
import { Component, Prop } from 'vue-property-decorator';

import { TypedVue } from '../../../../store/typed-vue';

@Component({})
export default class ScreenShotButtonComponent extends TypedVue {
  @Prop() row!: any;

  loadingScreenshot = false;

  async navigateToScreenshot(fileName: string) {
    this.loadingScreenshot = true;
    const url = await firebase
      .storage()
      .ref('rejection-assets/' + fileName)
      .getDownloadURL();

    this.loadingScreenshot = false;
    window.open(url, '_blank');
  }
}
