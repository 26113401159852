import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import {
  getHardcodedHeader,
  getSubTitle,
  getText,
  makeLine,
  smallText,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

// TODO: Fix de -16 + X hack een keer als nodig is. Die is erin gezet omdat de headers ineens 16 pixels lager moesten.

const wordFind: AnyElement[] = [
  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 80,
    left: 0,
    width: 2368,
    height: 1100,
  },
  ...(getHardcodedHeader(0, 0, 700, 'Woordzoeker') as any),
  makeLine(94 - 16, 700, 1666),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    gridBackgroundColor: config.colorWhite,
    onlyGrid: true,
    top: -16 + 128,
    left: 64,
    width: 1024,
    height: 1024,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyText: true,
    hideSolutionGrid: true,
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    lineGap: -1,
    characterSpacing: 2,
    top: -16 + 128,
    left: 1120,
    width: 1024,
    height: 864,
  },
  {
    type: 'text',
    value: 'Welk woord vormen de overgebleven letters?',
    top: -16 + 992,
    left: 1120,
    width: 800,
    height: 64,
  },
  {
    type: '@content/renderer',
    onlySolutionGrid: true,
    contentId: 'content-1',
    gridBackgroundColor: config.colorWhite,
    font: config.fontBold,
    valign: 'bottom',
    top: -16 + 1056,
    left: 1120,
    width: 1024,
    height: 96,
  },
  {
    type: 'image',
    path: 'img/hln/magnifier.png',
    top: -16 + 776,
    left: 2112,
    width: 192,
    height: 416,
  },
];

const tectonic: AnyElement[] = [
  ...getHardcodedHeader(0, 2410, 736, 'Cijferblok'),
  {
    type: 'stars',
    stars: '@content/difficulty',
    contentId: 'content-2',
    activeStar: 'star-light-selected.png',
    align: 'right',
    max: 4,
    padding: 30,
    color: config.colorWhite,
    top: 19,
    left: 2700,
    width: 450,
    height: 50,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: -16 + 96,
    left: 2410,
    width: 736,
    height: 1100,
  },
];

const wordPacman: AnyElement[] = [
  ...getHardcodedHeader(1312, 0, 1056, 'PAC-MAN™ Puzzel'),
  makeLine(-16 + 1406, 1056, 544),
  {
    type: '@content/renderer',
    hideSolutionGrid: true,
    blackCellColor: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    contentId: 'content-3',
    top: -16 + 1408,
    left: 0,
    width: 848,
    height: 848,
  },
  {
    // 2022x477
    type: 'image',
    path: 'img/pacman/logo.png',
    align: 'center',
    top: 1408,
    left: 880, // + 156,
    width: 407,
    height: 96,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'description',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 1536, // 1424,
    left: 880,
    width: 688,
    height: 512,
  } as any,
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'clues',
    font: config.fontRegular,
    fontBold: config.fontBold,
    fontSize: config.fontSizeRegular,
    valign: 'bottom',
    top: 2016,
    left: 880,
    width: 688,
    height: 264,
  },
  {
    type: 'custom',
    name: 'smallText',
    value: 'PAC-MAN™&©BANDAI NAMCO Entertainment Inc.',
    top: 2248,
    left: 24,
    width: 750,
    height: 32,
  },
];

const zeeslag: AnyElement[] = [
  ...getHardcodedHeader(1312, 1632, 768, 'Zeeslag'),
  makeLine(-16 + 1406, 2400, 1600),
  ...getText(
    -16 + 1430,
    1632,
    1502,
    160,
    'De schepen liggen horizontaal of verticaal en raken elkaar nergens, ook niet diagonaal. De cijfers naast en onder geven aan hoeveel delen van de schepen zich in de rij of kolom bevinden. De legenda geeft aan hoeveel schepen er geplaatst moeten worden.'
  ),

  {
    type: '@content/renderer',
    contentId: 'content-4',
    valign: 'bottom',
    top: 1552 + 64,
    left: 1632,
    width: 672 - 32,
    height: 672 - 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    renderType: 'legendSmall',
    fontSize: 27.8,
    top: 1512 + 40,
    left: 1632,
    width: 672,
    height: 32,
  } as any,
  {
    type: '@content/renderer',
    contentId: 'content-5',
    valign: 'bottom',
    top: 1552 + 64,
    left: 2464,
    width: 672 - 32,
    height: 672 - 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    renderType: 'legendSmall',
    fontSize: 27.8,
    top: 1512 + 40,
    left: 2464,
    width: 672,
    height: 32,
  } as any,
];

const tentjeBoompje: AnyElement[] = [
  ...getHardcodedHeader(2368, 0, 768, 'Tentje Boompje'),
  ...getText(
    -16 + 2485,
    16,
    752,
    320,
    'Elke boom is verbonden met een tentje dat er direct naast, boven of onder staat. De te plaatsen tentjes raken elkaar niet, ook niet diagonaal. De cijfers naast en onder het diagram geven aan hoeveel tentjes zich in de betreffende rij of kolom bevindt.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: -16 + 2834,
    left: 32,
    width: 704,
    height: 704,
  },
];

const ohNo: AnyElement[] = [
  ...getHardcodedHeader(2368, 800, 768, 'Oh No'),
  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 2448,
    left: 800,
    width: 768,
    height: 1056,
  },
  ...getText(
    -16 + 2485,
    820,
    728,
    224,
    'Het getal in een cirkel geeft aan hoeveel andere witte cirkels deze "ziet", horizontaal en verticaal. Zwarte cirkels blokkeren het zicht. Teken alle overige witte en zwarte cirkels.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-7',
    gridBackgroundColor: config.colorWhite,
    top: -16 + 2770,
    left: 820,
    width: 728,
    height: 728,
  },
];

const killerSudoku: AnyElement[] = [
  ...getHardcodedHeader(2368, 1600, 896, 'Killer Sudoku'),
  ...getText(
    -16 + 2485,
    1600,
    896,
    160,
    'De som van alle getallen in een vlak moet gelijk zijn aan het getal in de linker bovenhoek. In elk vlak mag een cijfer maar 1 keer voorkomen.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-8',
    top: -16 + 2624,
    left: 1600,
    width: 896,
    height: 896,
  },
];

const playBanner: AnyElement[] = [
  {
    type: 'image',
    path: '@content/image',
    contentId: 'play-image',
    top: -16 + 2384,
    left: 2528,
    width: 624,
    height: 1136,
  },
];

const koprol: AnyElement[] = [
  ...getHardcodedHeader(3616, 0, 768, 'Koprol'),
  ...getText(
    -16 + 3744,
    16,
    752,
    160,
    'Vorm een spreekwoord door letters en spaties (de stippen) in de juiste volgorde te verbinden. Start bij de omcirkelde letter.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-9',
    blackCellColor: config.colorBlack,
    spaceColor: [80, 20, 0, 20],
    startTileColor: [0, 0, 0, 100],
    startTileBackgroundColor: [0, 0, 0, 0],
    startTileBorderColor: [0, 0, 0, 100],
    startTileBorderSize: 0.8,
    top: -16 + 3894,
    left: 10,
    width: 720,
    height: 537,
  },
];

const quento: AnyElement[] = [
  ...getHardcodedHeader(3616, 800, 768, 'Quento'),
  ...getText(
    -16 + 3744,
    800,
    752,
    288,
    'Rechts staan negen antwoorden. Zoek bij elk antwoord de som op het spelbord. Ga van cijfer naar plus of min, weer naar een cijfer en zo verder indien nodig. Gebruik geen vakje twee keer. Er zijn meerder oplossingen mogelijk.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-10',
    blackCellColor: config.colorBlack,
    top: 4021,
    left: 800,
    width: 752,
    height: 395,
  },
];

const solutions: AnyElement[] = [
  ...getHardcodedHeader(3616, 1600, 1552, 'Oplossingen @SS'),
  ...getSubTitle(-16 + 3728, 1600, 256, 32, 'Koprol'),
  {
    type: '@content/renderer',
    top: -16 + 3776,
    left: 1600,
    width: 288,
    height: 360,
    contentId: 'solution-1-9',
    solution: true,
  },
  ...getSubTitle(4132, 1600, 256, 32, 'PAC-MAN Puzzel'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 4132 + 36,
    left: 1600,
    width: 256,
    height: 240,
  },
  ...getSubTitle(-16 + 3728, 1920, 256, 32, 'Zeeslag'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: -16 + 3776,
    left: 1920,
    width: 256,
    height: 256,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: -16 + 4064,
    left: 1920,
    width: 256,
    height: 256,
  },
  ...getSubTitle(-16 + 3728, 2208, 256, 32, 'Tentje Boompje'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: -16 + 3776,
    left: 2208,
    width: 256,
    height: 256,
  },
  ...getSubTitle(-16 + 3728, 2496, 256, 32, 'Cijferblok'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: -16 + 3776,
    left: 2496,
    width: 320,
    height: 448,
  },
  ...getSubTitle(-16 + 3728, 2848, 256, 32, '0h n0'),
  {
    type: '@content/renderer',
    solution: true,
    contentId: 'solution-1-7',
    top: -16 + 3776,
    left: 2848,
    width: 288,
    height: 288,
  },
  ...getSubTitle(-16 + 4048, 2208, 256, 32, 'Quento'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-10',
    solution: true,
    top: -16 + 4096,
    left: 2208 - 32, // offset is here because align left doesn't work
    width: 256,
    height: 330,
  },
  ...getSubTitle(-16 + 4080, 2848, 256, 32, 'Killer Sudoku'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-8',
    solution: true,
    top: -16 + 4128,
    left: 2848,
    width: 288,
    height: 288,
  },
  ...getSubTitle(-16 + 4240, 2496, 256, 32, 'Woordzoeker'),
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    onlySolutionGrid: true,
    solution: true,
    top: -16 + 4288,
    left: 2496,
    width: 320,
    height: 32,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN-V2 - Q42 page',
    layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
        'content-8',
        'content-9',
        'content-10',
      ],
      ...settings,
    },
    templates: {
      smallText,
    },
    elements: [
      ...wordFind,
      ...tectonic,
      ...wordPacman,
      ...zeeslag,
      ...tentjeBoompje,
      ...ohNo,
      ...killerSudoku,
      ...playBanner,
      ...koprol,
      ...quento,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
