import { ITemplate } from '../../models/ITemplate';
import { ADR, DEFAULT_SCALE } from '../config';

const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: 3118,
      height: 4606,

      // ___json_compatible_comment: 'these values are here for easy reference :)',
      // ___unused___widthOfAColumn: '(3118 - 3 * 64) / 4 = 731,5',

      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      columns: 4,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      fonts: {
        header: 'HaasGroteskTextBold-Regular.otf',
        text: 'HaasGroteskDisplayRm-Rg.otf',
        textbold: 'HaasGroteskDisplayBd-Rg.otf',
        cell: 'HaasGroteskDisplayMd-Rg.otf',
      },
    },
    templates: {
      header: {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: ADR.HighlightHeaderBackgroundColor,
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 48,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 64,
            top: 8,
            padding: 8,
            right: 16,
            max: 4,
            template: 'dark',
          },
        ],
      },
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 27,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 25,
            top: -6,
            padding: 0,
            right: 18,
            max: 4,
            template: 'dark',
          },
        ],
      },
      wordfind: {
        elements: [
          {
            type: 'wordfind',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSizeFactor: 0.6,
            fontSize: 38,
            lineGap: 3,
          },
        ],
      },
      crossword: {
        elements: [
          {
            type: 'crossword',
            font: '@page/settings/fonts/text',
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 38,
          },
        ],
      },
      quento: {
        elements: [
          {
            log: true,
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'quento',
            valign: 'bottom',
          },
        ],
      },
      koprol: {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
            valign: 'top',
          },
          {
            type: 'koprol',
            font: '@page/settings/fonts/text',
            valign: 'bottom',
          },
        ],
      },
      '0hn0': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            width: 689,
            left: 795.5,
            valign: 'top',
          },
          {
            type: '0hn0',
            valign: 'bottom',
          },
        ],
      },
    },

    rows: [
      {
        height: 864,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-2',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-3',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-4',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-4',
              },
            ],
          },
        ],
      },

      {
        height: 1152,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 3118,
                    y: -30,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 3,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-2-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-2-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-2-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-2-2',
              },
            ],
          },
        ],
      },

      {
        height: 864,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 3118,
                    y: -30,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-3-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-3-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-3-2',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-3-3',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'image',
                path: '@content/image',
                contentId: 'brand-image',
              },
              {
                type: 'text',
                left: 63,
                fontSize: 54,
                top: 756,
                contentId: 'brand-date',
                value: '@content/text',
              },
            ],
          },
        ],
      },

      {
        height: 864,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 3118,
                    y: -30,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-4-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                valign: 'bottom',
                contentId: 'content-4-2',
              },
            ],
          },
          {
            colSpan: 2,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-4-3',
              },
            ],
          },
        ],
      },

      {
        height: 30,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 2386.5,
                    y: -30,
                  },
                ],
              },
              {
                coordinates: [
                  {
                    x: 2386.5,
                    y: -30,
                  },
                  {
                    x: 2386.5,
                    y: -470,
                  },
                ],
              },
              {
                coordinates: [
                  {
                    x: 2386.5,
                    y: -470,
                  },
                  {
                    x: 3118,
                    y: -470,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: 'OPLOSSINGEN',
                top: -30,
              },
            ],
          },
        ],
      },

      {
        height: 320,
        columns: [
          {
            colSpan: 4,
            elements: [
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-1',
                top: -510,
                left: 2418.5,
                width: 699.5,
              },
              {
                type: '@content/renderer',
                top: -490,
                left: 2418.5,
                width: 699.5,
                height: 288,
                contentId: 'content-4-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 341.75,
                left: 2418.5,
                top: -40,
                stars: '@content/difficulty',
                contentId: 'content-1-1',
              },
              {
                type: '@content/renderer',
                width: 341.75,
                left: 2418.5,
                top: -380,
                contentId: 'content-1-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 341.75,
                left: 2776.25,
                top: -40,
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: '@content/renderer',
                width: 341.75,
                left: 2776.25,
                top: -380,
                contentId: 'content-1-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                top: 341,
                width: 341.75,
                left: 2418.5,
                stars: '@content/difficulty',
                contentId: 'content-1-3',
              },
              {
                type: '@content/renderer',
                width: 341.75,
                left: 2418.5,
                contentId: 'content-1-3',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                top: 341,
                width: 341.75,
                left: 2776.25,
                stars: '@content/difficulty',
                contentId: 'content-1-4',
              },
              {
                type: '@content/renderer',
                width: 341.75,
                left: 2776.25,
                contentId: 'content-1-4',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-3',
                top: 341,
                width: 320,
                left: 0,
              },
              {
                type: '@content/renderer',
                left: 0,
                width: 320,
                contentId: 'content-3-3',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-2',
                top: 341,
                width: 320,
                left: 341,
              },
              {
                type: '@content/renderer',
                left: 341,
                width: 320,
                contentId: 'content-4-2',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-3',
                top: 341,
                width: 320,
                left: 681,
              },
              {
                type: '@content/renderer',
                left: 681,
                width: 320,
                contentId: 'content-4-3',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-2',
                top: 341,
                width: 320,
                left: 1050,
              },
              {
                type: '@content/renderer',
                left: 1024,
                width: 320,
                height: 320,
                contentId: 'content-3-2',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-2-2',
                width: 320,
                left: 1362,
                top: 341,
                stars: '@content/difficulty',
              },
              {
                type: '@content/renderer',
                top: -80,
                left: 1362,
                width: 320,
                height: 392,
                valign: 'bottom',
                contentId: 'content-2-2',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-1',
                top: 341,
                width: 320,
                left: 1703,
                stars: '@content/difficulty',
              },
              {
                type: '@content/renderer',
                top: -80,
                left: 1703,
                width: 320,
                height: 392,
                valign: 'bottom',
                contentId: 'content-3-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-2-1',
                top: 341,
                width: 320,
                left: 2043,
              },
              {
                type: '@content/renderer',
                left: 2043,
                width: 320,
                height: 320,
                valign: 'bottom',
                contentId: 'content-2-1',
                solution: true,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default layout;
