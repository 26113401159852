import { storyLayout } from './components/story-layout';

const contentBoxFullHeight = 2916;
const widthContentBox = 2280;

export const config = {
  puzzleTitleFontSize: 125,
  widthContentBox,
  contentBoxFullHeight,
  contentBoxHalfHeight: 1400,
  topContentBox: (storyLayout.height - contentBoxFullHeight) / 2,
  leftContentBox: (storyLayout.width - widthContentBox) / 2,
  paddingContentBox: widthContentBox * 0.02,
  solutionRowHeight: 630,
  topOfSolutionElement: 70,
};
