import config from './config';

export const header = {
  elements: [
    {
      type: 'text',
      baseline: 'middle',
      top: 32,
      left: 0,
      font: config.fontRegular,
      fontSize: config.fontSizeLarge,
      color: config.colorBlack,
      align: 'center',
    },
  ],
};
