import { AnyElement, ITemplate } from '../../../models/ITemplate';
import block42puzzles from '../components/block-42puzzles';
import { getBlockWWW } from '../components/block-www';
import config from '../components/config';
import {
  copyright,
  copyrightQ42,
  getBox,
  getHeader,
  smallDifficulty,
  smallText,
  staticSmallText,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const solutionHashi: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-7',
    top: 3368,
    left: 1728,
    width: 240,
    height: 32,
  },
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-7',
    top: 3400,
    left: 1728,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    solution: true,
    top: 3432,
    left: 1728,
    width: 240,
    height: 240,
  },
  // 1-10
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-8',
    top: 3680,
    left: 1728,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-8',
    solution: true,
    top: 3680 + 32,
    left: 1728,
    width: 240,
    height: 240,
  },
];

const solutionBinaire: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-3',
    top: 3368,
    left: 2000,
    width: 240,
    height: 32,
  },
  {
    type: 'custom',
    name: 'staticSmallText',
    value: 'Gemiddeld',
    top: 3400,
    left: 2000,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 3432,
    left: 2000,
    width: 240,
    height: 240,
  },
  {
    type: 'custom',
    name: 'staticSmallText',
    value: 'Gevorderden',
    top: 3680,
    left: 2000,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 3680 + 32,
    left: 2000,
    width: 240,
    height: 240,
  },
];

const solutionSudoku: AnyElement[] = [
  // S: Sudoku
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-5',
    top: 3368,
    left: 2272,
    width: 240,
    height: 32,
  },
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-5',
    top: 3400,
    left: 2272,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3432,
    left: 2272,
    width: 240,
    height: 240,
  },
  {
    type: 'custom',
    name: 'smallDifficulty',
    contentId: 'solution-1-6',
    top: 3680,
    left: 2272,
    width: 240,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 3680 + 32,
    left: 2272,
    width: 240,
    height: 240,
  },
];

const solutionWww: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-2',
    top: 3996 - 32,
    left: 1728,
    width: 480,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    renderType: 'answers',
    solution: true,
    lineGap: -2,
    top: 3996,
    left: 1728,
    width: 480,
    height: 80,
  },
];

const solutionWordfind: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-1',
    top: 3996 - 32,
    left: 2096,
    width: 256,
    height: 32,
  },
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-1',
    value: '@content/solution',
    font: config.fontRegular,
    top: 3996,
    left: 2096,
    width: 256,
    height: 32,
  },
];

const solutionJigsawCrossword: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-3-2',
    top: 4096 - 32,
    left: 1728,
    width: 640,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-3-2',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    solution: true,
    top: 4096,
    left: 1728,
    width: 640,
    height: 320,
  },
];

const solutionFilippine: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-3-1',
    top: 3432 - 36,
    left: 2544,
    width: 608,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-3-1',
    solutionType: 'text',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    solution: true,
    continuous: true,
    compact: true,
    lineGap: 0,
    top: 3432,
    left: 2544,
    width: 608,
    height: 368,
  },
];

const solutionBlokjeOm: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-10',
    top: 3888 - 32,
    left: 2544,
    width: 606,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-10',
    solution: true,
    top: 3888,
    left: 2544,
    width: 368,
    height: 208,
    renderType: 'answers',
    font: config.fontRegular,
    fontBold: config.fontBold,
  },
];

const solutionSudokuHoroscope: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-1-9',
    top: 4208 - 64,
    left: 2944,
    width: 208,
    height: 120,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-9',
    solution: true,
    top: 4208,
    left: 2944,
    width: 208,
    height: 208,
  },
];

const solutionKakuro: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-3-4',
    top: 3888 - 32,
    left: 2944,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-3-4',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3888,
    left: 2944,
    width: 208,
    height: 208,
  },
];

const solutionIkura: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-3-3',
    top: 3996 - 32,
    left: 2400,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-3-3',
    solution: true,
    top: 3996,
    left: 2400,
    width: 112,
    height: 112,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'custom',
    name: 'smallText',
    contentId: 'solution-2-2',
    top: 4176 - 32,
    left: 2400,
    width: 352,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-2-2',
    blackCellColor: config.colorBlack,
    solution: true,
    onlyGrid: true,
    top: 4176,
    left: 2400,
    width: 416,
    height: 240,
  },
];

const solutionsBlock: AnyElement[] = [
  ...solutionHashi,
  ...solutionBinaire,
  ...solutionSudoku,
  ...solutionWww,
  ...solutionWordfind,
  ...solutionJigsawCrossword,
  ...solutionFilippine,
  ...solutionBlokjeOm,
  ...solutionSudokuHoroscope,
  ...solutionIkura,
  ...solutionKakuro,
  ...solutionCrossword,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN - Tuesday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
        'content-8',
        'content-9',
      ],
      ...settings,
    },
    templates: {
      smallText,
      smallDifficulty,
      copyright,
      copyrightQ42,
      staticSmallText,
    },
    elements: [
      // Content-1: Wordfind
      ...getHeader(0, 0, 1664, 80, 'content-1', undefined, '@content/header: @content/theme'),
      // Date of today
      {
        type: 'text',
        value: '@DD-@MM-@YY',
        font: config.fontRegular,
        fontSize: config.fontSizeSmall,
        color: config.colorWhite,
        align: 'right',
        baseline: 'middle',
        top: 52,
        left: 1312,
        width: 320,
        height: 48,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        font: config.fontBold,
        fontSize: config.fontSizeRegular,
        columns: 1,
        lineGap: -1,
        characterSpacing: 2,
        onlyText: true,
        hideSolutionGrid: true,
        top: 112,
        left: 32,
        width: 378,
        wordsHeight: 1312,
      },
      {
        type: 'text',
        contentId: 'content-1',
        value: '@content/explanation',
        font: config.fontRegular,
        fontSize: config.fontSizeRegular,
        color: config.colorBlack,
        align: 'center',
        lineGap: -2,
        top: 112,
        left: 384,
        width: 1264,
        height: 128,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        onlyGrid: true,
        hideSolutionGrid: true,
        gridBackgroundColor: config.colorWhite,
        font: config.fontBold,
        align: 'center',
        top: 208,
        left: 384,
        width: 1264,
        height: 1088,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        onlySolutionGrid: true,
        solutionGridType: 'circle',
        gridBackgroundColor: config.colorWhite,
        top: 1328,
        left: 424,
        width: 1232,
        height: 80,
      },
      {
        type: 'custom',
        name: 'copyright',
        top: 1040,
        left: 1608,
      },

      // Content-2: Swedish
      ...getHeader(1440, 0, 1648, 80, 'content-2', 'dense'),
      ...getBox(1520, 0, 1648, 2896),
      {
        type: '@content/renderer',
        contentId: 'content-2',
        fontSizeFactor: 0.195,
        gridBackgroundColor: config.colorWhite,
        blackCellColor: config.colorBlueBackground,
        textCellBackgroundColor: config.colorBlack,
        renderType: 'grid',
        separatorOffsetLeft: 1,
        highlightType: 'circle',
        top: 1518,
        left: 0,
        width: 1650,
        height: 1792 + 142,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        gridBackgroundColor: config.colorWhite,
        font: config.fontRegular,
        fontSize: config.fontSizeRegular,
        renderType: 'bar',
        highlightType: 'circle',
        top: 3348 + 142,
        left: 730,
        width: 1528,
        height: 80,
      },
      {
        type: 'text',
        value:
          'Breng de letters uit de genummerde vakjes\n' +
          'naar de cijferbalk en vorm het sleutelwoord.',
        color: config.colorBlack,
        font: config.fontRegular,
        fontSize: config.fontSizeRegular,
        baseline: 'top',
        lineGap: -1,
        top: 3348 + 142,
        left: 20,
        width: 720,
        height: 96,
      },
      {
        type: 'custom',
        name: 'copyright',
        top: 3056 + 124,
        left: 1604 + 48,
      },

      // Prize puzzle
      {
        type: 'image',
        path: '@content/image',
        contentId: 'prize-image',
        top: 3456 + 134,
        left: 0,
        width: 1648,
        height: 824,
      },

      // Wie wat waar
      ...getBlockWWW('content-3'),

      // Binaire & Sudoku
      ...block42puzzles,

      // PC image
      {
        type: 'image',
        path: 'img/hln/pc.png',
        top: 1168,
        left: 2064,
        width: 336,
        height: 304,
      },

      // Solutions
      // Solutions
      ...getHeader(3280, 1728, 1424, 80, null, 'dense', 'Oplossingen @SS'),
      ...solutionsBlock,
    ],
  },
};

export default layoutTemplate;
