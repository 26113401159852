import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import contentFriday from './content/volkskrant-friday.json';
import contentMonday from './content/volkskrant-monday.json';
import contentThursday from './content/volkskrant-thursday.json';
import contentTuesday from './content/volkskrant-tuesday.json';
import contentWednesday from './content/volkskrant-wednesday.json';
import layoutFriday from './layout/volkskrant-friday';
import layoutMonday from './layout/volkskrant-monday';
import layoutThursday from './layout/volkskrant-thursday';
import layoutTuesday from './layout/volkskrant-tuesday';
import layoutWednesday from './layout/volkskrant-wednesday';

const volkskrantLayouts: LayoutDb[] = [
  {
    id: layoutIds.volkskrantMaandag,
    name: 'Volkskrant - 1. Maandag',
    json: layoutMonday,
    content: contentMonday,
  },
  {
    id: layoutIds.volkskrantDinsdag,
    name: 'Volkskrant - 2. Dinsdag',
    json: layoutTuesday,
    content: contentTuesday,
  },
  {
    id: layoutIds.volkskrantDonderdag,
    name: 'Volkskrant - 4. Donderdag',
    json: layoutThursday,
    content: contentThursday,
  },
  {
    id: layoutIds.volkskrantWoensdag,
    name: 'Volkskrant - 3. Woensdag',
    json: layoutWednesday,
    content: contentWednesday,
  },
  {
    id: layoutIds.volkskrantVrijdag,
    name: 'Volkskrant - 5. Vrijdag',
    json: layoutFriday,
    content: contentFriday,
  },
];

export default volkskrantLayouts;
