import { ITemplate } from '../../../models/ITemplate';
import block42puzzles from '../components/block-42puzzles';
import { qrBlock } from '../components/block-qr';
import { getBlockWWW } from '../components/block-www';
import config from '../components/config';
import {
  copyright,
  copyrightQ42,
  getBox,
  getHeader,
  smallDifficulty,
  smallText,
  staticSmallText,
} from '../components/layoutParts';
import { layoutMonday, settings } from '../components/pageParts';

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN - Friday template',
    layout: layoutMonday,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {
      smallText,
      smallDifficulty,
      copyright,
      copyrightQ42,
      staticSmallText,
    },
    elements: [
      // Content-1: swedishcrossword
      ...getHeader(0, 0, 1664, 80, 'content-1'),
      // Date of today
      {
        type: 'text',
        value: '@DD-@MM-@YY',
        font: config.fontRegular,
        fontSize: config.fontSizeSmall,
        color: config.colorWhite,
        align: 'right',
        baseline: 'middle',
        top: 52,
        left: 1312,
        width: 320,
        height: 48,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        fontSizeFactor: 0.195,
        blackCellColor: config.colorBlueBackground,
        renderType: 'grid',
        separatorOffsetLeft: 1,
        highlightType: 'none',
        align: 'center',
        top: 80,
        left: 0,
        width: 1664,
        height: 1952,
      },
      {
        type: 'custom',
        name: 'copyright',
        top: 1760,
        left: 1664,
      },
      // Content-2: wordfind
      ...getHeader(2064, 0, 1664, 80, 'content-2', 'dense', '@content/header: @content/theme'),
      ...getBox(2144, 0, 1664, 2272),
      // Magnifier image, needed before the text
      // So the text might come on top of it
      {
        type: 'image',
        path: 'img/hln/magnifier.png',
        top: 3104,
        left: 290,
        width: 144,
        height: 320,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        font: config.fontBold,
        fontSize: config.fontSizeRegular,
        columns: 1,
        lineGap: -1,
        characterSpacing: 2,
        onlyText: true,
        hideSolutionGrid: true,
        top: 2176,
        left: 32,
        width: 336,
        wordsHeight: 1312,
      },
      {
        type: 'text',
        contentId: 'content-2',
        value: '@content/explanation',
        font: config.fontRegular,
        fontSize: config.fontSizeRegular,
        color: config.colorBlack,
        align: 'left',
        lineGap: -2,
        top: 2176,
        left: 432,
        width: 1184,
        height: 96,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        onlyGrid: true,
        hideSolutionGrid: true,
        gridBackgroundColor: config.colorWhite,
        font: config.fontBold,
        top: 2272,
        left: 432,
        width: 1184,
        height: 1024,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        onlySolutionGrid: true,
        solutionGridType: 'circle',
        gridBackgroundColor: config.colorWhite,
        top: 3312,
        left: 432,
        width: 1184,
        height: 80,
      },
      {
        type: 'custom',
        name: 'copyright',
        top: 3040,
        left: 1624,
      },
      // Prize puzzle
      {
        type: 'image',
        path: '@content/image',
        contentId: 'prize-image',
        top: 3456,
        left: 8,
        width: 1664,
        height: 960,
      },

      // Wie wat waar
      ...getBlockWWW('content-3'),

      // Binaire & Sudoku
      ...block42puzzles,

      // PC image
      {
        type: 'image',
        path: 'img/hln/pc.png',
        top: 1168,
        left: 2064,
        width: 336,
        height: 304,
      },

      qrBlock,

      // Solutions
      ...getHeader(3648, 1728, 1424, 80, null, 'dense', 'Oplossingen @SS'),

      // S:1-1 - Wordfind
      {
        type: 'custom',
        name: 'smallText',
        contentId: 'solution-1-1',
        top: 3808 - 32,
        left: 1728,
        width: 528,
        height: 32,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-1',
        blackCellColor: config.colorBlack,
        textCellBackgroundColor: config.colorGreyLight,
        solution: true,
        onlyGrid: true,
        markWordsVisually: true,
        top: 3808,
        left: 1728,
        width: 496,
        height: 592,
      },
      {
        type: 'text',
        contentId: 'solution-1-1',
        value: '@content/solution',
        color: config.colorBlack,
        font: config.fontBold,
        fontSize: config.fontSizeSmall,
        baseline: 'top',
        lineGap: -1,
        top: 4288,
        left: 1728,
        width: 496,
        height: 32,
      },

      // S:1-4 & 1-5 - Binaire
      {
        type: 'custom',
        name: 'smallText',
        contentId: 'solution-1-4',
        top: 3808 - 32,
        left: 2288,
        width: 240,
        height: 32,
      },
      {
        type: 'custom',
        name: 'staticSmallText',
        value: 'Gemiddeld',
        top: 3808 - 32,
        left: 2288 + 100,
        width: 240,
        height: 32,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-4',
        solution: true,
        top: 3808,
        left: 2288,
        width: 240,
        height: 240,
      },
      {
        type: 'custom',
        name: 'staticSmallText',
        value: 'Gevorderden',
        top: 4112 - 32,
        left: 2288,
        width: 240,
        height: 32,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-5',
        solution: true,
        top: 4112,
        left: 2288,
        width: 240,
        height: 240,
      },

      // S:1-6 & 1-7 - Sudoku
      {
        type: 'custom',
        name: 'smallText',
        contentId: 'solution-1-6',
        top: 3808 - 32,
        left: 2592,
        width: 240,
        height: 32,
      },
      {
        type: 'custom',
        name: 'smallDifficulty',
        contentId: 'solution-1-6',
        top: 3808 - 32,
        left: 2592 + 108,
        width: 240,
        height: 32,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-6',
        solution: true,
        top: 3808,
        left: 2592,
        width: 240,
        height: 240,
      },
      {
        type: 'custom',
        name: 'smallDifficulty',
        contentId: 'solution-1-7',
        top: 4112 - 32,
        left: 2592,
        width: 240,
        height: 32,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-7',
        solution: true,
        top: 4112,
        left: 2592,
        width: 240,
        height: 240,
      },

      // S:1-3 - WWW
      {
        type: 'custom',
        name: 'smallText',
        contentId: 'solution-1-3',
        top: 3776,
        left: 2880,
        width: 272,
        height: 80,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-3',
        font: config.fontRegular,
        fontSize: config.fontSizeSmall,
        renderType: 'answers',
        solution: true,
        top: 3848,
        left: 2880,
        width: 272,
        height: 512,
      },
    ],
  },
};

export default layoutTemplate;
