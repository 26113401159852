import {
  type Analytics,
  getAnalytics as getFbAnalytics,
  logEvent as logAnalyticsEvent,
} from 'firebase/analytics';

import { RouteName } from '../router/RouteName';
import { app } from '../store/db';

interface PageViewEvent {
  event: 'page_view';
  eventData: { page_name?: RouteName; page_url: string };
}

interface DownloadFromShopEvent {
  event: 'download_from_shop';
  eventData: { preset_name: string; puzzle_id: string };
}

type AnalyticsEvent = DownloadFromShopEvent;

let analytics: Analytics | null = null;

export function getAnalytics() {
  if (!analytics) {
    analytics = getFbAnalytics(app);
  }
  return analytics;
}

export function logPageView(pageUrl: string, pageName?: RouteName) {
  const pageViewEvent: PageViewEvent = {
    event: 'page_view',
    eventData: { page_name: pageName, page_url: pageUrl },
  };
  const analytics = getAnalytics();

  logAnalyticsEvent(analytics, pageViewEvent.event, pageViewEvent.eventData);
}

export function logEvent(event: AnalyticsEvent) {
  const analytics = getAnalytics();
  logAnalyticsEvent(analytics, event.event, event.eventData);
}
