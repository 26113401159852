

















import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ITectonicPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class TectonicPresetEditor extends Vue {
  @PropSync('presetData') preset!: ITectonicPreset;
}
