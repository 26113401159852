import { IBoxElement } from '../../../models/ITemplate';
import { config } from '../config';

export const contentBoxFullHeight: IBoxElement = {
  type: 'box',
  top: config.topContentBox,
  left: config.leftContentBox,
  height: config.contentBoxFullHeight,
  width: config.widthContentBox,
  backgroundColor: [0, 0, 0, 0],
  borderSize: 3,
};
