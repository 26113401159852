import { IRow } from '../../models/ITemplate';
import { IWordfindElement } from '../../models/puzzles/wordfind/interfaces';
import { clone } from '../../utils';
import { artifacts } from './components/artifacts';
import { contentBoxFullHeight } from './components/contentBoxFullHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';

// Clone the layout so you can override settings
const layout = clone(storyLayout);

// Heights and widths
const titleHeight = 100;
const titleTopMargin = 70;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};

// Elements: Made for the wordfind
const puzzle: IWordfindElement = {
  type: 'wordfind',
  contentId: 'content-1',
  top: 700 - layout.cellPadding.horizontal,
  height: config.contentBoxFullHeight - titleHeight - titleTopMargin * 2 - 700,
  font: storyConfig.fontText,
  fontSizeFactor: 0.6,
  fontSize: 41,
  onlyGrid: true,
  borderSize: 5,
  value: '@content/text',
  align: 'center',
};

const clues: IWordfindElement = {
  type: 'wordfind',
  contentId: 'content-1',
  wordsHeight: 700 - layout.cellPadding.horizontal,
  font: storyConfig.fontText,
  fontSize: 42,
  columns: 3,
  onlyText: true,
  hideSolutionGrid: true,
  borderSize: 5,
  value: '@content/text',
  width: 1930,
  left: 120,
};

// Rows
const titleRow: IRow = {
  height: titleHeight,
  elements: [storyHeader('content-1')],
};

const puzzleRow: IRow = {
  // leaves some margin at the bottom: 2916 - 100 - 140
  height: config.contentBoxFullHeight - titleHeight - titleTopMargin * 2,
  elements: [puzzle, clues],
};

// Layout
export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground, contentBoxFullHeight, ...artifacts, ...storyPageNumbers],
    rows: [titleRow, puzzleRow],
  },
};
