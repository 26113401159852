import { IGeneratedKakuro, IKakuroPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const KakuroName = 'Kakuro';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedKakuroIdInput(generated: IGeneratedKakuro): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataKakuro(): IKakuroPreset {
  return {
    renderer: 'kakuro',
    name: KakuroName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      width: 8,
      height: 8,
      difficulty: 1,
    },
    rendererParams: {
      header: KakuroName.toUpperCase(),
      explanation:
        'Van elk in te vullen getal is de som van de cijfers gelijk aan het getal in het zwarte vakje. ' +
        'Elk getal boven een diagonale lijn heeft betrekking op de horizontaal in te vullen cijfers. ' +
        'Een getal onder een diagonale lijn is de som van de cijfers die verticaal moeten worden ingevuld. ' +
        'De 0 wordt niet gebruikt en een getal bevat uitsluitend verschillende cijfers. Unieke combinaties zijn bijvoorbeeld: 17=8+9 24=7+8+9. ' +
        'Cijfers in een grijsvakje zijn oneven. De witte vakjes bevatten een even cijfer.',
    },
  };
}
