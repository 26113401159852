











import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IKillerSudokuPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class KillerSudokuPresetEditor extends Vue {
  @PropSync('presetData') preset!: IKillerSudokuPreset;
}
