import firebase from 'firebase/compat';

import { db, storage } from '../store/db';
import firestore = firebase.firestore;

export enum PublicationStatus {
  Draft = 'draft',
  Published = 'published',
  Deleted = 'deleted',
}

export enum AssetType {
  Image = 'image',
  File = 'file',
}

export interface IAsset extends IAssetInput {
  id: string;
}

export interface IAssetInput {
  url: string;
  ref: string;
  createdOn: Date;
  title?: string;
  tags?: string[];
  type?: string;
  mimetype?: string;
  status?: PublicationStatus;
  createdBy?: string;
  alt?: string;
  privateNotes?: string;
  copyright?: string;
  dimensions?: {
    width: number;
    height: number;
    ratio: number;
    ratioString: string;
  };
  filesize?: number;
}

export default class MediaService {
  async getAssets() {
    // todo: check for logged in user
    // todo: pagination

    const snapshot = await db.collection('media').orderBy('createdOn', 'desc').get();
    const assets: IAsset[] = (
      !snapshot.empty
        ? snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        : []
    ) as IAsset[];

    return assets;
  }

  async getAssetById(assetID: string) {
    const doc = await db.collection('media').doc(assetID).get();
    return doc.exists ? this.normalizeAsset(doc) : null;
  }

  async createAsset(asset: IAssetInput) {
    const docRef = await db.collection('media').doc();
    const doc = this.makeAssetDoc(asset);
    return docRef.set(doc);
  }

  async updateAsset(asset: IAsset) {
    const docRef = await db.collection('media').doc(asset.id);
    const doc = this.makeAssetDoc(asset);
    return docRef.update(doc);
  }

  async deleteAsset(asset: IAsset) {
    const docRef = db.collection('media').doc(asset.id);
    await docRef.delete();
  }

  async uploadFiles(files: FileList, currentUserEmail: string) {
    // todo: make more efficient
    // let promises: [File, Promise<any>] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filenameParts = file.name.split('.');
      filenameParts.splice(filenameParts.length - 1, 0, new Date().getTime().toString());
      const uniqueName = filenameParts.join('.');
      const refString = `media/${uniqueName}`;
      const ref = storage.ref(refString);
      await ref.put(file);

      const url = await ref.getDownloadURL();
      const dimensions = await this.getDimensionsForFile(file);

      const assetObj: IAssetInput = {
        url,
        ref: refString,
        createdOn: new Date(),
        title: file.name,
        tags: [],
        type: this.getTypeFromMimetype(file.type),
        mimetype: file.type,
        status: PublicationStatus.Published,
        createdBy: currentUserEmail,
        alt: '',
        privateNotes: '',
        copyright: '',
        dimensions,
        filesize: file.size,
      };

      await this.createAsset(assetObj);
    }
  }

  private normalizeAsset(doc: any) {
    const docData = doc.data();
    const asset: IAsset = JSON.parse(JSON.stringify(docData)) as IAsset;
    asset.id = doc.id;

    if (docData.createdOn) {
      asset.createdOn = new Date(docData.createdOn.seconds * 1000);
    }
    return asset;
  }

  private makeAssetDoc(asset: IAsset | IAssetInput) {
    const docData = JSON.parse(JSON.stringify(asset)) as any;
    if (asset.createdOn) {
      docData.createdOn = firestore.Timestamp.fromDate(new Date(asset.createdOn));
    }

    return docData;
  }

  private getTypeFromMimetype(mimetype: string) {
    // todo
    console.log('MimeType:' + mimetype);
    return AssetType.Image;
  }

  private async getDimensionsForFile(file: File) {
    const imageUrl = URL.createObjectURL(file);
    const img = await this.loadImage(imageUrl);

    return {
      width: img.width,
      height: img.height,
      ratio: Math.round((img.width / img.height) * 10) / 10,
      ratioString: `${img.width}by${img.height}`,
    };
  }

  private loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        console.log('Image Loaded');
        resolve(img);
      };
      img.src = url;
    });
  }
}
