import { IGeneratedPacMan, IPacManPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const PacManName = 'Pac-Man';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedPacManIdInput(generated: IGeneratedPacMan): string {
  return JSON.stringify(generated.words);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataPacMan(): IPacManPreset {
  return {
    renderer: 'pacman',
    name: PacManName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      width: 13,
      height: 13,
    },
    rendererParams: {
      header: PacManName,
      explanation:
        'Als PAC-MAN letters in de juiste volgorde eet kan hij woorden vormen. Teken hoe hij moet lopen. Sluit elk woord af met fruit. Pas op voor spoken! Eet een energie-pil om door één spook heen te kunnen.',
    },
  };
}
