import { UserDb } from 'functions/src/models/db/Users';
import { IState, IUserState } from 'src/models/models';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';

import { serializeWithId } from '..';
import { db } from '../db';

const userStore: Module<IUserState, IState> = {
  namespaced: true,
  state: {
    user: null,
    users: [],
    userOrders: [],
  },
  getters: {},
  mutations: {},
  actions: {
    bindUsers: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef('users', db.collection('users'), { serialize: serializeWithId })
    ),
    unbindUsers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('users')),
    bindUser: firestoreAction(({ bindFirestoreRef }, { userId }) =>
      bindFirestoreRef('user', db.collection('users').doc(userId), { serialize: serializeWithId })
    ),
    unbindUser: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('user')),
    deleteUser: async (_context, id: string) => await db.collection('users').doc(id).delete(),
    bindUserOrders: firestoreAction(({ bindFirestoreRef }, { userId }) =>
      bindFirestoreRef('userOrders', db.collection('orders').where('user', '==', userId), {
        serialize: serializeWithId,
      })
    ),
    unbindUserOrders: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('userOrders')),
    saveUser: async (_context, data: { userId: string; userData: Partial<UserDb> }) =>
      await db.collection('users').doc(data.userId).update(data.userData),
  },
};

export default userStore;
