import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

const config = {
  scale: DEFAULT_SCALE,
  width: 3016,
  height: 3992,
  gridSize: 32,
  fontCrossword: 'GothamNarrow-Book.otf',
  fontBold: 'GothamNarrow-Bold.otf',
  fontRegular: 'PublicoHeadline-Light.otf',
  fontHeader: 'PublicoHeadline-Extrabold.otf',
  fontHeaderLight: 'Graphik-Light.otf',
  colorBlue: [100, 30, 0, 25] as CMYKColor,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  colorWhite: [0, 0, 0, 0] as CMYKColor,
  fontSizeLarge: 14 / DEFAULT_SCALE,
  fontSizeRegular: 8.4 / DEFAULT_SCALE,
  fontSizeSmall: 7 / DEFAULT_SCALE,
  padding: 4,
};

export default config;
