// Puzzel verder op hln.be/fun
export const qrBlock = {
  type: 'image',
  path: '@content/image',
  contentId: 'play-image',
  top: 3280,
  left: 1728,
  width: 1424,
  height: 336,
};
