import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import content1 from './content/demorgen-content-1.json';
import content6 from './content/demorgen-content-6.json';
import content7 from './content/demorgen-content-7.json';
import content11 from './content/demorgen-content-11.json';
import friday from './layout/demorgen-friday';
import fridayWithWed from './layout/demorgen-friday-with-wednesday-solutions';
import monday from './layout/demorgen-monday';
import mondayWithFri from './layout/demorgen-monday-with-friday-solutions';
import saturday from './layout/demorgen-saturday';
import swedish from './layout/demorgen-saturday-swedish';
import saturdayWithDo from './layout/demorgen-saturday-with-thursday-solutions';
import thursday from './layout/demorgen-thursday';
import thursdayWithTue from './layout/demorgen-thursday-with-tuesday-solutions';
import tuesday from './layout/demorgen-tuesday';
import tuesdayWithSat from './layout/demorgen-tuesday-with-saturday-solutions';
import tuesdayWithSatContent from './content/demorgen-content-tuesday-with-saturday-solutions.json';
import wednesday from './layout/demorgen-wednesday';
import wednesdayWithMon from './layout/demorgen-wednesday-with-monday-solutions';

const deMorgenLayouts: LayoutDb[] = [
  {
    id: layoutIds.deMorgenMaandag,
    name: 'DeMorgen - 1. Maandag',
    json: monday,
    content: content6,
  },
  {
    id: layoutIds.deMorgenMaandagMetOplossingenVrijdag,
    name: 'DeMorgen - 1. Maandag met oplossingen vrijdag',
    json: mondayWithFri,
    content: content6,
    exceptionForLayoutId: layoutIds.deMorgenMaandag,
  },
  {
    id: layoutIds.deMorgenDinsdag,
    name: 'DeMorgen - 2. Dinsdag',
    json: tuesday,
    content: content7,
  },
  {
    id: layoutIds.deMorgenDinsdagMetOplossingenZaterdag,
    name: 'DeMorgen - 8.  Dinsdag met oplossingen zaterdag',
    json: tuesdayWithSat,
    content: tuesdayWithSatContent,
    exceptionForLayoutId: layoutIds.deMorgenDinsdag,
  },
  {
    id: layoutIds.deMorgenWoensdag,
    name: 'DeMorgen - 3. Woensdag',
    json: wednesday,
    content: content7,
  },
  {
    id: layoutIds.deMorgenWoensdagMetOplossingenMaandag,
    name: 'DeMorgen - 3. Woensdag met oplossingen maandag',
    json: wednesdayWithMon,
    content: content7,
    exceptionForLayoutId: layoutIds.deMorgenWoensdag,
  },
  {
    id: layoutIds.deMorgenDonderdag,
    name: 'DeMorgen - 4. Donderdag',
    json: thursday,
    content: content6,
  },
  {
    id: layoutIds.deMorgenDonderdagMetOplossingenDinsdag,
    name: 'DeMorgen - 4. Donderdag met oplossingen dinsdag',
    json: thursdayWithTue,
    content: content6,
    exceptionForLayoutId: layoutIds.deMorgenDonderdag,
  },
  {
    id: layoutIds.deMorgenVrijdag,
    name: 'DeMorgen - 5. Vrijdag',
    json: friday,
    content: content7,
  },
  {
    id: layoutIds.demorgenVrijdagMetWoensdagOplossingen,
    name: 'DeMorgen - 5.  Vrijdag met oplossingen woensdag',
    json: fridayWithWed,
    content: content7,
    exceptionForLayoutId: layoutIds.deMorgenVrijdag,
  },
  {
    id: layoutIds.deMorgenZaterdag,
    name: 'DeMorgen - 6. Zaterdag',
    json: saturday,
    content: content11,
  },
  {
    id: layoutIds.deMorgenZaterdagMetOplossingenDonderdag,
    name: 'DeMorgen - 6. Zaterdag met oplossingen donderdag (pagina 1)',
    json: saturdayWithDo,
    content: content11,
    exceptionForLayoutId: layoutIds.deMorgenZaterdag,
  },
  {
    id: layoutIds.deMorgenZaterdagZweeds,
    name: 'DeMorgen - 7. Zaterdag Zweeds',
    json: swedish,
    content: content1,
  },
];

export default deMorgenLayouts;
