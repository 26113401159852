import { ITextElement } from '../../../models/ITemplate';
import { config } from '../config';
import { storyConfig } from './story-config';

export const puzzleExplanation = (contentId: string): ITextElement => ({
  width: config.widthContentBox * 0.96,
  type: 'text',
  align: 'center',
  value: '@content/explanation',
  contentId: 'content-1',
  font: storyConfig.fontText,
  fontSize: 45,
});
