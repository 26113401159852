








import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IQuentoPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class QuentoPresetEditor extends Vue {
  @PropSync('presetData') preset!: IQuentoPreset;
}
