import { IImageElement, ITextElement } from '../../models/ITemplate';
import { clone } from '../../utils';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';

// Clone the layout so you can override settings
const layout = clone(storyLayout);

// Elements
const header: IImageElement = {
  type: 'image',
  path: '@content/image',
  contentId: 'header',
  // Values below are a bit of guess work since the image is not centered itself.
  width: storyLayout.width * 1.1,
  top: 350,
  left: 280,
};

const editionText: ITextElement = {
  type: 'text',
  font: storyConfig.fontSubTitle,
  fontSize: 50,
  color: [35, 30, 25, 100],
  top: 1300,
  left: 570,
  baseline: 'top',
  contentId: 'edition-info',
  value: '@content/text',
};

const frontImageMargin = 200;
const frontImage: IImageElement = {
  top: 1850,
  left: frontImageMargin,
  width: layout.width - 2 * frontImageMargin,
  type: 'image',
  path: '@content/image',
  contentId: 'front-image',
};

// Layout
export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground],
    rows: [
      {
        height: layout.height,
        elements: [header, editionText, frontImage],
      },
    ],
  },
};
