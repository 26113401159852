import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

/*
https://docs.google.com/document/d/14oiQOIguBg2KJntahfqzed__xZ95NB0p2jntzDn6ho4/edit
Fonts: Gotham in diverse gewichten; m.n. de black en de book

Titels vd puzzels zoals: KAKURO/SUDOKU
- Gotham Thin 13 ←→ 40

NR. van puzzel:
- Gotham Thin 7 ←→ 0

Omschrijving puzzels:
- Gotham Thin 7.5 ←→ 0

Doorverwijzing naar puzzelpagina volkskrant.nl/puzzels:
- Gotham Bold 7.5 ←→ 20

Kruiswoord:
- Horizontaal: GothamNarrow - Bold 8 ←→ 60
- Cijfers: Gotham - Bold 8 ←→ 0
- Clues: Gotham thin 8 ←→ 0

OPLOSSINGEN:
- Gotham Bold 10 ←→ 140

Naam van puzzel:
- GothamNarrow Bold 8 ←→ 40
*/
const config = {
  scale: DEFAULT_SCALE,
  width: 3141,
  height: 4403,
  gridSize: 8,
  fontRegular: 'Gotham-Book.otf',
  fontBold: 'Gotham-Black.otf',
  fontThin: 'Gotham-Thin.otf',
  fontNarrowBold: 'GothamNarrow-Bold.otf',
  colorRed: [0, 100, 100, 0] as CMYKColor,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  colorWhite: [0, 0, 0, 0] as CMYKColor,
  colorGrey: [0, 0, 0, 15] as CMYKColor,
  colorLightGrey: [0, 0, 0, 6] as CMYKColor,
  fontSizeLarge: 13 / DEFAULT_SCALE,
  fontSizeLargeAlt: 10 / DEFAULT_SCALE,
  fontSizeRegular: 8 / DEFAULT_SCALE,
  fontSizeRegularAlt: 7.5 / DEFAULT_SCALE,
  fontSizeSmall: 7 / DEFAULT_SCALE,
  letterSpacingNone: 0,
  letterSpacingExtraSmall: 1.02,
  letterSpacingSmall: 1.04,
  letterSpacingMedium: 1.06,
  letterSpacingLarge: 1.4,
};

export default config;
