import { assertNever } from '../../utils';

export interface TitleDb {
  id?: string;
  name: string;
  parent?: string;
  frequency: TitleFrequency;
  matches?: string[];
}

export enum TitleFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM',
}

export function getReadableNameForFrequency(freq?: TitleFrequency): string {
  switch (freq) {
    case undefined:
      return '';
    case TitleFrequency.DAILY:
      return 'Dagelijks';
    case TitleFrequency.WEEKLY:
      return 'Wekelijks';
    case TitleFrequency.MONTHLY:
      return 'Maandelijks';
    case TitleFrequency.CUSTOM:
      return 'Anders';
    default:
      assertNever(freq);
  }
}
