import { ContentTemplate } from '../../../models/puzzles/ContentTemplate';

const puzzles: ContentTemplate = {
  'content-1': {},
  'content-2': {},
  'content-3': {},
  'content-4': {},
  'content-5': {},
  'content-6': {},
  'content-7': {},
  'content-8': {},
  'content-9': {},
  'content-10': {},
};

export default puzzles;
