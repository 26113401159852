import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader, getText } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const crossword: AnyElement[] = [
  ...(getHeader(0, 0, 1808, 'Kruiswoord', 'puzzel', true) as any),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 112,
    left: 0,
    width: 768,
    height: 1216,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 112,
    left: 800,
    width: 1008,
    height: 1216,
  },
  getCopyrightLabel(1328, 1568, 'Puzzelaar'),
];

const sudokuBlock: AnyElement[] = [
  ...getHeader(1376, 0, 1808, 'Sudoku'),
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1504,
    left: 0,
    width: 592,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1568,
    left: 0,
    width: 592,
    height: 592,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1504,
    left: 608,
    width: 592,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1568,
    left: 608,
    width: 592,
    height: 592,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1504,
    left: 1216,
    width: 592,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1568,
    left: 1216,
    width: 592,
    height: 592,
  },
  getCopyrightLabel(2164, 1588, 'Q42'),
];

// This puzzle was lower before but we needed to put extra solutions. This is the reason of this offset.
const topOffsetToevoegAnagram = 600;

const toevoegAnagram: AnyElement[] = [
  ...getHeader(2848 - topOffsetToevoegAnagram, 0, 1808, 'Toevoeg', 'anagram'),
  ...getText(
    2960 - topOffsetToevoegAnagram,
    0,
    1808,
    120,
    'Op elke regel moeten 3 woorden ingevuld worden. Het opvolgende woord bestaat uit de letters van het voorgaande woord plus een letter. In de kolom met het pijltje komt een trefwoord te staan.'
  ),

  {
    type: '@content/renderer',
    contentId: 'content-5',
    renderType: 'clues',
    onlyClues: true,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 3065 - topOffsetToevoegAnagram,
    left: 0,
    width: 1808,
    height: 190,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    onlyGrid: true,
    top: 3290 - topOffsetToevoegAnagram,
    left: 0,
    width: 1808,
    height: 944,
  },
  getCopyrightLabel(3248 - topOffsetToevoegAnagram, 1568, 'Puzzelaar'),
];

const quizFilippine: AnyElement[] = [
  ...getHeader(0, 1904, 1248, 'Quiz', 'filippine'),
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'filippine',
    highlightColor: config.colorOrange,
    arrowsInHighlightedColumn: true,
    top: 112,
    left: 1904,
    width: 1248,
    height: 1360,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'clues',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    alignNumbers: 'right',
    top: 1510,
    left: 1904,
    width: 1248,
    height: 992,
  },
  getCopyrightLabel(1476, 2880, 'Puzzelaar'),
];

const solutionSudokuBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 1904,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 1904 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 2704,
    left: 1904,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2336,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2336 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 2704,
    left: 2336,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2768,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2768 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 2704,
    left: 2768,
    width: 384,
    height: 384,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 1904,
    width: 352,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    top: 3168,
    left: 1904,
    width: 352,
    height: 432,
    solution: true,
  },
];

const solutionCryptogram: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3648 - 48,
    left: 1904,
    width: 1248,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3648,
    left: 1904,
    width: 432,
    height: 432,
  },
];

const solutionSwedish: AnyElement[] = [
  {
    type: 'text',
    value: 'Zweedse puzzel - ',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 2288,
    width: 432,
    height: 32,
  },
  {
    type: 'text',
    value: '@content/solutionWord',
    contentId: 'solution-2-1',
    color: config.colorBlack,
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 2288 + 278,
    width: 432,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-2-1',
    blackCellColor: config.colorBlack,
    renderType: 'grid',
    textCellBackgroundColor: config.colorGrey,
    solution: true,
    top: 3168,
    left: 2288,
    width: 864,
    height: 1088,
  },
];

const solutionsBlock: AnyElement[] = [
  ...getHeader(2544, 1904, 1248, 'Oplossingen ', '@SS'),
  ...solutionSudokuBlock,
  ...solutionCrossword,
  ...solutionCryptogram,
  ...solutionSwedish,
];

const solutionQuento: AnyElement[] = [
  {
    type: 'text',
    value: 'Quento',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 16,
    width: 400,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    solution: true,
    top: 3824,
    left: 16,
    width: 400,
    height: 432,
  },
];

const solutionPacman: AnyElement[] = [
  {
    type: 'text',
    value: 'Woord Pacman',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 370,
    width: 272,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 3824,
    left: 370,
    width: 272,
    height: 224,
  },
];

const solutionsTectonic: AnyElement[] = [
  {
    type: 'text',
    value: 'Cijferblok',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 700,
    width: 336,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-8',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3776 + 32,
    left: 700,
    width: 336,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-8',
    solution: true,
    top: 3824 + 32,
    left: 700,
    width: 336,
    height: 432 - 32,
  },
  {
    type: 'text',
    value: 'Cijferblok',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 1080,
    width: 336,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-9',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3776 + 32,
    left: 1080,
    width: 336,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-9',
    solution: true,
    top: 3824 + 32,
    left: 1080,
    width: 336,
    height: 432 - 32,
  },
];

const solutionsKoprol: AnyElement[] = [
  {
    type: 'text',
    value: 'Koprol 1 & 2',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 1472,
    width: 352,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-10',
    solution: true,
    fontSize: config.fontSizeSmall,
    lineGap: -1,
    top: 3820,
    left: 1472,
    width: 352,
    height: 210,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-11',
    solution: true,
    fontSize: config.fontSizeSmall,
    lineGap: -1,
    top: 4059,
    left: 1472,
    width: 352,
    height: 210,
  },
];

const solutionsBlockExtra: AnyElement[] = [
  ...getHeader(3664, 0, 1824, 'Oplossingen ', '@SS'),
  ...solutionQuento,
  ...solutionPacman,
  ...solutionsTectonic,
  ...solutionsKoprol,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Monday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
      ],
      ...settings,
    },
    templates: {},
    elements: [
      ...crossword,
      ...sudokuBlock,
      ...toevoegAnagram,
      ...quizFilippine,
      ...solutionsBlock,
      ...solutionsBlockExtra,
    ],
  },
};

export default layoutTemplate;
