var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userHasAccess)?_c('div',[(_vm.preset)?_c('div',[_c('BreadCrumbs',{attrs:{"bread-crumbs":[
        { name: 'Shop', link: '/shop' },
        { name: _vm.getDisplayNameByRenderer(_vm.renderer), link: '/shop/' + _vm.renderer },
        { name: _vm.preset.name } ]}}),_c('h4',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.preset.name))]),_c('section',{staticClass:"section columns"},[_vm._l((_vm.contentItems),function(ci){return _c('div',{key:ci.id,staticClass:"column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header p-3"},[_c('div',{staticClass:"card-header-title p-0"},[_vm._v("ID: "+_vm._s(ci.number))]),_c('div',{staticClass:"content buttons"},[(_vm.allowPrintRead)?_c('router-link',{attrs:{"to":("/puzzles/" + (_vm.preset.renderer) + "/" + _vm.presetId + "/content/" + (ci.id))}},[_c('b-icon',{attrs:{"type":"is-info","icon":"information-outline"}})],1):_vm._e(),(
                  _vm.preset.source === 'upload'
                    ? _vm.allowDownloadExternalPuzzles
                    : _vm.allowDownloadLicensedPuzzles
                )?_c('form',{staticClass:"mr-2",attrs:{"method":"post","action":_vm.downloadUrl,"target":"_blank"},on:{"submit":function($event){return _vm.onDownload(ci.id)}}},[_c('input',{attrs:{"type":"hidden","name":"token"},domProps:{"value":_vm.token}}),_c('input',{attrs:{"type":"hidden","name":"presetId"},domProps:{"value":_vm.presetId}}),_c('input',{attrs:{"type":"hidden","name":"puzzleId"},domProps:{"value":ci.id}}),_c('b-button',{staticClass:"ml-3",attrs:{"type":"is-success","icon-left":"cloud-download","native-type":"submit","value":"Download","disabled":!_vm.isDownloadable}},[_vm._v("Download puzzel")])],1):_c('b-tooltip',{attrs:{"label":_vm.preset.source === 'upload'
                    ? 'Geen rechten op extern ingekochte content'
                    : 'Geen rechten om te downloaden'}},[_c('b-button',{attrs:{"type":"is-success","disabled":""}},[_vm._v("Download")])],1)],1)]),_c('div',{staticClass:"card-image"},[_c('PreviewPuzzle',{attrs:{"puzzlePreset":_vm.preset,"puzzle":ci}})],1)])])}),_c('div',{staticClass:"column is-narrow"},[(_vm.index < _vm.contentItemsLength - 2)?_c('b-button',{on:{"click":function($event){_vm.index += 2}}},[_vm._v("Shuffle")]):_c('p',[_vm._v("Einde")])],1)],2)],1):_vm._e()]):_c('div',[_c('NoAccess')],1)}
var staticRenderFns = []

export { render, staticRenderFns }