import { IBlokjeOmPreset, IGeneratedBlokjeOm } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const BlokjeOmName = 'Blokje om';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedBlokjeOmIdInput(generated: IGeneratedBlokjeOm): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataBlokjeOm(): IBlokjeOmPreset {
  return {
    renderer: 'blokjeom',
    name: BlokjeOmName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      width: 9,
      height: 6,
      maxClueLength: 17,
    },
    rendererParams: {
      header: BlokjeOmName.toUpperCase(),
      explanation:
        'De letters van elk woord staan in willekeurige volgorde om de lege hokjes. ' +
        'Vind het woord en plaats de eerste letter in het lege hokje. ' +
        'Samen vormen deze letters de oplossing.',
    },
  };
}
