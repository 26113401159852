import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const swedishPuzzle: AnyElement[] = [
  ...getHeader(0, 0, 1504, 'Zweedse ', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'grid',
    highlightType: 'circle',
    highlightColor: config.colorRed,
    blackCellColor: config.colorGrey,
    textCellBackgroundColor: config.colorRed,
    top: 128,
    left: 0,
    width: 1504,
    height: 1216,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'bar',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 1424,
    left: 0,
    width: 1504,
    height: 144,
  },
  getCopyrightLabel(1348, 1248, 'Puzzelaar'),
];

const kakuro: AnyElement[] = [
  ...getHeader(0, 1616, 1536, 'Kakuro'),
  {
    type: '@content/renderer',
    contentId: 'content-2',
    blackCellColor: config.colorGrey,
    oddCellBackgroundColor: config.colorWhite,
    blackCellTextColor: config.colorBlack,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 128,
    left: 1616,
    width: 1536,
    height: 1520,
  },
  getCopyrightLabel(1652, 2896, 'Puzzelaar'),
];

const sudoku: AnyElement[] = [
  ...getHeader(1680, 0, 1504, 'Sudoku'),
  // Puzzle 1
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 0,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1856,
    left: 0,
    width: 704,
    height: 704,
  },
  // Puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 800,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1856,
    left: 800,
    width: 704,
    height: 704,
  },
  getCopyrightLabel(2568, 1310, 'Q42'),
];

const crosswordPuzzle: AnyElement[] = [
  ...getHeader(1696, 1616, 1536, 'Kruiswoord', 'puzzel'),
  // Clues
  {
    type: '@content/renderer',
    contentId: 'content-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 1808,
    left: 1616,
    width: 542,
    height: 1456,
  },
  // Puzzle
  {
    type: '@content/renderer',
    contentId: 'content-5',
    blackCellColor: config.colorBlack,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyGrid: true,
    top: 1808,
    left: 1616 + 542 + 16,
    width: 976,
    height: 1376,
  },
  getCopyrightLabel(3182, 2912, 'Puzzelaar'),
];

const binaire: AnyElement[] = [
  ...getHeader(2656, 0, 1504, 'Binaire'),
  // Puzzle 1
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'content-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2774,
    left: 0,
    width: 704,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 2829,
    left: 0,
    width: 704,
    height: 704,
  },
  // Puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2774,
    left: 800,
    width: 704,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    top: 2829,
    left: 800,
    width: 704,
    height: 704,
  },
  getCopyrightLabel(3492 + 45, 1310, 'Q42'),
];

const prizeBanner: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'play-banner',
    top: 3250,
    left: 1632,
    width: 1520,
    height: 336,
    valign: 'bottom',
  },
];

const solutionSudokuBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 0,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 0 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3810,
    left: 0,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 440,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 440 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 3810,
    left: 440,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 880,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3810 - 48,
    left: 880 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 3810,
    left: 880,
    width: 384,
    height: 384,
  },
];

const solutionQuizFilippine: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3680 - 48,
    left: 2664,
    width: 192,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    blackCellColor: config.colorBlack,
    solutionType: 'grid',
    solution: true,
    top: 3680,
    left: 2664,
    width: 512,
    height: 512,
  },
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/solutionTxt',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3680 + 528,
    left: 2664,
    width: 512,
    height: 32,
  },
];

const solutionsCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3682 - 48,
    left: 1320,
    width: 432,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3682,
    left: 1320,
    width: 432,
    height: 512,
  },
];

const solutionToevoegAnagram: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3760,
    left: 1808,
    width: 900,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3808,
    left: 1808,
    width: 900,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/solutionWord',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 4200,
    left: 1800,
    width: 704,
    height: 32,
  },
];

const solutions: AnyElement[] = [
  ...getHeader(3576, 0, 1264, 'Oplossingen ', '@SS'),
  ...solutionSudokuBlock,
  ...solutionsCrossword,
  ...solutionQuizFilippine,
  ...solutionToevoegAnagram,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Wednesday with monday solutions template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {},
    elements: [
      ...swedishPuzzle,
      ...kakuro,
      ...sudoku,
      ...crosswordPuzzle,
      ...binaire,
      ...prizeBanner,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
