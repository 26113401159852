import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { copyright } from '../../hln/components/layoutParts';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const swedishCrossword: AnyElement[] = [
  {
    type: '@content/renderer',
    top: 0,
    left: 0,
    width: 3152,
    height: 4080,
    contentId: 'content-1',
    renderType: 'grid',
    align: 'center',
    textCellBackgroundColor: config.colorRed,
    blackCellColor: config.colorGrey,
    fontSize: config.fontSizeSmall,
    separatorOffsetLeft: 5,
    highlightType: 'circle',
    fontSizeFactor: 0.167871014, // makes 5.5
  } as any,
  {
    type: 'custom',
    name: '@content/renderer',
    contentId: 'content-1',
    renderType: 'bar',
    top: 4096,
    left: 8,
    width: 1504,
    height: 160,
  },
  // Copyright
  {
    type: 'text',
    value: '© De Puzzelaar',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 4092,
    left: 2896,
    width: 256,
    height: 32,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Saturday Swedish template',
    layout,
    settings: {
      solutionsForThisSchema: ['content-1'],
      ...settings,
    },
    templates: {
      copyright: copyright as any,
    },
    elements: [...swedishCrossword],
  },
};

export default layoutTemplate;
