import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 0, y: 1656 },
          { x: 1752, y: 1656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 0, y: 2656 },
          { x: 3008, y: 2656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1752, y: 0 },
          { x: 1752, y: 2656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 2152, y: 2872 },
          { x: 3008, y: 2872 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 2152, y: 2873 },
          { x: 2152, y: 3992 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 752, y: 2656 },
          { x: 752, y: 3992 },
        ],
      },
    ],
  },
];

const wordFind: AnyElement[] = [
  // header
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-1',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 0,
    left: 0,
    width: 576,
    height: 72,
  },
  // cluewords
  {
    type: '@content/renderer',
    contentId: 'content-1',
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    onlyText: true,
    hideSolutionGrid: true,
    columns: 5,
    lineGap: 25,
    top: 110,
    left: 0,
    width: 1688,
    height: 544,
  },
  // puzzle
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    top: 624,
    left: 0,
    width: 1688,
    height: 888,
  },
  {
    type: 'text',
    value: 'Oplossing: ',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 1560,
    left: 0,
    width: 200,
    height: 88,
  },
  // solutiongrid
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlySolutionGrid: true,
    top: 1544,
    left: 200,
    width: 1480,
    height: 88,
  },
  getCopyrightLabelByOrigin(1256, 1692, 'content-1'),
];

const swedish: AnyElement[] = [
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-2',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 1672,
    left: 0,
    width: 816,
    height: 72,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'grid',
    top: 1752,
    left: 0,
    width: 1688,
    height: 856,
  },
  getCopyrightLabelByOrigin(2344, 1692, 'content-2'),
];

const stepLadder: AnyElement[] = [
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-3',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 0,
    width: 584,
    height: 72,
  },
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-3',
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 2752,
    left: 0,
    width: 712,
    height: 128,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'clues',
    top: 2896,
    left: 16,
    width: 656,
    height: 360,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'grid',
    top: 3296,
    left: 0,
    width: 750,
    height: 696,
  },
  getCopyrightLabelByOrigin(3728, 728, 'content-3'),
];

const crossword: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'content-4',
    blackCellColor: [0, 0, 0, 100],
    onlyGrid: true,
    top: 0,
    left: 1800,
    width: 1184,
    height: 1336,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    fontSize: config.fontSizeSmall,
    onlyText: true,
    continuous: false,
    top: 1350,
    left: 1794,
    width: 616,
    height: 1282,
  },
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-4',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1450,
    left: 2408,
    width: 568,
    height: 336,
  },
  // Solution
  {
    type: 'text',
    value: 'oplossingen @SS',
    dateFormat: 'd LLLL',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1856,
    left: 2408,
    width: 568,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    blackCellColor: [0, 0, 0, 100],
    top: 1912,
    left: 2416,
    width: 512,
    height: 568,
  },
  {
    type: 'text',
    value: 'Een boekenbon gaat naar:',
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 2496,
    left: 2408,
    width: 568,
    height: 112,
  },
  getCopyrightLabelByOrigin(920, 1768, 'content-4'),
];

const droedelRebus: AnyElement[] = [
  // Header
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-5',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 768,
    width: 386,
    height: 72,
  },
  // explanation
  {
    type: '@content/renderer',
    contentId: 'droedel-rebus-voorbeeld',
    top: 2696,
    left: 1696,
    width: 224,
    height: 152,
  },
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-5',
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 2696,
    left: 1960,
    width: 1032,
    height: 152,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    renderType: 'imageClueBlocks',
    cluesColumns: 3,
    cluesRows: 2,
    top: 2880,
    left: 824,
    width: 1200,
    height: 616,
  },
  // grid
  {
    type: '@content/renderer',
    contentId: 'content-5',
    renderType: 'filippine',
    arrowsInHighlightedColumn: true,
    top: 3520,
    left: 808,
    width: 1248,
    height: 472,
  },
  getCopyrightLabelByOrigin(3728, 2120, 'content-5'),
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'oplossingen vorige week',
    font: config.fontBold,
    fontSize: config.fontSizeLarge,
    top: 2904,
    left: 2192,
    width: 750,
    height: 64,
  },
  // wordfind:
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-1',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 2976,
    left: 2192,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    onlyGrid: true,
    markWordsVisually: true,
    top: 3016,
    left: 2192,
    width: 720,
    height: 376,
  },
  {
    type: 'text',
    value: '@content/solution',
    contentId: 'solution-1-1',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3400,
    left: 2192,
    width: 320,
    height: 32,
  },
  // Swedish Puzzle
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-2',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3464,
    left: 2192,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3504,
    left: 2192,
    width: 480,
    height: 248,
  },
  // Quiz Filippine
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-3',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3760,
    left: 2192,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    fontSize: config.fontSizeSmall,
    solution: true,
    solutionType: 'text',
    top: 3800,
    left: 2192,
    width: 816,
    height: 120,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Trouw - Zaterdag pagina 2',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [
      ...lines,
      ...wordFind,
      ...swedish,
      ...crossword,
      ...stepLadder,
      ...droedelRebus,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
