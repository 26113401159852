











import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IPacManPreset } from '../../../../../functions/src/models/puzzles/pacman/interfaces';

@Component
export default class PacManPresetEditor extends Vue {
  @PropSync('presetData') preset!: IPacManPreset;
}
