export default {
  background: {},
  header: {},
  'edition-info': {},
  'front-image': {},
  /**
   * magic property! If the first page of a booklet contains this property
   * it is used to set the pagenumber of all subsequent pages to `offset + index`
   */
  'page-number-offset': {},
};
