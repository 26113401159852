/*
Titels van de puzzels: Sudoku en Woordzoeker
Coco gothic light - All caps
19 pt

Woorden woordzoekers
Coco gothic light - All caps
8 pt

Uitleg sudoku
Coco gothic regular
9 pt
*/

import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

const config = {
  scale: DEFAULT_SCALE,
  width: 1920,
  height: 2112,
  gridSize: 64,
  fontRegular: 'CocoGothic.otf',
  fontLight: 'CocoGothic-Light.otf',
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  fontSizeLarge: 19 / DEFAULT_SCALE,
  fontSizeRegular: 9 / DEFAULT_SCALE,
  fontSizeSmall: 7 / DEFAULT_SCALE,
};

export default config;
