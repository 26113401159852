import { LayoutDb } from '../models/db/Layout';
import { clone } from '../utils';
import adrLayouts from './adr';
import deMorgenLayouts from './demorgen';
import hlnLayouts from './hln';
import margrietLayouts from './margriet';
import paroolLayouts from './parool';
import adrPrizePuzzleLayouts from './prizepuzzle';
import storyLayouts from './story';
import trouwLayouts from './trouw';
import volkskrantLayouts from './volkskrant';

export const layouts: LayoutDb[] = [
  ...adrLayouts,
  ...adrPrizePuzzleLayouts,
  ...storyLayouts,
  ...deMorgenLayouts,
  ...margrietLayouts,
  ...paroolLayouts,
  ...trouwLayouts,
  ...volkskrantLayouts,
  ...hlnLayouts,
];

// check of id's wel uniek zijn!!
const idsFromLayouts = layouts.map((l) => l.id);
const uniqueLayouts = idsFromLayouts.filter((l, i) => idsFromLayouts.indexOf(l) === i);
if (uniqueLayouts.length !== layouts.length) {
  console.error('De layout IDs zijn niet uniek :( check layouts/index.ts', {
    data: idsFromLayouts,
  });
  throw new Error('De layout IDs zijn niet uniek :( check layouts/index.ts');
}

export function getLayoutById(id: string | null | undefined) {
  if (!id) return null;
  const layout = layouts.find((l) => l.id === id);
  if (!layout) return null;
  return clone(layout);
}
