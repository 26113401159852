import { ICalcudokuPreset, IGeneratedCalcudoku } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const CalcudokuName = 'Calcudoku';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedCalcudokuIdInput(generated: IGeneratedCalcudoku): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataCalcudoku(): ICalcudokuPreset {
  return {
    renderer: 'calcudoku',
    name: CalcudokuName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      size: 9,
    },
    rendererParams: {
      header: CalcudokuName.toUpperCase(),
      explanation:
        'Plaats, net als bij een sudoku, de cijfers 1 t/m 9 zodanig dat ze in elke rij en iedere kolom eenmaal voorkomen. ' +
        'De cijfers in een vetomrand vak hebben de uitkomst volgens de aangegeven bewerking (+ - × ÷).',
    },
  };
}
