import { IGeneratedWhoWhatWhere, IWhoWhatWherePreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const WhoWhatWhereName = 'Wie Wat Waar';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedWhoWhatWhereIdInput(generated: IGeneratedWhoWhatWhere): string {
  return generated.solution + ': ' + generated.questions.map((q) => q.question).join(' & ');
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataWhoWhatWhere(): IWhoWhatWherePreset {
  return {
    renderer: 'whowhatwhere',
    name: WhoWhatWhereName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      amountOfQuestions: 6,
    },
    rendererParams: {
      header: WhoWhatWhereName.toUpperCase(),
      explanation:
        'Beantwoord de vragen, ontdek zo de zes letters van het oplossingswoord en schrijf deze in de juiste volgorde in het balkje.\nOpgelet: je moet deze volgorde zelf vinden!',
    },
  };
}
