import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const crossWord: AnyElement[] = [
  ...getHeader(0, 0, 1808, 'Kruiswoord', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 128,
    left: 0,
    width: 672,
    height: 1536,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    top: 128,
    left: 688,
    width: 1120,
    height: 1536,
  },
  getCopyrightLabel(1672, 1554, 'Puzzelaar'),
];

const swedish: AnyElement[] = [
  ...getHeader(0, 1888, 1264, 'Zweedse ', 'puzzel'),
  {
    type: '@content/renderer',
    useImagePuzzle: true,
    contentId: 'content-7',
    renderType: 'grid',
    highlightType: 'circle',
    highlightColor: config.colorRed,
    blackCellColor: config.colorGrey,
    textCellBackgroundColor: config.colorRed,
    top: 128,
    left: 1888,
    width: 1232,
    height: 2240,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    renderType: 'bar',
    valign: 'bottom',
    top: 2432,
    left: 1888,
    width: 1122,
    height: 144,
  },
  getCopyrightLabel(2576, 2780, 'Puzzelaar'),
];

const sudokus: AnyElement[] = [
  ...getHeader(1792, 0, 1808, 'Sudoku'),
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2000 - 60,
    left: 0,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 2000,
    left: 0,
    width: 576,
    height: 576,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2000 - 60,
    left: 616,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    top: 2000,
    left: 616,
    width: 576,
    height: 576,
    contentId: 'content-3',
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2000 - 60,
    left: 1232,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 2000,
    left: 1232,
    width: 576,
    height: 576,
  },
  getCopyrightLabel(2576, 1614, 'Q42'),
];

const playBanner: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'play-banner',
    top: 2720,
    left: 0,
    width: 1808,
    height: 336,
  },
];

const tentjeBoompje: AnyElement[] = [
  ...getHeader(3152, 0, 1808, 'Tentje', ' boompje'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-5',
    color: config.colorBlack,
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    top: 3280,
    left: 0,
    width: 1616,
    height: 192,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 3488,
    left: 0,
    width: 768,
    height: 768,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 3488,
    left: 1024,
    width: 768,
    height: 768,
  },
  getCopyrightLabel(3256, 1614, 'Q42'),
];

const solutionsSudoku: AnyElement[] = [
  // Puzzle 1
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 1904 - 16,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 1904 - 16 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 2880,
    left: 1904 - 16,
    width: 384,
    height: 384,
  },
  // Puzzle 2
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 2336 - 16,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 2336 - 16 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    solution: true,
    top: 2880,
    left: 2336 - 16,
    width: 384,
    height: 384,
    contentId: 'solution-1-3',
  },
  // Puzzle 3
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 2768 - 16,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2880 - 48,
    left: 2768 - 16 + 124,
    width: 384 + 16 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    solution: true,
    top: 2880,
    left: 2768 - 16,
    width: 384,
    height: 384,
    contentId: 'solution-1-4',
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3344 - 48,
    left: 1904 - 16,
    width: 448,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    solution: true,
    onlyGrid: true,
    top: 3344,
    left: 1904 - 16,
    width: 448,
    height: 448,
  },
];

const solutionCijferCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3344 - 48,
    left: 2320,
    width: 448,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    onlyGrid: true,
    solution: true,
    blackCellColor: config.colorBlack,
    top: 3344,
    left: 2320,
    width: 448,
    height: 448,
  },
];

const solutionHoningraad: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3872 - 48,
    left: 1888,
    width: 608,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3872,
    left: 1888,
    width: 640,
    height: 384,
  },
];

const solutions: AnyElement[] = [
  ...getHeader(2704, 1888, 1264, 'Oplossingen ', '@SS'),
  ...solutionsSudoku,
  ...solutionCrossword,
  ...solutionCijferCrossword,
  ...solutionHoningraad,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Friday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {},
    elements: [...crossWord, ...swedish, ...sudokus, ...playBanner, ...tentjeBoompje, ...solutions],
  },
};

export default layoutTemplate;
