




import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IImagePuzzlePreset } from '../../../../../functions/src/models/puzzles/imagepuzzle/interfaces';

@Component
export default class ImagePuzzlePresetEditor extends Vue {
  @PropSync('presetData') preset!: IImagePuzzlePreset;
}
