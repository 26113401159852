import { IGeneratedGroterKleiner, IGroterKleinerPreset } from './interfaces';

export const GroterKleinerName = 'Groter dan/kleiner dan';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedGroterKleinerIdInput(generated: IGeneratedGroterKleiner): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataGroterKleiner(): IGroterKleinerPreset {
  return {
    renderer: 'groterkleiner',
    name: GroterKleinerName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      size: 9,
    },
    rendererParams: {
      header: GroterKleinerName.toUpperCase(),
      explanation:
        'Elke rij en elke kolom bevat de cijfers van 1 t/m 9. ' +
        'Hou rekening met de groter-dan (>) en kleiner-dan (<) tekens die tussen de cellen staan. ' +
        'Bijvoorbeeld als de relatie tussen 2 lege cellen de volgende is: A<B, dan weet je dat A niet 9 en B niet 1 kan zijn.',
    },
  };
}
