import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { logPageView } from '../services/analytics';
import { db, env } from '../store/firestore-config';
import homepageComponent from '../views/HomePage.vue';
import adminRouter from './admin';
import mediaRouter from './media';
import printRouter from './print';
import puzzlesRouter from './puzzles';
import { RouteName } from './RouteName';
import shopRouter from './shop';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: homepageComponent,
  },
  ...shopRouter,
  ...printRouter,
  ...puzzlesRouter,
  ...mediaRouter,
  ...adminRouter,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active',
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  const pageName = to.name as RouteName | undefined;
  logPageView(to.fullPath, pageName);
});

router.beforeEach((to, from, next) => {
  const DEFAULT_TITLE = 'DPG Puzzel Printstraat';

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (env === 'LOCAL') {
    // Show this, to indicate local development & which database you're working on.
    const LOCAL_DB_INFO = `${env} (${db})`;
    document.title = nearestWithTitle
      ? `${nearestWithTitle.meta.title} - ${LOCAL_DB_INFO}`
      : `${DEFAULT_TITLE} - ${LOCAL_DB_INFO}`;
  } else {
    document.title = nearestWithTitle
      ? `${nearestWithTitle.meta.title} - ${DEFAULT_TITLE}`
      : DEFAULT_TITLE;
  }

  next();
});

export default router;
