import puzzlePageDetailComponent from '../components/print/PuzzlePageDetail.vue';
import puzzlePagesAgendaComponent from '../components/print/PuzzlePagesAgenda.vue';
import puzzlePagesArchiveComponent from '../components/print/PuzzlePagesArchive.vue';
import schedulerEditorComponent from '../components/print/SchedulerEditor/SchedulerEditorNew.vue';
import schedulersOverviewComponent from '../components/print/SchedulersOverview.vue';
import printPageComponent from '../views/PrintPage.vue';
import { RouteName } from './RouteName';

export default [
  {
    path: '/print',
    component: printPageComponent,
    redirect: '/print/pages',
    children: [
      {
        // Todo: Can we delete this?
        path: 'puzzlepages',
        redirect: '/print/pages',
      },
      {
        name: RouteName.PrintPuzzlePageOverview,
        path: 'pages',
        component: schedulersOverviewComponent,
        meta: { title: `Puzzel pagina's` },
      },
      {
        name: RouteName.PrintPuzzlePageNew,
        path: 'pages/new',
        component: schedulerEditorComponent,
        meta: { title: 'Nieuwe puzzel pagina' },
      },
      {
        name: RouteName.PrintPuzzlePage,
        path: 'pages/:id',
        component: schedulerEditorComponent,
        props: true,
        meta: { title: `Puzzel pagina's` },
      },
      {
        name: RouteName.PrintAgenda,
        path: 'agenda',
        component: puzzlePagesAgendaComponent,
        meta: { title: 'Agenda' },
      },
      {
        name: RouteName.PrintAgendaItem,
        path: 'agenda/:id',
        component: puzzlePageDetailComponent,
        props: true,
        meta: { title: 'Agenda' },
      },
      {
        name: RouteName.PrintArchive,
        path: 'archive',
        component: puzzlePagesArchiveComponent,
        meta: { title: 'Archief' },
      },
      {
        name: RouteName.PrintArchiveItem,
        path: 'archive/:id',
        component: puzzlePageDetailComponent,
        props: true,
        meta: { title: 'Archief' },
      },
    ],
  },
];
