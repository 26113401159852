import { ContentTemplate } from '../../../models/puzzles/ContentTemplate';

const puzzles: ContentTemplate = {
  'content-1': {},
  'content-2': {},
  'prize-banner': {},
  'supplier-banner': {},
};

export default puzzles;
