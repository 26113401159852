import { artifactsContent } from './components/artifacts-content';

export default {
  background: {},
  'page-number-left': {},
  'page-number-right': {},
  'content-1': {},
  'content-2': {},
  ...artifactsContent,
};
