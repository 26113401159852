export const textToWords = (text: string) => {
  let s = text;
  const done: Record<string, any> = {};

  s = s.replace(/\t|\n|\r/gi, ' ');
  // eslint-disable-next-line
  s = s.replace(/\&\w+\;/gi, ' ');
  s = s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  s = s.replace(/[:;"'']/gi, '');

  s = s.replace(/[^A-Za-z0-9 ]/gi, '').toLowerCase();
  s = s.replace(/\s\s+/g, ' ');
  s = s.replace(/^\s+|\s+$/g, '');

  // eslint-disable-next-line
  s = s.replace(/[\s \.\,\!\?\"\'\:\;\(\)\-""]/gi, '|');
  const ws = s.split('|');
  for (let i = 0; i < ws.length; i++) {
    const w = ws[i];
    if (!w || w.length == 0) continue;
    if (w.toLowerCase() != w) continue;
    if (w.toUpperCase() == w) continue;
    // eslint-disable-next-line
    if (/[ \-'0123456789\.\(\)\/]/.test(w)) continue;
    if (w.length < 3) continue;
    if (done[w]) continue;
    done[w] = true;
  }
  return Object.keys(done).sort();
};

const Config = {
  avgWordSizePerc: 0,
  minWordCount: 0,
  maxSize: 0,
};

export function suggestSize(words: string[]) {
  let totalLength = 0;
  let lens: Record<string, any> = {};
  for (let i = 0; i < words.length; i++) {
    const len = words[i].length;
    if (!lens[len]) lens[len] = 1;
    else lens[len]++;
    totalLength += len;
  }
  const avgLength = Math.floor(totalLength / words.length);

  // remove word lengths that are not not frequent enough
  for (const id in lens) {
    if (lens[id] < Config.minWordCount) {
      delete lens[id];
    }
  }

  lens = Object.keys(lens).sort(function (a: any, b: any) {
    return a * 1 - b * 1;
  });
  const len75 = lens[Math.floor(lens.length * Config.avgWordSizePerc) - 1] * 1;
  let size = len75;

  if (Config.maxSize) {
    size = Math.min(Config.maxSize, size);
  }

  return size;
}
