





































































import { LayoutDb } from 'functions/src/models/db/Layout';
import { SchedulerPageDb } from 'functions/src/models/db/Scheduler';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

import { layouts } from '../../../../functions/src/layouts';
import { clone } from '../../../utils';

@Component
export default class PagesEditor extends Vue {
  @Prop() hasError!: boolean;
  @VModel() pages!: SchedulerPageDb[];
  layouts = layouts.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  get layoutIsEditable() {
    return this.pages.length > 1;
  }

  onPageLayoutInput(layoutId: string, index: number) {
    const layoutObj: LayoutDb | undefined = layouts.find((l) => l.id === layoutId);
    if (!layoutObj) {
      throw new Error('Layout not found: ' + layoutId);
    }

    // this sets the layout json but we get that from disk instead of from db
    Vue.set(this.pages[index], 'layoutName', layoutObj.name || `Template #${layoutId}`);
    Vue.set(this.pages[index], 'content', clone(layoutObj.content));
  }

  addNewPage() {
    this.pages.push({
      layoutId: null,
    });
  }

  deletePage(index: number) {
    if (this.pages.length === 1) {
      return;
    } else {
      this.pages.splice(index, 1);
    }
  }

  movePage(fromIndex: number, toIndex: number) {
    if (fromIndex === toIndex || fromIndex < 0 || toIndex < 0) {
      return;
    }
    const update = [...this.pages];

    const pageToMove = update[fromIndex];
    const direction = toIndex < fromIndex ? -1 : 1;

    for (let i = fromIndex; i !== toIndex; i += direction) {
      update[i] = update[i + direction];
    }

    update[toIndex] = pageToMove;

    this.pages = update;
  }
}
