











import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IIkuraPreset } from '../../../../../functions/src/models/puzzles/ikura/interfaces';

@Component
export default class IkuraPresetEditor extends Vue {
  @PropSync('presetData') preset!: IIkuraPreset;
}
