












































import { IState } from 'src/models/models';
import { Component, Vue } from 'vue-property-decorator';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import { auth } from '../../store/db';
import { functionsUrl } from '../../store/firestore-config';

@Component({
  components: {
    BreadCrumbs,
  },
})
export default class ShopOrderList extends Vue {
  token: string | null = null;
  get downloadUrl() {
    return `${functionsUrl}downloadOrder`;
  }

  get orders() {
    return (this.$store.state as IState).orders || [];
  }

  async mounted() {
    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }
  }
}
