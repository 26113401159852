














import { Component, PropSync } from 'vue-property-decorator';

import { IJigsawCrosswordPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class JigsawCrosswordPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: IJigsawCrosswordPreset;
}
