import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 416 },
          { x: 3141, y: 416 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1840 },
          { x: 3141, y: 1840 },
        ],
      },
    ],
  },

  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3208 },
          { x: 3141, y: 3208 },
        ],
      },
    ],
  },
];

const crossWord: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 464,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 536 },
          { x: 3141, y: 536 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyText: true,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 560,
    left: 1272,
    width: 744,
    height: 1040,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    blackCellColor: config.colorBlack,
    top: 560,
    left: 2024,
    width: 1112,
    height: 1040,
  },
  getCopyrightLabelByOrigin(1600, 2024, 'content-1', 0),
];

const prizeBanner: AnyElement = {
  type: '@content/renderer',
  contentId: 'prize-banner',
  top: 1624,
  left: 1224,
  width: 1032,
  height: 144,
  debug: true,
};

const kakuro: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 1904,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1968 },
          { x: 3141, y: 1968 },
        ],
      },
    ],
  },
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2040,
    left: 1272,
    width: 248,
    height: 1112,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    blackCellColor: config.colorLightGrey,
    blackCellTextColor: config.colorBlack,
    oddCellBackgroundColor: config.colorWhite,
    top: 2040,
    left: 1528,
    width: 1608,
    height: 1112,
  },
  getCopyrightLabelByOrigin(3160, 1528, 'content-2', 0),
];

const sudokus: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 3280,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3344 },
          { x: 3141, y: 3344 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 3392,
    left: 1272,
    width: 912,
    height: 912,
  },
  getCopyrightLabelByOrigin(4304, 1272, 'content-3', 0),
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 3392,
    left: 2228,
    width: 912,
    height: 912,
  },
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 4344,
    left: 1272,
    width: 1816,
  },
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'OPLOSSINGEN',
    font: config.fontBold,
    fontSize: config.fontSizeLargeAlt,
    characterSpacing: config.letterSpacingLarge,
    rotation: 270,
    top: 0,
    left: 1272,
    width: 40,
    height: 380,
  },
  // crossword
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1368,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1368,
    width: 384,
    height: 352,
  },
  // cijfercode
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1872,
    width: 552,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1872,
    width: 552,
    height: 352,
  },
  // Sudoku Twins
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2552,
    width: 584,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 40,
    left: 2552,
    width: 584,
    height: 352,
    debug: true,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Volkskrant - Dinsdag layout',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [...lines, ...crossWord, prizeBanner, ...kakuro, ...sudokus, ...solutions],
  },
};

export default layoutTemplate;
