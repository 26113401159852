





















import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';
import { SchedulerDb } from 'functions/src/models/db/Scheduler';
import { TitleDb } from 'functions/src/models/db/Titles';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import ContentEditor from '../ContentEditor.vue';

@Component({
  components: {
    ContentEditor,
  },
})
export default class SchedulerVariantEditor extends Vue {
  @State('titles') titles!: (TitleDb & { id: string })[];
  @PropSync('schedulerData') syncedSchedulerData!: SchedulerDb;
  @Prop() puzzlePresets!: PuzzlePresetDb[];

  activeTab = 0;

  getVariantFormattedTitle(variantKey: string) {
    return this.titles.find((title) => title.id === variantKey)?.name;
  }

  mounted() {
    this.$store.dispatch('bindTitles');
  }

  beforeDestroy() {
    this.$store.dispatch('unbindTitles');
  }

  @Watch('activeTab')
  onActiveTabChange(activeTab: number) {
    this.$emit('tab-change', activeTab);
  }
}
