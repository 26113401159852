














import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IBattleshipPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class BattleshipPresetEditor extends Vue {
  @PropSync('presetData') preset!: IBattleshipPreset;
}
