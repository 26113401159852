import { IGeneratedIkura, IIkuraPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const IkuraName = 'Ikura';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedIkuraIdInput(generated: IGeneratedIkura): string {
  return generated.values.join(',');
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataIkura(): IIkuraPreset {
  return {
    renderer: 'ikura',
    name: IkuraName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      width: 3,
      height: 3,
    },
    rendererParams: {
      header: IkuraName.toUpperCase(),
      explanation:
        'Plaats de cijfers 1 tot en met 9 zodanig dat de optelsom in zowel de rij als de kolom klopt. ' +
        'Elk cijfer mag maar één keer gebruikt worden. ' +
        'Naast het diagram staan de cijfers die nog geplaatst moeten worden.',
    },
  };
}
