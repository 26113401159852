import { IGeneratedSamuraiSudoku, ISamuraiSudokuPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const SamuraiSudokuName = 'Sudoku Vijfling';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedSamuraiSudokuIdInput(generated: IGeneratedSamuraiSudoku): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataSamuraiSudoku(): ISamuraiSudokuPreset {
  return {
    renderer: 'samuraisudoku',
    name: SamuraiSudokuName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      difficulty: 1,
    },
    rendererParams: {
      header: SamuraiSudokuName.toUpperCase(),
      explanation:
        'De Sudoku vijfing bestaat uit 5 overlappende sudoku spellen. De hoeken van de middelste sudoku bestaan uit de hoeken van de ingeschoven spellen.',
    },
  };
}
