import { AnyElement, AnyPuzzleElement } from './ITemplate';
import { IGeneratedCalcudoku } from './puzzles/calcudoku/interfaces';
import { Content, PuzzlePresetGenerated } from './puzzles/Content';
import {
  ContentTemplate,
  ContentTemplateEmpty,
  ContentTemplateImage,
  ContentTemplatePuzzle,
  ContentTemplateText,
} from './puzzles/ContentTemplate';
import {
  IGeneratedCrossword,
  IGeneratedLetterSudoku,
  IGeneratedSwedishCrossword,
  IGeneratedWordfind,
} from './puzzles/IGeneratedPuzzle';
import { isPuzzleId, PuzzleIds } from './puzzles/IPuzzles';

export type UnknownPuzzle =
  | Record<string, string | number>
  | IGeneratedCrossword
  | IGeneratedSwedishCrossword
  | IGeneratedLetterSudoku
  | IGeneratedWordfind
  | IGeneratedCalcudoku;

type ContentItem =
  | PuzzlePresetGenerated
  | ContentTemplatePuzzle
  | ContentTemplateImage
  | ContentTemplateText
  | ContentTemplateEmpty;

export function contentArrayIsAssigned(tbd: (Content | ContentTemplate)[]): tbd is Content[] {
  if (!tbd || !Array.isArray(tbd)) {
    return false;
  }
  return tbd.every(contentIsAssigned);
}

export function contentIsAssigned(tbd: Content | ContentTemplate): tbd is Content {
  if (!tbd) return false;
  return Object.keys(tbd).every((key) => {
    const item = tbd[key];
    return !contentItemIsPuzzle(item) || contentItemIsAssigned(item);
  });
}

/**
 * step 1: empty
 */
export function contentItemIsEmpty(tbd: ContentItem): tbd is ContentTemplateEmpty {
  return Object.keys(tbd).length === 0 && tbd.constructor === Object;
}

/**
 * step 2 or 3: there is a renderer
 */
export function contentItemIsChosen(
  tbd: ContentItem
): tbd is ContentTemplatePuzzle | PuzzlePresetGenerated {
  return !!tbd.renderer;
}
/**
 * step 2 or 3: renderer is text or image
 */
export function contentItemIsTextOrImage(
  tbd: ContentItem
): tbd is ContentTemplateImage | ContentTemplateText {
  return contentItemIsText(tbd) || contentItemIsImage(tbd);
}
/**
 * step 2 or 3: renderer is text
 */
export function contentItemIsText(tbd: ContentItem): tbd is ContentTemplateImage {
  return tbd.renderer === 'text';
}
/**
 * step 2 or 3: renderer is image
 */
export function contentItemIsImage(tbd: ContentItem): tbd is ContentTemplateText {
  return tbd.renderer === 'image';
}
/**
 * step 2 or 3: renderer is a puzzle and there is a preset chosen
 */
export function contentItemIsPuzzle(
  tbd: ContentItem
): tbd is ContentTemplatePuzzle | PuzzlePresetGenerated {
  return isPuzzleId(tbd.renderer) && !!tbd.chosenPuzzlePresetId;
}

/**
 * step 3: puzzle is assigned
 */
export function contentItemIsAssigned(tbd: ContentItem): tbd is PuzzlePresetGenerated {
  const tbdt = tbd as PuzzlePresetGenerated;
  return contentItemIsPuzzle(tbd) && tbdt.generatorParams && !!tbdt.generatorParams.id;
}

export function isPuzzleElement(el: AnyElement): el is AnyPuzzleElement {
  return 'type' in el && (PuzzleIds as Readonly<string[]>).indexOf(el.type) >= 0;
}

export function IsUnknownPuzzleContainer(
  data:
    | string
    | number
    | UnknownPuzzle
    | UnknownPuzzle[]
    | Record<string, UnknownPuzzle[]>
    | Record<string, UnknownPuzzle>
): data is Record<string, UnknownPuzzle> {
  if (typeof data !== 'object') {
    return false;
  }
  if (Array.isArray(data)) {
    return false;
  }
  if (IsUnknownPuzzle(data)) {
    return false;
  }
  const puzzles = data[Object.keys(data)[0]];
  if (Array.isArray(puzzles)) {
    return false;
  }
  return IsUnknownPuzzle(puzzles);
}

export function IsUnknownPuzzlesContainer(
  data:
    | string
    | number
    | UnknownPuzzle
    | UnknownPuzzle[]
    | Record<string, UnknownPuzzle[]>
    | Record<string, UnknownPuzzle>
): data is Record<string, UnknownPuzzle[]> {
  if (typeof data !== 'object') {
    return false;
  }
  if (Array.isArray(data)) {
    return false;
  }
  if (IsUnknownPuzzle(data)) {
    return false;
  }
  const puzzles = data[Object.keys(data)[0]];
  if (!Array.isArray(puzzles)) {
    return false;
  }
  return puzzles.every((p) => IsUnknownPuzzle(p));
}

export function IsUnknownPuzzle(
  data:
    | string
    | number
    | UnknownPuzzle
    | UnknownPuzzle[]
    | Record<string, UnknownPuzzle[]>
    | Record<string, UnknownPuzzle>
): data is UnknownPuzzle {
  if (typeof data !== 'object') {
    return false;
  }
  if (Array.isArray(data)) {
    return false;
  }
  return Object.keys(data).length > 1;
}

export function IsStringArray(arr: any[]): arr is string[] {
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr.every((val) => typeof val === 'string');
}
