export const layoutIds = {
  adrMetWoordzoeker: '1',
  adrMetKruiswoord: '2',
  adrMetPrijsBlok: '28',
  adrPrijspuzzelMaandag: '4',
  adrPrijspuzzelDinsdag: '5',
  adrPrijspuzzelDinsdagMetVrijdagOplossingen: '131',
  adrPrijspuzzelWoensdag: '6',
  adrPrijspuzzelWoensdagMetVrijdagOplossingen: '136',
  adrPrijspuzzelDonderdag: '13',
  adrPrijspuzzelVrijdag: '7',
  adrPrijspuzzelVrijdagMetWoensdagOplossingen: '137',
  adrMagazineZonderPrijs: '8',
  adrMagazineWeekPrijs: '9',
  adrMagazineWeekendPrijs: '10',
  adrMagazineMaandPrijs: '11',
  huisAanHuisHalf: '3',
  huisAanHuisHeel: '12',
  storyVoorkant: '14',
  story1Puzzle: '15',
  story1WoordzoekerPuzzel: '30',
  story1Zweedse: '31',
  story2Puzzels: '17',
  story2PuzzelsZweedseOnder: '32',
  story3Puzzels: '18',
  storyOplossingen1: '19',
  storyOplossingen2: '20',
  deMorgenMaandag: '21',
  deMorgenMaandagMetOplossingenVrijdag: '132',
  deMorgenDinsdag: '22',
  deMorgenWoensdag: '23',
  deMorgenWoensdagMetOplossingenMaandag: '128',
  deMorgenDonderdag: '24',
  deMorgenDonderdagMetOplossingenDinsdag: '133',
  deMorgenVrijdag: '25',
  deMorgenZaterdag: '33',
  deMorgenZaterdagZweeds: '27',
  deMorgenZaterdagMetOplossingenDonderdag: '129',
  deMorgenDinsdagMetOplossingenZaterdag: '124',
  demorgenVrijdagMetWoensdagOplossingen: '125',
  paroolMaandag: '40',
  paroolDinsdag: '41',
  paroolWoensdag: '42',
  paroolDonderdag: '43',
  paroolVrijdag: '44',
  paroolZaterdag: '45',
  volkskrantMaandag: '60',
  volkskrantDinsdag: '61',
  volkskrantWoensdag: '62',
  volkskrantDonderdag: '63',
  volkskrantVrijdag: '64',
  volkskrantZaterdag: '65',
  margriet: '29',
  trouwMaandag: '50',
  trouwDinsdag: '51',
  trouwWoensdag: '52',
  trouwDonderdag: '53',
  trouwVrijdag: '54',
  trouwZaterdag1: '55',
  trouwZaterdag2: '56',
  trouwZaterdag3: '57',
  trouwZaterdag4: '58',
  hlnMaandag: '70',
  hlnMaandagMetOplossingenVrijdag: '134',
  hlnDinsdag: '71',
  hlnDinsdagMetOplossingenZaterdag: '112',
  hlnWoensdag: '72',
  hlnWoensdagMetOplossingenMaandag: '127',
  hlnDonderdag: '73',
  hlnDonderdagMetOplossingenDinsdag: '135',
  hlnVrijdag: '74',
  hlnVrijdagMetOplossingenWoensdag: '126',
  hlnZaterdag1: '75',
  hlnZaterdag2: '76',
  hlnZaterdag3: '77',
  hlnZaterdagMetOplossingenDonderdag: '130',
  hlnQ42: '78',
};
