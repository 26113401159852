































































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import { UserMixin } from '../mixins/User';
import { env } from '../store/firestore-config';

@Component
export default class HomePage extends mixins(UserMixin) {
  loketLoading = true;

  goToInbox() {
    window.location.href =
      env === 'PROD' ? 'https://inbox.42puzzles.com' : 'https://inbox-test.42puzzles.com';
  }

  async mounted() {
    const u = this.currentUser;
    if (!u) return;

    if (u.allowShopRead && !u.allowPrintRead) {
      await this.$router.push('/shop');
    }

    if (!u.allowShopRead && u.allowPrintRead) {
      await this.$router.push('/print');
    }
  }
}
