import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const swedishPuzzle: AnyElement[] = [
  ...getHeader(0, 0, 1504, 'Zweedse ', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'grid',
    highlightType: 'circle',
    highlightColor: config.colorRed,
    blackCellColor: config.colorGrey,
    textCellBackgroundColor: config.colorRed,
    top: 128,
    left: 0,
    width: 1504,
    height: 1216,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'bar',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 1424,
    left: 0,
    width: 1504,
    height: 144,
  },
  getCopyrightLabel(1348, 1248, 'Puzzelaar'),
];

const kakuro: AnyElement[] = [
  ...getHeader(0, 1616, 1536, 'Kakuro'),
  {
    type: '@content/renderer',
    contentId: 'content-2',
    blackCellColor: config.colorGrey,
    oddCellBackgroundColor: config.colorWhite,
    blackCellTextColor: config.colorBlack,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 128,
    left: 1616,
    width: 1536,
    height: 1520,
  },
  getCopyrightLabel(1652, 2896, 'Puzzelaar'),
];

const sudoku: AnyElement[] = [
  ...getHeader(1680, 0, 1504, 'Sudoku'),
  // Puzzle 1
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 0,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1856,
    left: 0,
    width: 704,
    height: 704,
  },
  // Puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 800,
    width: 192,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1856,
    left: 800,
    width: 704,
    height: 704,
  },
  getCopyrightLabel(2568, 1310, 'Q42'),
];

const crosswordPuzzle: AnyElement[] = [
  ...getHeader(1696, 1616, 1536, 'Kruiswoord', 'puzzel'),
  // Clues
  {
    type: '@content/renderer',
    contentId: 'content-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 1808,
    left: 1616,
    width: 542,
    height: 1456,
  },
  // Puzzle
  {
    type: '@content/renderer',
    contentId: 'content-5',
    blackCellColor: config.colorBlack,
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyGrid: true,
    top: 1808,
    left: 1616 + 542 + 16,
    width: 976,
    height: 1376,
  },
  getCopyrightLabel(3182, 2912, 'Puzzelaar'),
];

const binaire: AnyElement[] = [
  ...getHeader(2656, 0, 1504, 'Binaire'),
  // Puzzle 1
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'content-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2774,
    left: 0,
    width: 704,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 2829,
    left: 0,
    width: 704,
    height: 704,
  },
  // Puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2774,
    left: 800,
    width: 704,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    top: 2829,
    left: 800,
    width: 704,
    height: 704,
  },
  getCopyrightLabel(3492 + 45, 1310, 'Q42'),
];

const prizeBanner: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'play-banner',
    top: 3370,
    left: 1632,
    width: 1520,
    height: 336,
    valign: 'bottom',
  },
];

const solutionLegPuzzel: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-7',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 0,
    width: 448,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3776,
    left: 0,
    width: 448,
    height: 400,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 432,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3776,
    left: 432,
    width: 320,
    height: 400,
  },
];

const solutionsSudoku: AnyElement[] = [
  // sudoku makkelijk
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 752,
    width: 400,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 752 + 124,
    width: 400,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3776,
    left: 752,
    width: 400,
    height: 400,
  },
  // sudoku gemiddeld
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 1184,
    width: 400,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 1184 + 124,
    width: 400,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 3776,
    left: 1184,
    width: 400,
    height: 400,
  },
  // sudoku moeilijk
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 1616,
    width: 400,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 1616 + 124,
    width: 400,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 3776,
    left: 1616,
    width: 400,
    height: 400,
  },
];

const solutionsBinaire: AnyElement[] = [
  // binaire 1
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 2048,
    width: 115,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'solution-1-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 2048 + 115,
    width: 400 - 115,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3776,
    left: 2048,
    width: 400,
    height: 400,
  },
  // binaire 2
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 2480,
    width: 115,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3728,
    left: 2480 + 115,
    width: 400 - 115,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 3776,
    left: 2480,
    width: 400,
    height: 400,
  },
];

const solutions: AnyElement[] = [
  ...getHeader(3616, 0, 3152, 'Oplossing ', '@SS'),
  ...solutionLegPuzzel,
  ...solutionCrossword,
  ...solutionsSudoku,
  ...solutionsBinaire,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Wednesday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {},
    elements: [
      ...swedishPuzzle,
      ...kakuro,
      ...sudoku,
      ...crosswordPuzzle,
      ...binaire,
      ...prizeBanner,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
