




import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ISpotTheDifferencesPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class SpotTheDifferencesEditor extends Vue {
  @PropSync('presetData') preset!: ISpotTheDifferencesPreset;
}
