import { IToevoegAnagramPreset } from './interfaces';

export const getDefaultPresetDataToevoeganagram = (): IToevoegAnagramPreset => {
  return {
    renderer: 'toevoeganagram',
    source: 'upload',
    name: 'toevoegAnagram',
    stock: { approved: 1, available: 1 },
    usage: {
      expectedWeeklyUse: 0,
    },
    needsApproval: false,
    generatorParams: {
      height: 8,
      width: 15,
      grids: [
        {
          height: 8,
          width: 4,
        },
        {
          height: 8,
          width: 5,
        },
        {
          height: 8,
          width: 6,
        },
      ],
    },
    rendererParams: {
      header: '',
      explanation: '',
    },
  };
};
