import shopCart from '../components/shop/ShopCart.vue';
import shopHomeComponent from '../components/shop/ShopHome.vue';
import shopOrderDetail from '../components/shop/ShopOrderDetail.vue';
import shopOrderList from '../components/shop/ShopOrderList.vue';
import shopPuzzelPresetDetail from '../components/shop/ShopPuzzlePresetDetail.vue';
import shopPuzzelPresetList from '../components/shop/ShopPuzzlePresetList.vue';
import shopPageComponent from '../views/ShopPage.vue';
import { RouteName } from './RouteName';

export default [
  {
    path: '/shop',
    redirect: '/shop/home',
    component: shopPageComponent,
    children: [
      {
        name: RouteName.ShopHome,
        path: 'home',
        component: shopHomeComponent,
        meta: { title: 'Shop' },
      },
      {
        name: RouteName.ShopCart,
        path: 'cart',
        component: shopCart,
        meta: { title: 'Winkelmandje' },
      },
      {
        name: RouteName.ShopOrderOverview,
        path: 'orders',
        component: shopOrderList,
        meta: { title: 'Bestellingen' },
      },
      {
        name: RouteName.ShopOrderDetail,
        path: 'orders/:orderId',
        component: shopOrderDetail,
        meta: { title: 'Bestellingen' },
      },
      {
        name: RouteName.ShopPuzzleTypeOverview,
        path: ':renderer',
        component: shopPuzzelPresetList,
        props: true,
        meta: { title: 'Shop' },
      },
      {
        name: RouteName.ShopPuzzlePage,
        path: ':renderer/:presetId',
        component: shopPuzzelPresetDetail,
        props: true,
        meta: { title: 'Shop' },
      },
    ],
  },
];
