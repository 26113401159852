import { CMYKColor, ILineElement, ITemplate } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import { FONTS } from '../../constants';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { mezzaFonts, mezzaTemplates } from '../components/generic';

const MARGIN_TOP = 192;
const MARGIN_LEFT = 88;
const MARGIN_RIGHT = 66;
const PAGE_WIDTH = 2480;
const SPACER = 8;
const scale = DEFAULT_SCALE;

const PrizeBanner = {
  type: 'image' as const,
  path: '@content/image',
  contentId: 'puzzleAndWin',
  top: MARGIN_TOP - 40,
  left: MARGIN_LEFT,
  width: 912,
  height: 1600,
};
const HeaderBox = {
  type: 'custom' as const,
  top: MARGIN_TOP,
  height: 48,
  left: PAGE_WIDTH - MARGIN_RIGHT - 502,
  width: 512,
  name: 'header',
  value: '@content/header',
  contentId: 'content-1-1',
};
const HeaderLine: ILineElement = {
  type: 'line',
  lineWidth: 0.8 / scale,
  lines: [
    {
      coordinates: [
        { x: PrizeBanner.left + PrizeBanner.width * 0.6, y: HeaderBox.top + 24 },
        { x: PAGE_WIDTH - MARGIN_LEFT, y: HeaderBox.top + 24 },
      ],
    },
  ],
};

const SudokuHeadingText = {
  type: 'custom' as const,
  name: 'header',
  value: '@content/header',
  contentId: 'content-2-1',
  top: 2280,
  left: MARGIN_LEFT,
  width: 420,
};
const SudokuHeadingLine = {
  type: 'line' as const,
  lineWidth: 0.8 / scale,
  lines: [
    {
      coordinates: [
        { x: MARGIN_LEFT + 200, y: SudokuHeadingText.top + 24 },
        { x: MARGIN_LEFT + 200 + 948, y: SudokuHeadingText.top + 24 },
      ],
    },
  ],
};
const SudokuPuzzle = {
  type: 'sudoku' as const,
  contentId: 'content-2-1',
  alternatingColor: [0, 4, 15, 0] as CMYKColor,
  top: SudokuHeadingText.top + SPACER * 9,
  left: 88,
  width: 912,
  height: 912,
};

const WordSearchGrid = {
  type: 'wordfind' as const,
  contentId: 'content-1-1',
  onlyGrid: true,
  hideSolutionGrid: true,
  top: HeaderBox.top + HeaderBox.height + SPACER * 4,
  left: PrizeBanner.left + PrizeBanner.width + SPACER * 3,
  width: PAGE_WIDTH - PrizeBanner.width - MARGIN_LEFT - MARGIN_LEFT,
  height: 880,
  cellBorderWidth: 0.2,
  fontSize: 34, // supposed to be 9pt
};
const WordSearchSolutionGrid = {
  type: 'wordfind' as const,
  contentId: 'content-1-1',
  onlySolutionGrid: true,

  top: WordSearchGrid.top + WordSearchGrid.height + SPACER * 3,
  left: PAGE_WIDTH - MARGIN_RIGHT - 1060,
  width: 1060,
  height: 80,
};
const WordSearchSolutionGridText = {
  type: 'text' as const,
  value: 'Oplossing:',
  top: WordSearchSolutionGrid.top + WordSearchSolutionGrid.height * 0.3,
  left: WordSearchSolutionGrid.left - 190, // the 'oplossing' text gets cut off when it doesn't have an excess of space
  fontSize: 8 / DEFAULT_SCALE,
  width: 210,
  height: 80,
};

const cluesTop = WordSearchSolutionGrid.top + WordSearchSolutionGrid.height + SPACER * 4;
const WordSearchClues = {
  type: 'wordfind' as const,
  renderType: 'clueBlocks' as const,
  contentId: 'content-1-1',

  columns: 6,
  rows: 8,

  top: cluesTop,
  left: PrizeBanner.left,
  width: WordSearchGrid.left + WordSearchGrid.width - PrizeBanner.left,
  height: SudokuPuzzle.top + SudokuPuzzle.height - cluesTop,
  clueFont: mezzaFonts.text,
  clueFontSize: 8,
  indexFont: mezzaFonts.text,
  indexFontSize: 10,

  excludeRects: [
    // above the clues in the leftmost columns
    {
      left: PrizeBanner.left,
      top: WordSearchSolutionGrid.top + WordSearchSolutionGrid.height,
      width: WordSearchGrid.left - PrizeBanner.left,
      height:
        PrizeBanner.top +
        PrizeBanner.height -
        (WordSearchSolutionGrid.top + WordSearchSolutionGrid.height),
    },
    // below the clues in the leftmost columns
    {
      left: PrizeBanner.left,
      width: WordSearchGrid.left - PrizeBanner.left + 1,
      top: SudokuHeadingText.top - SPACER * 2,
      height: SudokuPuzzle.height + SPACER * 14, // the text and line don't have a height so this was just trial and error
    },
  ],
};

const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: PAGE_WIDTH,
      height: 3508,
      margin: {
        top: MARGIN_TOP,
        right: 0,
        bottom: 0,
        left: MARGIN_LEFT,
      },
      columns: 36,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      solutionsForThisSchema: ['content-1-1', 'content-2-1'],
      fonts: mezzaFonts,
    },
    templates: mezzaTemplates,
    // New elements for ADR are added here!
    elements: [
      // sudoku
      getCopyrightLabelByOrigin(2976 - 112, 56, 'content-1-1'),
      // wordsearch
      getCopyrightLabelByOrigin(
        3008,
        WordSearchClues.left + WordSearchClues.width + SPACER,
        'content-2-1'
      ),

      PrizeBanner,
      HeaderLine,
      HeaderBox,

      WordSearchGrid,
      WordSearchSolutionGrid,
      WordSearchSolutionGridText,
      WordSearchClues,

      SudokuHeadingLine,
      SudokuHeadingText,
      SudokuPuzzle,

      // Black vlakjes ding
      {
        type: 'custom',
        name: 'page-identifier',
        top: WordSearchClues.top + WordSearchClues.height + SPACER * 2,
        left: PAGE_WIDTH - MARGIN_RIGHT - 184,
        font: FONTS.LFT_ETICA_SEMI_BOLD,
      },
    ],
  },
};

export default layout;
