













import format from 'date-fns/format';
import { Component, Prop } from 'vue-property-decorator';

import { TypedVue } from '../..//store/typed-vue';

@Component
export default class DatePicker extends TypedVue {
  @Prop() value!: string | null;
  @Prop() placeholder!: string;

  get _date() {
    if (!this.value) {
      return null;
    }
    return new Date(this.value);
  }

  set _date(value: Date | null) {
    if (value === null) {
      this.$emit('input', null);
      return;
    }
    this.$emit('input', format(value, 'yyyy-MM-dd'));
  }
}
