import { ILayout } from '../../../models/ITemplate';
import { millimetersToPoints } from '../../../utils/conversion';
import { DEFAULT_SCALE } from '../../config';

// 'these values are here for easy reference :)',
// '(3118 - 3 * 64) / 4 = 731,5',
export const storyLayout: ILayout = {
  scale: DEFAULT_SCALE,
  width: millimetersToPoints(221), // 2610 * 0.24 = 626
  height: millimetersToPoints(270), // 3188 * 0.24 = 765
  margin: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  bleed: millimetersToPoints(8.292), // 97 * 0.24 = 23
  bleedBox: millimetersToPoints(3), // 35 * 0.24 = 8
  columns: 4,
  cellPadding: {
    horizontal: 64,
    vertical: 64,
  },
};
