import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import fridayContent from './content/parool-friday-content.json';
import mondayContent from './content/parool-monday-content.json';
import saturdayContent from './content/parool-saturday-content.json';
import thursdayContent from './content/parool-thursday-content.json';
import tuesdayContent from './content/parool-tuesday-content.json';
import wednesdayContent from './content/parool-wednesday-content.json';
import friday from './layout/parool-friday';
import monday from './layout/parool-monday';
import saturday from './layout/parool-saturday';
import thursday from './layout/parool-thursday';
import tuesday from './layout/parool-tuesday';
import wednesday from './layout/parool-wednesday';

const paroolLayouts: LayoutDb[] = [
  {
    id: layoutIds.paroolMaandag,
    name: 'Parool - 1. Maandag',
    json: monday,
    content: mondayContent,
  },
  {
    id: layoutIds.paroolDinsdag,
    name: 'Parool - 2. Dinsdag',
    json: tuesday,
    content: tuesdayContent,
  },
  {
    id: layoutIds.paroolWoensdag,
    name: 'Parool - 3. Woensdag',
    json: wednesday,
    content: wednesdayContent,
  },
  {
    id: layoutIds.paroolDonderdag,
    name: 'Parool - 4. Donderdag',
    json: thursday,
    content: thursdayContent,
  },
  {
    id: layoutIds.paroolVrijdag,
    name: 'Parool - 5. Vrijdag',
    json: friday,
    content: fridayContent,
  },
  {
    id: layoutIds.paroolZaterdag,
    name: 'Parool - 6. Zaterdag',
    json: saturday,
    content: saturdayContent,
  },
];

export default paroolLayouts;
