import { I0hh1GeneratorParams } from './0hh1/interfaces';
import { IBlokjeOmGeneratorParams } from './blokjeom/interfaces';
import { ICalcudokuGeneratorParams } from './calcudoku/interfaces';
import { IGroterKleinerGeneratorParams } from './groterkleiner/interfaces';
import { IHashiGeneratorParams } from './hashi/interfaces';
import { IIkuraGeneratorParams } from './ikura/interfaces';
import { IKakuroGeneratorParams } from './kakuro/interfaces';
import { IPacManGeneratorParams } from './pacman/interfaces';
import { ISamuraiSudokuGeneratorParams } from './samuraisudoku/interfaces';
import { ISudokuMixGeneratorParams } from './sudokumix/interfaces';
import { IToevoegAnagramGeneratorParams } from './toevoeganagram/interfaces';
import { IWhoWhatWhereGeneratorParams } from './whowhatwhere/interfaces';
import { IWoordKrakerGeneratorParams } from './woordkraker/interfaces';

export type IPuzzleGeneratorParams =
  | I0hh1GeneratorParams
  | I0hn0GeneratorParams
  | IBattleshipGeneratorParams
  | IBlokjeOmGeneratorParams
  | ICalcudokuGeneratorParams
  | ICodekrakerGeneratorParams
  | ICrosswordGeneratorParams
  | ICryptoFilippineGeneratorParams
  | IDoorloperCrosswordGeneratorParams
  | IFilippineGeneratorParams
  | IToevoegAnagramGeneratorParams
  | IGroterKleinerGeneratorParams
  | IHashiGeneratorParams
  | IHoroscopeSudokuGeneratorParams
  | IIkuraGeneratorParams
  | IJigsawCrosswordGeneratorParams
  | IKakuroGeneratorParams
  | IKillerSudokuGeneratorParams
  | IKnightmoveGeneratorParams
  | IKoprolGeneratorParams
  | ILetterSudokuGeneratorParams
  | IQuentoGeneratorParams
  | ISamuraiSudokuGeneratorParams
  | ISudokuGeneratorParams
  | ISudokuMixGeneratorParams
  | ISwedishCrosswordGeneratorParams
  | ITectonicGeneratorParams
  | ITentsTreesGeneratorParams
  | IWhoWhatWhereGeneratorParams
  | IWoordKrakerGeneratorParams
  | IWordfindGeneratorParams
  | IPacManGeneratorParams;

export type IWordPool =
  /**
   * scraped van ad.nl
   */
  | 'ad'
  /**
   * scraped van hln
   */
  | 'hln'
  /**
   * Nederlands
   */
  | 'nl'
  /**
   * Vlaams
   */
  | 'be'
  /**
   * Vlaams en Nederlands
   */
  | 'nlbe';
/**
 * Intern gebruikte woordpools, dus zonder BE en NLBE, die worden alleen door externe leveranciers gebruikt
 */
export const WordPools = ['ad', 'hln', 'nl'];

export interface IWordfindGeneratorParams {
  width: number;
  height: number;
  /** for themed wordlists */
  theme?: string;
  /**
   * optional because we don't know which was used by external suppliers
   */
  wordPool?: IWordPool;
  /**
   * set to lower when creating mini wordfinders
   */
  minWordLength?: number;
  /**
   * set to a value to fix the required words (which in turn makes the solutionword length variable)
   */
  maxWordCount?: number;
  /**
   * defaults to 10 or shorter if the board is smaller
   */
  maxSolutionLength?: number;
  seed?: string;
  difficulty?: number; // for now it's only used in the Dutchbook deliverables
}

export interface ITectonicGeneratorParams {
  width: number;
  height: number;
  groupSize: number;
  difficulty: number;
}

export interface IKoprolGeneratorParams {
  sizes: { width: number; height: number }[];
  feeds: string[];
  maxTail: number;
  language: 'nl' | 'be' | 'en';
}

export interface I0hn0GeneratorParams {
  size: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
}

export interface ICrosswordGeneratorParams {
  wordPool: IWordPool;
  difficulty: number;
  width: number;
  height: number;
}

export interface IDoorloperCrosswordGeneratorParams {
  width: number;
  height: number;
}

export interface ISudokuGeneratorParams {
  /**
   * difficulty is only available with size 9
   */
  difficulty: number;
  size: 4 | 5 | 6 | 8 | 9;
  seed?: string;
  /** technique that are required */
  requireTechniques?: {
    [index: number]: boolean | number;
  };
}

export interface ILetterSudokuGeneratorParams extends ISudokuGeneratorParams {
  wordPool?: IWordPool;
  word?: string;
}

export interface IHoroscopeSudokuGeneratorParams extends ISudokuGeneratorParams {
  size: 6;
}

export interface IQuentoGeneratorParams {
  /** 1-4 */
  difficulty: number;
}

export interface ITentsTreesGeneratorParams {
  width: 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
  height: 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
  difficulty: number;
}

export interface IBattleshipGeneratorParams {
  width: 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  height: 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  difficulty: number;
  ships: {
    [id: number]: number;
  };
}

export interface IKnightmoveGeneratorParams {
  word?: string;
  wordPool?: IWordPool;
}

export interface ICodekrakerGeneratorParams {
  width: number;
  height: number;
  difficulty: number;
}

export interface ICryptoFilippineGeneratorParams {
  width: number;
  height: number;
  difficulty: number;
}

export interface ISwedishCrosswordGeneratorParams {
  difficulty: number;
  width: number;
  height: number;
  hasSolutionWord: boolean;
}

export interface IKillerSudokuGeneratorParams {
  difficulty: number;
  size: 4 | 5 | 6 | 8 | 9;
  seed?: string;
}

export interface IFilippineGeneratorParams {
  width: number;
  height: number;
  difficulty: number;
}

export interface IJigsawCrosswordGeneratorParams {
  width: number;
  height: number;
  difficulty: number;
  wordPool?: IWordPool;
  minWordLength?: number;
  maxWordCount?: number;
  seed?: string;
}
