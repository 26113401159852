

















import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IWoordKrakerPreset } from '../../../../../functions/src/models/puzzles/woordkraker/interfaces';

@Component
export default class WoordKrakerPresetEditor extends Vue {
  @PropSync('presetData') preset!: IWoordKrakerPreset;
}
