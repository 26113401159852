import { IBattleshipPreset } from '../../db/PuzzlePreset';

// Used in: functions/src/utils/puzzleUtils.ts
export const BattleshipName = 'Zeeslag';

export function getDefaultPresetDataBattleship(): IBattleshipPreset {
  return {
    renderer: 'battleship',
    name: BattleshipName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      width: 10,
      height: 10,
      ships: { 1: 4, 2: 3, 3: 3, 4: 1 },
      difficulty: 3,
    },
    rendererParams: {
      header: BattleshipName.toUpperCase(),
      explanation:
        'De schepen liggen horizontaal of verticaal, maar ze raken elkaar nergens, ook niet diagonaal. De cijfers naast en onder geven aan hoeveel delen van de schepen zich in de betreffende tij of kolom bevinden.',
      showLegend: true,
    },
  };
}
