import { ITemplate } from '../../models/ITemplate';
import { ADR, DEFAULT_SCALE } from '../config';

const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: 3118,
      height: 2197,
      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      columns: 4,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      fonts: {
        header: 'HaasGroteskTextBold-Regular.otf',
        text: 'HaasGroteskDisplayRm-Rg.otf',
        textbold: 'HaasGroteskDisplayBd-Rg.otf',
        cell: 'HaasGroteskDisplayMd-Rg.otf',
      },
    },
    templates: {
      header: {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: ADR.HighlightHeaderBackgroundColor,
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 48,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 64,
            top: 8,
            padding: 8,
            right: 16,
            max: 4,
            template: 'light',
          },
        ],
      },
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 27,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 25,
            top: -8,
            padding: 0,
            right: 18,
            max: 4,
            template: 'dark',
          },
        ],
      },
      wordfind: {
        elements: [
          {
            type: 'wordfind',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSizeFactor: 0.6,
            fontSize: 38,
            lineGap: 10,
          },
        ],
      },
      crossword: {
        elements: [
          {
            type: 'crossword',
            font: '@page/settings/fonts/text',
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 38,
          },
        ],
      },
      quento: {
        elements: [
          {
            log: true,
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'quento',
            valign: 'bottom',
          },
        ],
      },
      koprol: {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'koprol',
            font: '@page/settings/fonts/text',
            valign: 'bottom',
          },
        ],
      },
    },

    rows: [
      {
        height: 1248,
        columns: [
          {
            colSpan: 3,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-1-2',
              },
            ],
          },
        ],
      },
      {
        height: 949,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-2-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-2-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-2-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-2-2',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-2-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                '+height': -128,
                contentId: 'content-2-3',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: 'OPLOSSINGEN',
                top: -64,
                left: 0,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-1-1',
                top: 64,
                left: 5,
                height: 22,
              },
              {
                type: 'text',
                font: '@page/settings/fonts/header',
                fontSize: 20,
                color: [0, 0, 0, 100],
                top: 88,
                left: 5,
                baseline: 'middle',
                value: '@content/solution',
                contentId: 'content-1-1',
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 320,
                height: 32,
                left: 5,
                top: 136,
                stars: '@content/difficulty',
                contentId: 'content-2-1',
              },
              {
                type: '@content/renderer',
                top: 160,
                left: 0,
                width: 320,
                valign: 'top',
                contentId: 'content-2-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 320,
                height: 32,
                left: 5,
                top: 512,
                stars: '@content/difficulty',
                contentId: 'content-2-2',
              },
              {
                type: '@content/renderer',
                top: 536,
                left: 0,
                width: 320,
                valign: 'top',
                contentId: 'content-2-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 320,
                height: 32,
                left: 393,
                top: 64,
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: '@content/renderer',
                top: 88,
                left: 388,
                width: 270,
                valign: 'top',
                contentId: 'content-1-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 320,
                height: 32,
                left: 393,
                top: 512,
                contentId: 'content-2-3',
              },
              {
                type: '@content/renderer',
                top: 536,
                left: 388,
                width: 320,
                valign: 'top',
                contentId: 'content-2-3',
                solution: true,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default layout;
