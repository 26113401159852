import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 416 },
          { x: 3141, y: 416 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1872 },
          { x: 3141, y: 1872 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3280 },
          { x: 3141, y: 3280 },
        ],
      },
    ],
  },
];

const crossWord: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 472,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 536 },
          { x: 3141, y: 536 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyText: true,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 560,
    left: 1272,
    width: 744,
    height: 1040,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    blackCellColor: config.colorBlack,
    top: 560,
    left: 2024,
    width: 1112,
    height: 1040,
  },
  getCopyrightLabelByOrigin(1600, 2024, 'content-1', 0),
];

const prizeBanner: AnyElement = {
  type: '@content/renderer',
  contentId: 'prize-banner',
  top: 1624,
  left: 1224,
  width: 1032,
  height: 144,
  debug: true,
};

const filippine: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 1968,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 2032 },
          { x: 3141, y: 2032 },
        ],
      },
    ],
  },
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2056,
    left: 1272,
    width: 784,
    height: 140,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'filippine',
    arrowsInHighlightedColumn: true,
    top: 2056,
    left: 2064,
    width: 1080,
    height: 1168,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'clues',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2208,
    left: 1280,
    width: 768,
    height: 1048,
  },
  getCopyrightLabelByOrigin(3248, 1272, 'content-2', 0),
];

const sudokuTwins: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 3376,
    left: 1272,
    width: 520,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3440 },
          { x: 3141, y: 3440 },
        ],
      },
    ],
  },
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3504,
    left: 1272,
    width: 264,
    height: 500,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    showBorder: true,
    top: 3472,
    left: 1592,
    width: 1544,
    height: 928,
  },
  getCopyrightLabelByOrigin(4152, 1560, 'content-3'),
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'OPLOSSINGEN',
    font: config.fontBold,
    fontSize: config.fontSizeLargeAlt,
    characterSpacing: config.letterSpacingLarge,
    rotation: 270,
    top: 0,
    left: 1272,
    width: 40,
    height: 380,
  },
  // crossword
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1368,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1368,
    width: 384,
    height: 352,
  },
  // cijfercode
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1792,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1792,
    width: 544,
    height: 352,
  },
  // sudokus
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2376,
    width: 1512,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 40,
    left: 2376,
    width: 352,
    height: 352,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2784,
    width: 1512,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 40,
    left: 2784,
    width: 352,
    height: 352,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Volkskrant - Vrijdag layout',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [...lines, ...solutions, ...crossWord, prizeBanner, ...filippine, ...sudokuTwins],
  },
};

export default layoutTemplate;
