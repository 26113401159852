import { IImageElement } from '../../../models/ITemplate';
import { storyLayout } from './story-layout';

export const contentIdNamesArtifacts: { [k: string]: any } = {
  pencilBottomLeft: 'pencil-bottom-left',
  pencilTopLeft: 'pencil-top-left',
  coffeeSpotTopRight: 'coffees-spot-top-right',
  cookieBottomRight: 'cookie-bottom-right',
  pencilBottomCenter: 'pencil-bottom-center',
};

// Elements below
const pencilTopLeft: IImageElement = {
  type: 'image',
  contentId: contentIdNamesArtifacts.pencilTopLeft,
  path: '@content/image',
  top: -(storyLayout.bleedBox ?? 0),
  left: -(storyLayout.bleedBox ?? 0),
  width: 900,
  ignoreRenderWarning: true,
};

const pencilBottomLeft: IImageElement = {
  type: 'image',
  contentId: contentIdNamesArtifacts.pencilBottomLeft,
  path: '@content/image',
  top: 2750,
  left: -(storyLayout.bleedBox ?? 0),
  width: 700,
  ignoreRenderWarning: true,
};

const coffeeSpotTopRight: IImageElement = {
  type: 'image',
  contentId: contentIdNamesArtifacts.coffeeSpotTopRight,
  path: '@content/image',
  top: 200,
  left: 2050,
  width: 500,
  ignoreRenderWarning: true,
};

const cookieBottomRight: IImageElement = {
  type: 'image',
  contentId: contentIdNamesArtifacts.cookieBottomRight,
  path: '@content/image',
  top: 2580,
  left: 1900,
  width: 600,
  ignoreRenderWarning: true,
};

const pencilBottomCenter: IImageElement = {
  type: 'image',
  contentId: contentIdNamesArtifacts.pencilBottomCenter,
  path: '@content/image',
  width: 2100,
  top: 2800,
  ignoreRenderWarning: true,
};

export const artifacts = [
  pencilBottomLeft,
  pencilTopLeft,
  coffeeSpotTopRight,
  cookieBottomRight,
  pencilBottomCenter,
];
