














import { IStepLadderPreset } from '../../../../../functions/src/models/puzzles/stepladder/interfaces';
import { Component, PropSync, Vue } from 'vue-property-decorator';

//

@Component
export default class StepLadderPresetEditor extends Vue {
  @PropSync('presetData') preset!: IStepLadderPreset;
}
