




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActionsHeader extends Vue {
  @Prop() header!: string;
}
