import config from './config';

export const headerLarge = {
  elements: [
    {
      type: 'text',
      color: config.colorRed,
      font: config.fontBold,
      fontSize: config.fontSizeLarge,
      baseline: 'middle',
      top: 48,
      height: 48,
    },
  ],
};

export const headerSmall = {
  elements: [
    {
      type: 'text',
      color: config.colorRed,
      font: config.fontBold,
      fontSize: config.fontSizeLarge,
      baseline: 'middle',
      top: 32,
      height: 0,
    },
  ],
};

export const headerSolution = {
  elements: [
    {
      type: 'text',
      color: config.colorRed,
      font: config.fontBold,
      fontSize: config.fontSizeRegular,
      baseline: 'middle',
      top: 32,
      height: 0,
    },
  ],
};

export const difficulty = {
  elements: [
    {
      type: 'box',
      height: 64,
      backgroundColor: [0, 0, 0, 0],
    },
    {
      type: 'stars',
      stars: '@content/difficulty',
      activeStar: 'star-parool-selected.png',
      align: 'right',
      height: 20,
      max: 6,
      padding: 8,
      top: 12,
    },
  ],
};

export const difficultySmall = {
  elements: [
    {
      type: 'box',
      height: 64,
      backgroundColor: [0, 0, 0, 0],
    },
    {
      type: 'stars',
      stars: '@content/difficulty',
      activeStar: 'star-parool-selected.png',
      align: 'right',
      height: 15,
      max: 6,
      padding: 8,
      top: 12,
    },
  ],
};
