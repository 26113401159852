/**
 * This file contains the puzzles that we want to be able to quick check.
 * The property name should match the renderer. The array should contain
 * the generated fields you want to check.
 */

export const batchCheckablePuzzles: Record<string, string[]> = {
  pacman: ['descriptions', 'words'],
  koprol: ['title', 'solution', 'tail', 'width', 'height'],
  lettersudoku: ['word'],
  wordfind: ['solution', 'words'],
  knightmove: ['word'],
  filippine: ['solutionTxt', 'words'],
  swedishcrossword: ['solutionWord'],
  codekraker: ['hwords', 'vwords'],
  blokjeom: ['solutionWord', 'words'],
};

export const stockListKeys: Record<string, string[]> = {
  pacman: ['descriptions', 'words'],
  koprol: ['title', 'solution'],
  lettersudoku: ['word'],
  wordfind: ['solution', 'words'],
  knightmove: ['word'],
  filippine: ['solutionTxt', 'words'],
  swedishcrossword: ['solutionWord'],
  codekraker: ['hwords', 'vwords'],
  blokjeom: ['solutionWord', 'words'],
};
