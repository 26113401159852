import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 416 },
          { x: 3141, y: 416 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1672 },
          { x: 3141, y: 1672 },
        ],
      },
    ],
  },

  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 2816 },
          { x: 3141, y: 2816 },
        ],
      },
    ],
  },
];

const crossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 464,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 536 },
          { x: 3141, y: 536 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyText: true,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 560,
    left: 1272,
    width: 744,
    height: 1024,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    blackCellColor: config.colorBlack,
    top: 560,
    left: 2032,
    width: 1104,
    height: 1024,
  },
  getCopyrightLabelByOrigin(1592, 2032, 'content-1', 0),
];

const sudokus: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 1712,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1800 },
          { x: 3141, y: 1800 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1824,
    left: 1272,
    width: 912,
    height: 912,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1824,
    left: 2224,
    width: 912,
    height: 912,
  },
  getCopyrightLabelByOrigin(2744, 1272, 'content-2', 0),
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'OPLOSSINGEN',
    font: config.fontBold,
    fontSize: config.fontSizeLargeAlt,
    characterSpacing: config.letterSpacingLarge,
    rotation: 270,
    top: 0,
    left: 1272,
    width: 40,
    height: 380,
  },
  // crossword
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1368,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1368,
    width: 384,
    height: 352,
  },
  // kakuro
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1808,
    width: 1512,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    blackCellColor: config.colorLightGrey,
    top: 40,
    left: 1808,
    width: 512,
    height: 352,
  },
  // sudokus
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2376,
    width: 1512,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 40,
    left: 2376,
    width: 352,
    height: 352,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2784,
    width: 1512,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 40,
    left: 2784,
    width: 352,
    height: 352,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Volkskrant - Woensdag layout',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [...lines, ...crossword, ...sudokus, ...solutions],
  },
};

export default layoutTemplate;
