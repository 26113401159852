import { AnyPuzzleElement, IRow } from '../../models/ITemplate';
import { clone } from '../../utils/index';
import { contentBoxHalfHeight } from './components/contentBoxHalfHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { puzzleExplanation } from './components/puzzleExplanation';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';
import { storySolutionsRowOne } from './solutions/storySolutionsRow-1';
import { storySolutionsRowTwo } from './solutions/storySolutionsRow-2';

const layout = clone(storyLayout);

// The layouts margin adds a padding of 5% from the edge of the contentBox. To adjust the content below the box to
// take up the width of the contentBox, i divide 100 columns over 90 columns. This will add the padding to the content
// below (10%). This is the reason this number is 90. Working with 100 columns makes calculation easier, because you
// can work with them as if they were percentages.
layout.columns = 96;

// Heights and widths
const titleHeight = 100;
const titleTopMargin = 70;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};

// Puzzles
const puzzle: AnyPuzzleElement = {
  type: '@content/renderer' as any,
  contentId: 'content-1',
  explanationAbove: puzzleExplanation('content-1'),
  blackCellColor: [0, 11, 6, 0],
  textCellBackgroundColor: [0, 0, 0, 100],
  hideSolutionGrid: true, //only for wordfind elements
  borderSize: 5,
  columns: 2,
  font: storyConfig.fontText,
  fontSizeFactor: 0.6,
  fontSize: 30,
  align: 'right',
  gridBackgroundColor: [0, 0, 0, 0],
};

// Rows
const rowTitleOne: IRow = {
  height: titleHeight,
  elements: [storyHeader('content-1')],
};

const rowPuzzle: IRow = {
  height: config.contentBoxHalfHeight - 350,
  elements: [puzzle],
};

const marginRow: IRow = {
  height: 250,
};

export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground, contentBoxHalfHeight(), ...storyPageNumbers],
    rows: [
      rowTitleOne,
      rowPuzzle,
      marginRow,
      storySolutionsRowOne(-config.paddingContentBox),
      storySolutionsRowTwo(-config.paddingContentBox),
    ],
  },
};
