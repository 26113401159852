import firebase from 'firebase/compat';

import { db as envdb, firebaseConfig } from './firestore-config';

export const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const functions = app.functions('europe-west2');

auth.languageCode = 'nl';

if (envdb === 'EMULATOR') {
  db.useEmulator('localhost', 9012);
  functions.useEmulator('localhost', 9011);
  auth.useEmulator('http://localhost:9010');
  storage.useEmulator('localhost', 9013);
}
