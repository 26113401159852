import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import margrietContent from './content/margriet-2-1-content.json';
import margriet from './layout/margriet-2-1';

const margrietLayouts: LayoutDb[] = [
  {
    id: layoutIds.margriet,
    name: 'Margriet',
    json: margriet,
    content: margrietContent,
  },
];

export default margrietLayouts;
