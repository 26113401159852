import config from './config';

export default function getAnswersBlock(day = 'GISTEREN') {
  return [
    {
      type: 'line',
      color: [0, 0, 0, 100],
      lineWidth: 64,
      lines: [
        {
          coordinates: [
            { x: 0, y: 1952 },
            { x: 3136, y: 1952 },
          ],
        },
      ],
    },
    {
      type: 'custom',
      name: 'headerSmall',
      value: `OPLOSSINGEN VAN ${day.toUpperCase()}`,
      top: 2048,
      left: 0,
      width: 1216,
      height: 64,
    },
    // Swedish
    {
      type: 'custom',
      name: 'headerSolution',
      value: '@content/header',
      contentId: 'solution-1-2',
      top: 2112,
      left: 0,
      width: 1216,
    },
    {
      type: '@content/renderer',
      contentId: 'solution-1-2',
      renderType: 'grid',
      blackCellColor: config.colorBlack,
      solution: true,
      top: 2176,
      left: 0,
      width: 1216,
      height: 416,
    },
    // Sudoku
    {
      type: 'custom',
      name: 'difficultySmall',
      contentId: 'solution-1-3',
      top: 2624,
      left: 0,
      width: 448,
    },
    {
      type: '@content/renderer',
      contentId: 'solution-1-3',
      top: 2688,
      left: 0,
      width: 448,
      height: 448,
      solution: true,
    },
    {
      type: 'custom',
      name: 'difficultySmall',
      contentId: 'solution-1-4',
      top: 2624,
      left: 480,
      width: 448,
    },
    {
      type: '@content/renderer',
      contentId: 'solution-1-4',
      top: 2688,
      left: 480,
      width: 448,
      height: 448,
      solution: true,
    },
    {
      // because of the difficulty boxes, put the header in last
      type: 'custom',
      name: 'headerSolution',
      value: '@content/header',
      contentId: 'solution-1-3',
      top: 2624,
      left: 0,
      width: 0,
    },
  ];
}
