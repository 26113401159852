import firebase from 'firebase/compat';
import Vue from 'vue';
import Vuex from 'vuex';
import { firestoreAction, vuexfireMutations } from 'vuexfire';

import { PuzzlePresetContentItem } from '../../functions/src/models/db/PuzzlePresetContent';
import { IState } from '../models/models';
import { auth, db } from './db';
import puzzlePageStore from './modules/puzzle-page-store';
import puzzlePresetStore from './modules/puzzle-preset-store';
import schedulerStore from './modules/scheduler-store';
import userStore from './modules/user-store';

Vue.use(Vuex);

export const serializeWithId = (snapshot: firebase.firestore.DocumentSnapshot) => {
  return {
    id: snapshot.id,
    ...snapshot.data(),
  };
};

const store = new Vuex.Store<IState>({
  modules: {
    puzzlePageStore,
    schedulerStore,
    puzzlePresetStore,
    userStore,
  },
  state: {
    globalLoading: true,
    currentUserEmail: null,
    currentUser: null,
    puzzlePageDetail: null,
    puzzleZips: [],
    puzzleZipJsons: [],
    puzzlePresetContentDetail: null,
    titles: [],
    isModalActive: false,
    assetUrl: null as string | null,
    titleDetail: null,
    orders: null,
    order: null,
    pageUserActionLogs: [],
  },
  getters: {},
  mutations: {
    async login(state, email) {
      state.currentUserEmail = email;
      await store.dispatch('bindCurrentUser', email);
    },
    async logout(state) {
      state.currentUserEmail = null;
      await store.dispatch('unbindCurrentUser');
    },
    setGlobalLoading(state, enabled) {
      state.globalLoading = enabled;
    },
    setModalState(state, modalState: boolean) {
      state.isModalActive = modalState;
    },
    setAssetUrl(state, assetUrl: string | null) {
      state.assetUrl = assetUrl;
    },
    ...vuexfireMutations,
  },
  actions: {
    bindCurrentUser: firestoreAction(async ({ bindFirestoreRef }, email) => {
      await bindFirestoreRef('currentUser', db.collection('users').doc(email));
      store.commit('setGlobalLoading', false);
    }),
    unbindCurrentUser: firestoreAction(async ({ unbindFirestoreRef }) => {
      await unbindFirestoreRef('currentUser');
      store.commit('setGlobalLoading', false);
    }),

    bindPuzzlePageDetail: firestoreAction(({ bindFirestoreRef }, id) =>
      bindFirestoreRef('puzzlePageDetail', db.collection('pages').doc(id))
    ),
    unbindPuzzlePageDetail: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzlePageDetail')
    ),

    bindTemplates: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef(
        'templates',
        db.collection('templates').where('active', '==', true).orderBy('name')
      )
    ),
    unbindTemplates: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('templates')),
    deletePuzzlePreset: (_context, id: string) => {
      db.collection('puzzlepresets').doc(id).delete();
    },
    bindPuzzleZips: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef(
        'puzzleZips',
        db.collection('puzzleZips').orderBy('createdAt', 'desc').limit(20)
      )
    ),
    unbindPuzzleZips: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('puzzleZips')),
    bindPuzzleZipJsons: firestoreAction(({ bindFirestoreRef }, id) =>
      bindFirestoreRef(
        'puzzleZipJsons',
        db.collection('puzzleZips').doc(id).collection('json').orderBy('path')
      )
    ),
    unbindPuzzleZipJsons: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzleZipJsons')
    ),
    bindPageUserActions: async ({ state }, { schedulerId, date }) => {
      await db
        .collection('userActionLogs')
        .where('schedulerId', '==', schedulerId)
        .where('publicationDate', '==', date)
        .orderBy('date')
        .onSnapshot((result) => {
          const newActionLog: any[] = [];
          if (result.docs.length) {
            result.docs.map((doc) => newActionLog.push(doc.data()));
          }

          state.pageUserActionLogs = newActionLog;
        });
    },
    unbindPageUserActions: firestoreAction(({ state }) => {
      state.pageUserActionLogs = [];
    }),

    bindPuzzlePresetContentDetail: firestoreAction(
      ({ bindFirestoreRef }, { presetId, contentId }) =>
        bindFirestoreRef(
          'puzzlePresetContentDetail',
          db.collection('puzzlepresets').doc(presetId).collection('content').doc(contentId)
        )
    ),
    unbindPuzzlePresetContentDetail: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('puzzlePresetContentDetail');
    }),
    savePuzzlePresetContentDetail: async (
      _context,
      data: { presetId: string; contentId: string; contentData: Partial<PuzzlePresetContentItem> }
    ) =>
      await db
        .collection('puzzlepresets')
        .doc(data.presetId)
        .collection('content')
        .doc(data.contentId)
        .update(data.contentData),
    // Bind with a wait, so it only binds when completely resolved before setting the value in data.
    bindTitles: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef('titles', db.collection('titles'), { wait: true })
    ),
    // Unbind titles without resetting state to null, to keep all components that bind working.
    unbindTitles: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('titles', false)),
    openAssetModal: (_context) => {
      _context.commit('setModalState', true);
    },
    assetSelected: (_context, assetUrl: string) => {
      _context.commit('setModalState', false);
      _context.commit('setAssetUrl', assetUrl);
    },
    resetAsset: (_context) => {
      _context.commit('setModalState', false);
      _context.commit('setAssetUrl', null);
    },

    /** ORDERS */
    bindOrders: firestoreAction(({ bindFirestoreRef, state }) =>
      bindFirestoreRef(
        'orders',
        db
          .collection('orders')
          .where('user', '==', (state as IState).currentUserEmail)
          .orderBy('createdDate', 'desc')
          .limit(200)
      )
    ),
    unbindOrders: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('orders');
    }),
  },
});

auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit('login', user.email);
  } else {
    store.commit('logout');
  }
});

export default store;
