import debugDatabaseComponent from '../components/admin/debug/Database.vue';
import debugActionsComponent from '../components/admin/debug/DebugActions.vue';
import rendererCheckComponent from '../components/admin/debug/RendererCheck.vue';
import titlesComponent from '../components/admin/Titles.vue';
import userDetailComponent from '../components/admin/UserDetail.vue';
import usersComponent from '../components/admin/Users.vue';
import adminPageComponent from '../views/AdminPage.vue';
import { RouteName } from './RouteName';

export default [
  {
    path: '/admin',
    component: adminPageComponent,
    redirect: '/admin/users',
    children: [
      {
        name: RouteName.AdminUsers,
        path: 'users',
        component: usersComponent,
        meta: { title: 'Gebruikers' },
      },
      {
        name: RouteName.AdminUserDetail,
        path: 'users/:id',
        component: userDetailComponent,
        props: true,
        meta: { title: 'Gebruikers' },
      },
      {
        name: RouteName.AdminTitles,
        path: 'titles',
        component: titlesComponent,
        meta: { title: 'Titels' },
      },
      {
        name: RouteName.AdminDebugActions,
        path: 'debug/debugactions',
        component: debugActionsComponent,
        meta: { title: 'Handmatige acties' },
      },
      {
        name: RouteName.AdminDebugDatabase,
        path: 'debug/database',
        component: debugDatabaseComponent,
        meta: { title: 'Database consistentie' },
      },
      {
        name: RouteName.AdminDebugRenderers,
        path: 'debug/renderers',
        component: rendererCheckComponent,
        meta: { title: 'Puzzel renderer check' },
      },
    ],
  },
];
