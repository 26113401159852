import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

export const storyConfig = {
  fontHeader: 'ClarendonURW-ExtBol.ttf',
  fontSubTitle: 'ClarendonLTStd-Bold.otf',
  fontText: 'FrutigerLTStd-Roman.otf',
  fontTextbold: 'FrutigerLTStd-Black.otf',
  fontFooter: 'SyntaxLTStd-UltraBlack.otf',
  fontSwedish: 'GothamNarrow-Bold.otf',
  /** copied this from Margriet */
  fontCopyright: 'CocoGothic.otf',
  // fontSwedish: 'Helvetica.ttf',
  // fontSwedish: 'Helvetica.otf',
  fontSizeLarge: 30 / DEFAULT_SCALE,
  fontSizeRegular: 12 / DEFAULT_SCALE,
  fontSizeSmall: 10 / DEFAULT_SCALE,
  fontSizeXSmall: 8 / DEFAULT_SCALE,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  colorWhite: [0, 0, 0, 0] as CMYKColor,
};

export const storySettings = {
  fonts: {
    header: storyConfig.fontHeader,
    subTitle: storyConfig.fontSubTitle,
    text: storyConfig.fontText,
    textbold: storyConfig.fontTextbold,
    footer: storyConfig.fontFooter,
  },
};
