














import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import Menu from '@/components/Menu.vue';
import NoAccess from '@/components/NoAccess.vue';

import { UserMixin } from '../mixins/User';
import { IMenuItem } from '../models/models';
import { RouteName } from '../router/RouteName';

@Component({
  components: {
    Menu,
    NoAccess,
  },
})
export default class PrintPage extends mixins(UserMixin) {
  menuItems: IMenuItem[] = [
    {
      label: "Puzzelpagina's",
      links: [
        {
          label: 'Overzicht',
          routeObject: { name: RouteName.PrintPuzzlePageOverview },
        },
        {
          link: '/print/agenda',
          label: 'Agenda',
        },
        {
          link: '/print/archive',
          label: 'Archief',
        },
        // Disable for now, it's broken
        // {
        //   link: '/print/prize-result',
        //   label: 'Prijspuzzel uitslag',
        // },
      ],
    },
  ];
}
