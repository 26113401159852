
















import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ISudokuPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import RequireTechniqueEditor from './RequireTechniqueEditor.vue';

@Component({
  components: {
    RequireTechniqueEditor,
  },
})
export default class SudokuPresetEditor extends Vue {
  @PropSync('presetData') preset!: ISudokuPreset;

  mounted() {
    if (!this.preset.generatorParams.requireTechniques) {
      this.$set(this.preset.generatorParams, 'requireTechniques', {});
    }
  }
}
