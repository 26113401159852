import { ITextElement } from '../../../models/ITemplate';
import { config } from '../config';
import { storyConfig } from './story-config';

export const storyHeader = (contentId: string, top?: number): ITextElement => ({
  type: 'text',
  align: 'center',
  value: '@content/header',
  contentId: contentId,
  font: storyConfig.fontHeader,
  fontSize: config.puzzleTitleFontSize,
  top,
});
