import { IGeneratedImagePuzzle, IImagePuzzlePreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const ImagePuzzleName = 'Afbeelding (uitsnede)';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedImagePuzzleIdInput(generated: IGeneratedImagePuzzle): string {
  return JSON.stringify(generated.puzzleFile);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataImagePuzzle(): IImagePuzzlePreset {
  return {
    renderer: 'imagepuzzle',
    name: ImagePuzzleName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {},
    rendererParams: {
      header: ImagePuzzleName.toUpperCase(),
      explanation: '',
    },
  };
}
