import { ITectonicPreset } from '../../db/PuzzlePreset';

// Used in: functions/src/utils/puzzleUtils.ts
export const TectonicName = 'Cijferblok'; // aka Nummerblok, Nrblok, Tectonic or Suguru
// engels: Nanba Blocks, zie discussie hier: https://q42.slack.com/archives/C01EGK6P5HT/p1653907565382539

export function getDefaultPresetDataTectonic(): ITectonicPreset {
  return {
    renderer: 'tectonic',
    name: TectonicName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      width: 7,
      height: 9,
      groupSize: 5,
      difficulty: 1,
    },
    rendererParams: {
      header: TectonicName.toUpperCase(),
      explanation:
        'Elk blok bevat de cijfers 1 t/m het aantal vakjes in dat blok. Vakjes met dezelfde cijfers mogen elkaar niet raken, ook niet diagonaal.',
    },
  };
}
