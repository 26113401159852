import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const crossword: AnyElement[] = [
  ...getHeader(0, 0, 1792, 'Kruiswoord', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 112,
    left: 0,
    width: 768,
    height: 1424,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 112,
    left: 800,
    width: 992,
    height: 1408,
  },
  getCopyrightLabel(1504, 1560, 'Puzzelaar'),
];

const legPuzzel: AnyElement[] = [
  ...getHeader(0, 1872, 1280, 'Leg', 'puzzel'),
  {
    type: 'text',
    contentId: 'content-7',
    value: '@content/explanation',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 112,
    left: 1872,
    width: 1152,
    height: 192,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    cluesColumns: 3,
    onlyText: true,
    top: 352,
    left: 1888,
    width: 1056,
    height: 720,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 1136,
    left: 1888,
    width: 1256,
    height: 1264,
  },
  getCopyrightLabel(2400, 2904, 'Puzzelaar'),
];

const offsetTopSudoku = 100;

const sudoku: AnyElement[] = [
  ...getHeader(1680 - offsetTopSudoku, 0, 1792, 'Sudoku'),
  // puzzle 1
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60 - offsetTopSudoku,
    left: 0,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1856 - offsetTopSudoku,
    left: 0,
    width: 576,
    height: 576,
  },
  // puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60 - offsetTopSudoku,
    left: 608,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1856 - offsetTopSudoku,
    left: 608,
    width: 576,
    height: 576,
  },
  // puzzle 3
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60 - offsetTopSudoku,
    left: 1216,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1856 - offsetTopSudoku,
    left: 1216,
    width: 576,
    height: 576,
  },
  getCopyrightLabel(2436 - offsetTopSudoku, 1588, 'Q42'),
];

const topOffsetBinaire = 600 + offsetTopSudoku - 50;

const binaire: AnyElement[] = [
  ...getHeader(3056 - topOffsetBinaire, 0, 1792, 'Binaire'),
  {
    type: 'text',
    contentId: 'content-5',
    value: '@content/explanation',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 3184 - topOffsetBinaire,
    left: 0,
    width: 1792,
    height: 192,
  },

  // puzzle 1
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'content-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3375 - topOffsetBinaire,
    left: 0,
    width: 832,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 3424 - topOffsetBinaire,
    left: 0,
    width: 832,
    height: 832,
  },
  // puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3375 - topOffsetBinaire,
    left: 960,
    width: 832,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 3424 - topOffsetBinaire,
    left: 960,
    width: 832,
    height: 832,
  },
  getCopyrightLabel(3375 - topOffsetBinaire, 1588, 'Q42'),
];

const solutionSudokuBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 1904,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 1904 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 2704,
    left: 1904,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2336,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2336 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 2704,
    left: 2336,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2768,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656,
    left: 2768 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 2704,
    left: 2768,
    width: 384,
    height: 384,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 1904,
    width: 352,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    top: 3168,
    left: 1904,
    width: 352,
    height: 432,
    solution: true,
  },
];

const solutionCryptogram: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3648 - 48,
    left: 1904,
    width: 1248,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3648,
    left: 1904,
    width: 432,
    height: 432,
  },
];

const solutionSwedish: AnyElement[] = [
  {
    type: 'text',
    value: 'Zweedse puzzel - ',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 2288,
    width: 432,
    height: 32,
  },
  {
    type: 'text',
    value: '@content/solutionWord',
    contentId: 'solution-2-1',
    color: config.colorBlack,
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 2288 + 278,
    width: 432,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-2-1',
    blackCellColor: config.colorBlack,
    renderType: 'grid',
    textCellBackgroundColor: config.colorGrey,
    solution: true,
    top: 3168,
    left: 2288,
    width: 864,
    height: 1088,
  },
];

const solutionsBlock: AnyElement[] = [
  ...getHeader(2544, 1904, 1248, 'Oplossingen ', '@SS'),
  ...solutionSudokuBlock,
  ...solutionCrossword,
  ...solutionCryptogram,
  ...solutionSwedish,
];

const solutionQuento: AnyElement[] = [
  {
    type: 'text',
    value: 'Quento',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 16,
    width: 400,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    solution: true,
    top: 3824,
    left: 16,
    width: 400,
    height: 432,
  },
];

const solutionPacman: AnyElement[] = [
  {
    type: 'text',
    value: 'Woord Pacman',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 370,
    width: 272,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 3824,
    left: 370,
    width: 272,
    height: 224,
  },
];

const solutionsTectonic: AnyElement[] = [
  {
    type: 'text',
    value: 'Cijferblok',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 700,
    width: 336,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-8',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3776 + 32,
    left: 700,
    width: 336,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-8',
    solution: true,
    top: 3824 + 32,
    left: 700,
    width: 336,
    height: 432 - 32,
  },
  {
    type: 'text',
    value: 'Cijferblok',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 1080,
    width: 336,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-8',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3776 + 32,
    left: 1080,
    width: 336,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-9',
    solution: true,
    top: 3824 + 32,
    left: 1080,
    width: 336,
    height: 432 - 32,
  },
];

const solutionsKoprol: AnyElement[] = [
  {
    type: 'text',
    value: 'Koprol 1 & 2',
    fontSize: config.fontSizeSmall,
    top: 3776,
    left: 1472,
    width: 352,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-10',
    solution: true,
    fontSize: config.fontSizeSmall,
    lineGap: -1,
    top: 3820,
    left: 1472,
    width: 352,
    height: 210,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-11',
    solution: true,
    fontSize: config.fontSizeSmall,
    lineGap: -1,
    top: 4059,
    left: 1472,
    width: 352,
    height: 210,
  },
];

const solutionsBlockExtra: AnyElement[] = [
  ...getHeader(3664, 0, 1824, 'Oplossingen ', '@SS'),
  ...solutionQuento,
  ...solutionPacman,
  ...solutionsTectonic,
  ...solutionsKoprol,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Tuesday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {},
    elements: [
      ...crossword,
      ...legPuzzel,
      ...sudoku,
      ...binaire,
      ...solutionsBlock,
      ...solutionsBlockExtra,
    ],
  },
};

export default layoutTemplate;
