import { formatInTimeZone } from 'date-fns-tz';

import {
  actionLogDateFormat,
  ActionLogDb,
  IPageActionLog,
} from '../../functions/src/models/db/UserActionLogs';
import { db } from '../store/db';

export const addUserActionLog = async (log: IPageActionLog) => {
  const logDb: ActionLogDb = {
    ...log,
    date: formatInTimeZone(new Date(), 'Europe/Amsterdam', actionLogDateFormat),
  };

  await db.collection('userActionLogs').add(logDb);
};
