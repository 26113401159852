import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import story1CrosswordPuzzle from './story-1-crossword-puzzle';
import story1PuzzleContent from './story-1-puzzle-content';
import story1SwedishPuzzle from './story-1-swedish-puzzle';
import story1WordfindPuzzle from './story-1-wordfind-puzzle';
import storyFront from './story-front';
import storyFrontContent from './story-front-content';
import storySolutions1 from './story-solutions-1';
import storySolutions1Content from './story-solutions-1-content';
import storySolutions2 from './story-solutions-2';
import storySolutions2Content from './story-solutions-2-content';
import story2Puzzle from './story-2-puzzles';
import story2PuzzleContent from './story-2-puzzles-content';
import story2PuzzleSwedishBelow from './story-2-puzzles-swedish-below';
import story3Puzzle from './story-3-puzzles';
import story3PuzzleContent from './story-3-puzzles-content';

const storyLayouts: LayoutDb[] = [
  {
    id: layoutIds.storyVoorkant,
    name: 'Story - Voorkant',
    json: storyFront,
    content: storyFrontContent,
  },
  {
    id: layoutIds.story1Puzzle,
    name: 'Story - 1 puzzel',
    json: story1CrosswordPuzzle,
    content: story1PuzzleContent,
  },
  // 16 has been deleted
  {
    id: layoutIds.story1WoordzoekerPuzzel,
    name: 'Story - 1 Woordzoeker puzzel',
    json: story1WordfindPuzzle,
    content: story1PuzzleContent,
  },
  {
    id: layoutIds.story1Zweedse,
    name: 'Story - 1 Zweedse puzzel',
    json: story1SwedishPuzzle,
    content: story1PuzzleContent,
  },
  {
    id: layoutIds.story2Puzzels,
    name: 'Story - 2 puzzels',
    json: story2Puzzle,
    content: story2PuzzleContent,
  },
  {
    id: layoutIds.story2PuzzelsZweedseOnder,
    name: 'Story - 2 puzzels - Zweeds onder',
    json: story2PuzzleSwedishBelow,
    content: story2PuzzleContent,
  },
  {
    id: layoutIds.story3Puzzels,
    name: 'Story - 3 puzzels',
    json: story3Puzzle,
    content: story3PuzzleContent,
  },
  {
    id: layoutIds.storyOplossingen1,
    name: 'Story - Oplossingen 1',
    json: storySolutions1,
    content: storySolutions1Content,
  },
  {
    id: layoutIds.storyOplossingen2,
    name: 'Story - Oplossingen 2',
    json: storySolutions2,
    content: storySolutions2Content,
  },
];
export default storyLayouts;
