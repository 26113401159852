import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

const config = {
  scale: DEFAULT_SCALE,
  width: 3136,
  height: 3552,
  gridSize: 32,
  fontRegular: 'balto-book.otf',
  fontBold: 'balto-bold.otf',
  colorRed: [0, 100, 85, 0] as CMYKColor,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  fontSizeLarge: 9.7 / DEFAULT_SCALE,
  fontSizeRegular: 8 / DEFAULT_SCALE,
  lineGap: 2,
};

export const configSaturday = {
  ...config,
  height: 4288,
};

export default config;
