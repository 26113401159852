import { ITemplate } from '../../models/ITemplate';
import { ADR, DEFAULT_SCALE } from '../config';

const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: 3142,
      height: 4370,
      margin: {
        top: 75,
        bottom: 0,
        right: 87,
        left: 87,
      },
      columns: 4,
      cellPadding: {
        horizontal: 70,
        vertical: 60,
      },
    },
    settings: {
      fonts: {
        header: 'HaasGroteskTextBold-Regular.otf',
        text: 'HaasGroteskDisplayRm-Rg.otf',
        textbold: 'HaasGroteskDisplayBd-Rg.otf',
        cell: 'HaasGroteskDisplayMd-Rg.otf',
      },
    },
    templates: {
      header: {
        elements: [
          {
            type: 'box',
            height: 88,
            backgroundColor: [0, 5, 3, 64],
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 20,
            top: 44,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 68,
            top: 4,
            padding: 2,
            right: 10,
            max: 4,
            template: 'dark',
          },
        ],
      },
      'prize-header': {
        elements: [
          {
            type: 'box',
            height: 88,
            backgroundColor: ADR.HighlightHeaderBackgroundColor,
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 20,
            top: 44,
            baseline: 'middle',
          },
        ],
      },
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 25,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
          {
            type: 'stars',
            height: 25,
            top: -6,
            padding: 0,
            right: 18,
            max: 4,
            template: 'dark',
          },
        ],
      },
      wordfind: {
        elements: [
          {
            type: 'wordfind',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSizeFactor: 0.6,
            fontSize: 38,
            lineGap: 10,
          },
        ],
      },
      crossword: {
        elements: [
          {
            type: 'crossword',
            font: '@page/settings/fonts/text',
            lineGap: 3,
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 38,
          },
        ],
      },
      quento: {
        elements: [
          {
            log: true,
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'quento',
            valign: 'bottom',
          },
        ],
      },
      koprol: {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            valign: 'top',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'koprol',
            font: '@page/settings/fonts/text',
            valign: 'bottom',
          },
        ],
      },
      '0hn0': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/explanation',
            fontSize: 38,
            lineGap: 3,
            color: [0, 0, 0, 100],
            width: 689,
            left: 759,
            valign: 'top',
          },
          {
            type: '0hn0',
            valign: 'bottom',
          },
        ],
      },
    },

    rows: [
      // Row 1
      {
        height: 815,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-1-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-1-2',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-1-3',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-1-4',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-1-4',
              },
            ],
          },
        ],
      },

      // Row 2
      {
        height: 1100,
        elements: [
          // Grey Lines
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 2968,
                    y: -30,
                  },
                ],
              },
            ],
          },
          // Red Lines
          {
            type: 'line',
            color: ADR.HighlightHeaderBackgroundColor,
            lineWidth: 6,
            lines: [
              {
                coordinates: [
                  { x: 0, y: 3 },
                  { x: 0, y: 1132 },
                ],
              },
              {
                coordinates: [
                  { x: 0, y: 1132 },
                  { x: 2242, y: 1132 },
                ],
              },
              {
                coordinates: [
                  { x: 2242, y: 1132 },
                  { x: 2242, y: 2007 },
                ],
              },
              {
                coordinates: [
                  { x: 2242, y: 2007 },
                  { x: 2968, y: 2007 },
                ],
              },
              {
                coordinates: [
                  { x: 2968, y: 2007 },
                  { x: 2968, y: 3 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 3,
            elements: [
              {
                type: 'custom',
                name: 'prize-header',
                value: 'PRIJSPUZZEL',
                width: 2968,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-2-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'image',
                path: '@content/image',
                contentId: 'prize-image',
                width: 720,
                top: 120,
                left: -60,
                // height: 920,
              },
            ],
          },
        ],
      },

      // Row 3
      {
        height: 815,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                stars: '@content/difficulty',
                contentId: 'content-3-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-3-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-3-2',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-3-3',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'image',
                path: '@content/image',
                contentId: 'play-image',
                top: -30,
                width: 660,
              },
            ],
          },
        ],
      },

      {
        height: 815,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 2242,
                    y: -30,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-1',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-4-1',
              },
            ],
          },
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-2',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                valign: 'bottom',
                top: 118,
                '+height': -118,
                contentId: 'content-4-2',
              },
            ],
          },
          {
            colSpan: 2,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-3',
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 118,
                '+height': -118,
                contentId: 'content-4-3',
              },
            ],
          },
        ],
      },

      {
        height: 30,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  {
                    x: 0,
                    y: -30,
                  },
                  {
                    x: 2279,
                    y: -30,
                  },
                ],
              },
              {
                coordinates: [
                  {
                    x: 2279,
                    y: -30,
                  },
                  {
                    x: 2279,
                    y: -470,
                  },
                ],
              },
              {
                coordinates: [
                  {
                    x: 2279,
                    y: -470,
                  },
                  {
                    x: 2968,
                    y: -470,
                  },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: 'OPLOSSINGEN',
                top: -30,
              },
            ],
          },
        ],
      },

      {
        height: 312,
        columns: [
          {
            colSpan: 4,
            elements: [
              {
                type: 'text',
                left: 2768,
                fontSize: 38,
                width: 200,
                align: 'right',
                top: -550,
                contentId: 'brand-date',
                value: '@content/text',
                font: '@page/settings/fonts/text',
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-1',
                top: -510,
                left: 2324,
                width: 644,
              },
              {
                type: '@content/renderer',
                top: -490,
                left: 2324,
                width: 644,
                height: 282,
                lineGap: 3,
                contentId: 'content-4-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 312,
                left: 2324,
                top: -48,
                stars: '@content/difficulty',
                contentId: 'content-1-1',
              },
              {
                type: '@content/renderer',
                width: 312,
                left: 2324,
                top: -380,
                contentId: 'content-1-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                width: 312,
                left: 2656,
                top: -48,
                stars: '@content/difficulty',
                contentId: 'content-1-2',
              },
              {
                type: '@content/renderer',
                width: 312,
                left: 2656,
                top: -380,
                contentId: 'content-1-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                top: 332,
                width: 312,
                left: 2324,
                stars: '@content/difficulty',
                contentId: 'content-1-3',
              },
              {
                type: '@content/renderer',
                width: 312,
                left: 2324,
                contentId: 'content-1-3',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                top: 332,
                width: 312,
                left: 2656,
                stars: '@content/difficulty',
                contentId: 'content-1-4',
              },
              {
                type: '@content/renderer',
                width: 312,
                left: 2656,
                contentId: 'content-1-4',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-3',
                top: 332,
                width: 312,
                left: 0,
              },
              {
                type: '@content/renderer',
                left: 0,
                width: 312,
                contentId: 'content-3-3',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-2',
                top: 332,
                width: 312,
                left: 332,
              },
              {
                type: '@content/renderer',
                left: 332,
                width: 312,
                contentId: 'content-4-2',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-4-3',
                top: 332,
                width: 312,
                left: 664,
              },
              {
                type: '@content/renderer',
                left: 664,
                width: 312,
                contentId: 'content-4-3',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-2',
                top: 332,
                width: 252,
                left: 1038,
              },
              {
                type: '@content/renderer',
                left: 996,
                width: 252,
                height: 312,
                contentId: 'content-3-2',
                solution: true,
              },

              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'content-3-1',
                width: 312,
                left: 1268,
                top: 332,
                stars: '@content/difficulty',
              },
              {
                type: '@content/renderer',
                left: 1268,
                width: 312,
                height: 312,
                valign: 'bottom',
                contentId: 'content-3-1',
                solution: true,
              },
              {
                type: 'image',
                path: '@content/image',
                contentId: 'brand-image',
                height: 312,
                width: 704,
                left: 1600,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default layout;
