






























import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import { PuzzlePresetDb } from '../../../functions/src/models/db/PuzzlePreset';
import { IPuzzleId } from '../../../functions/src/models/puzzles/IPuzzles';
import { getDefaultPresetData } from '../../../functions/src/models/puzzles/PresetHelper';
import { assertNever } from '../../../functions/src/utils';
import { getDisplayNameByRenderer } from '../../../functions/src/utils/puzzleUtils';
import { UserMixin } from '../../mixins/User';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
  },
})
export default class ShopPuzzlePresetList extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresets') puzzlePresets!: PuzzlePresetDb[];
  @Prop() renderer: IPuzzleId | undefined;

  selected = null;

  get presets() {
    return this.puzzlePresets.filter(
      (pp) =>
        pp.renderer === this.renderer &&
        (!pp.titles || pp.titles.length == 0) &&
        pp.stock.available > 0 &&
        (pp.source !== 'upload' || this.allowDownloadExternalPuzzles)
    );
  }

  get subtitle() {
    if (this.renderer) {
      return getDefaultPresetData(this.renderer).rendererParams.explanation;
    }
    return null;
  }

  getSource(source: 'seed' | 'upload' | 'custom') {
    switch (source) {
      case 'seed':
      case 'custom':
        return 'Gegenereerd';
      case 'upload':
        return 'Extern ingekocht';
      default:
        assertNever(source);
    }
  }

  getParameters(params: Record<string, string | number>) {
    const mapping: Record<string, string> = {
      size: 'formaat',
      difficulty: 'moeilijkheid',
      width: 'breedte',
      height: 'hoogte',
      minWordLength: 'minimum woord lengte',
    };
    return Object.keys(params)
      .filter((key) => key in mapping)
      .map((key) => `${mapping[key]}: ${params[key ?? '']}`)
      .join(', ');
  }

  getDisplayNameByRenderer(renderer: IPuzzleId) {
    return getDisplayNameByRenderer(renderer);
  }

  @Watch('selected')
  async onSelectRow(val: PuzzlePresetDb) {
    await this.$router.push(`/shop/${val.renderer}/${val.id}`);
  }
}
