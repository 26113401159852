import { AnyPuzzleElement, IRow, IStarsElement } from '../../models/ITemplate';
import { clone } from '../../utils/index';
import { artifacts } from './components/artifacts';
import { contentBoxHalfHeight } from './components/contentBoxHalfHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { puzzleExplanation } from './components/puzzleExplanation';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';

const layout = clone(storyLayout);

// Heights and widths
const titleHeight = 70;
const titleTopMargin = 50;
const titleTwoTopMargin = 230;
const marginBetweenBoxes = 116;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};
// Puzzles
const firstAndSecondPuzzle: AnyPuzzleElement = {
  type: '@content/renderer' as any,
  contentId: 'content-1-1',
  secondElement: {
    id: 'content-1-2',
    align: 'right',
  },
  explanationAbove: puzzleExplanation('content-1'),
  blackCellColor: [0, 11, 6, 0],
  textCellBackgroundColor: [0, 0, 0, 100],
  columns: 2,
};

const thirdPuzzle: AnyPuzzleElement = {
  type: '@content/renderer' as any,
  contentId: 'content-2',
  explanationAbove: puzzleExplanation('content-2'),
  blackCellColor: [0, 0, 0, 100],
  hideSolutionGrid: true, //only for wordfind elements
  borderSize: 5,
  columns: 2,
  font: storyConfig.fontText,
  fontSize: 32,
  align: 'right',
};

const stars: IStarsElement = {
  top: 5,
  height: 90,
  padding: 20,
  max: 4,
  type: 'stars',
  stars: '@content/difficulty',
  contentId: 'content-1-1',
  activeStar: 'star-story-selected.png',
  inactiveStar: 'star-story-unselected.png',
  align: 'right',
};

// Rows
const rowTitleOne: IRow = {
  height: titleHeight,
  elements: [stars, storyHeader('content-1-1')],
};

const rowPuzzleOneAndTwo: IRow = {
  height: config.contentBoxHalfHeight - 300,
  elements: [firstAndSecondPuzzle],
};

const rowTitleTwo: IRow = {
  height: titleHeight + titleTwoTopMargin,
  elements: [storyHeader('content-2', titleTwoTopMargin)],
};

const rowPuzzleThree = {
  height: config.contentBoxHalfHeight - 320,
  elements: [thirdPuzzle],
};

export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [
      storyBackground,
      contentBoxHalfHeight(),
      contentBoxHalfHeight(config.contentBoxHalfHeight + marginBetweenBoxes),
      ...artifacts,
      ...storyPageNumbers,
    ],
    rows: [rowTitleOne, rowPuzzleOneAndTwo, rowTitleTwo, rowPuzzleThree] as any,
  },
};
