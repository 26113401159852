






















































import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { PuzzlePresetDb } from '../../../../../functions/src/models/db/PuzzlePreset';
import { PuzzlePresetContentItem } from '../../../../../functions/src/models/db/PuzzlePresetContent';
import { rejectionReasons } from '../../../../models/rejectionReasons';
import Loading from '../../../Loading.vue';
import ScreenShotButton from './ScreenShotButton.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: { Loading, ScreenShotButton },
})
export default class PuzzlesRejectedList extends Vue {
  @Prop() puzzlePresetDetail!: PuzzlePresetDb & { id: string };
  @Prop() presetId!: string;
  @PuzzlePresetStore.State('rejectedPuzzlePresetContent')
  puzzlePresetContent!: (PuzzlePresetContentItem & {
    id: string;
  })[];

  loading = true;
  loadingScreenshot = false;

  currentPage = 1;

  get isKoprol() {
    return this.puzzlePresetDetail.renderer === 'koprol';
  }

  get isUploaded() {
    return this.puzzlePresetDetail.source === 'upload';
  }

  getRejectionReason(reason: string) {
    const keys = Object.keys(rejectionReasons);

    if (keys.includes(reason)) {
      return rejectionReasons[reason];
    } else return reason;
  }

  getFormattedDate(date: string) {
    return format(new Date(date), 'd MMMM Y', { locale: nl });
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindRejectedPuzzlePresetContent', {
      presetId: this.$route.params.id,
      orderByDirection: this.$route.params.renderer === 'koprol' ? 'desc' : 'asc',
    });

    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindRejectedPuzzlePresetContent');
  }
}
