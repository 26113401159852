import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

const config = {
  scale: DEFAULT_SCALE,
  width: 3152,
  height: 4256,
  gridSize: 16,
  fontLight: 'MuseoSans-100.otf',
  fontRegular: 'MuseoSans-300.otf',
  fontBold: 'MuseoSans-500.otf',
  colorWhite: [0, 0, 0, 0] as CMYKColor,
  colorRed: [0, 100, 97, 0] as CMYKColor,
  colorYellow: [0, 7, 10, 0] as CMYKColor,
  colorGrey: [0, 0, 0, 15] as CMYKColor,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  colorOrange: [0, 6, 12, 1] as CMYKColor,
  fontSizeLarge: 18 / DEFAULT_SCALE,
  fontSizeRegular: 9 / DEFAULT_SCALE,
  fontSizeSmall: 8 / DEFAULT_SCALE,
};

export const configSaturday = {
  ...config,
  width: 1552,
};

export default config;
