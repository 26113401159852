











































import compareDesc from 'date-fns/compareDesc';
import endOfDay from 'date-fns/endOfDay';
import { compareAsc } from 'date-fns/esm';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import { PageDb } from 'functions/src/models/db/Page';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Loading from '@/components/Loading.vue';

import { UserMixin } from '../../mixins/User';
import Agenda from './Agenda.vue';

const PuzzlePageStore = namespace('puzzlePageStore');

@Component({
  components: {
    Agenda,
    Loading,
  },
})
export default class PuzzlePagesArchive extends mixins(UserMixin) {
  @PuzzlePageStore.State('puzzlePages') puzzlePages!: PageDb[];
  startDate = startOfDay(subDays(new Date(), 1));
  maxDate = endOfDay(subDays(new Date(), 1));
  loading = false;

  get lastShownDate() {
    return new Date(this.startDate).setDate(this.startDate.getDate() - 14);
  }

  mounted() {
    this.loadPuzzlePages(this.startDate);
  }

  async loadPuzzlePages(date: Date) {
    this.loading = true;
    await this.$store.dispatch('puzzlePageStore/bindPuzzlePageArchive', {
      startdate: date,
      userTitles: this.currentUserTitles,
    });
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePageStore/undbindPuzzlePages');
  }

  @Watch('startDate')
  onStartDateChange(newStartDate: Date) {
    const hasPageBeforeShownDates = this.puzzlePages.some(
      (puzzlePage) => compareDesc(new Date(puzzlePage.date), this.lastShownDate) === 1
    );
    const hasPagefterShownDates = this.puzzlePages.some(
      (puzzlePage) => compareAsc(new Date(puzzlePage.date), this.startDate) === 1
    );
    if (hasPageBeforeShownDates && hasPagefterShownDates) return;
    this.loadPuzzlePages(newStartDate);
  }
}
