














import { Component, PropSync } from 'vue-property-decorator';

import { ICrosswordPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class CrosswordPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: ICrosswordPreset;
}
