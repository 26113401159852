import { IGeneratedSudokuMix, ISudokuMixPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const SudokuMixName = 'Sudoku Mix';
export const SudokuTwinsName = 'Sudoku Twins';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedSudokuMixIdInput(generated: IGeneratedSudokuMix): string {
  return JSON.stringify(generated.puzzles.map((p) => p.values));
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataSudokuMix(): ISudokuMixPreset {
  return {
    renderer: 'sudokumix',
    name: SudokuMixName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      amountOfPuzzles: 2,
      overlaps: [{ puzzle1: 1, puzzle2: 2, block1: 9, block2: 1 }],
      puzzles: [
        {
          difficulty: 1,
          type: 'jigsaw',
        },
        {
          difficulty: 1,
          type: 'diagonal',
        },
      ],
    },
    rendererParams: {
      header: SudokuMixName.toUpperCase(),
      explanation:
        'Deze multisudoku bestaat uit twee puzzels die elkaar overlappen: een vorm- en een diagonaalsudoku. ' +
        'Plaats een cijfer van 1-9 in een leeg vakje zodat op elke rij, in iedere kolom en in elk dikomlijnd vlak ' +
        'die getallen één keer voorkomen. Ook op beide stippellijnen mag elke cijfer maar één keer staan.',
    },
  };
}
