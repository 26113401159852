import { AnyPuzzleElement, IRow } from '../../models/ITemplate';
import { ISwedishCrosswordElement } from '../../models/puzzles/swedishcrossword/interfaces';
import { clone } from '../../utils';
import { artifacts } from './components/artifacts';
import { contentBoxHalfHeight } from './components/contentBoxHalfHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { puzzleExplanation } from './components/puzzleExplanation';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';

// Clone the layout so you can override settings
const layout = clone(storyLayout);

// Heights and widths
const titleHeight = 70;
const titleTopMargin = 50;
const titleTwoTopMargin = 230;
const marginBetweenBoxes = 116;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};

// First puzzle
const puzzleOne: AnyPuzzleElement = {
  type: '@content/renderer' as any,
  contentId: 'content-1',
  blackCellColor: [0, 0, 0, 100],
  explanationAbove: puzzleExplanation('content-1'),
  hideSolutionGrid: true, //only for wordfind elements
  borderSize: 5,
  columns: 2,
  font: storyConfig.fontText,
  fontSize: 32,
  align: 'right',
};

// Second puzzle
const puzzleTwo: ISwedishCrosswordElement = {
  type: '@content/renderer' as any,
  contentId: 'content-2',
  explanationAbove: puzzleExplanation('content-2'),
  // @ts-ignore
  highlightType: 'none',
  renderType: 'grid',
  blackCellColor: [0, 11, 6, 0],
  font: storyConfig.fontSwedish,
  fontSize: 30,
  fontSizeFactor: 0.167871014, // makes 5.5
};

// Rows
const rowTitleOne: IRow = {
  height: titleHeight,
  elements: [storyHeader('content-1')],
};

const firstPuzzleRow: IRow = {
  height: config.contentBoxHalfHeight - 300,
  elements: [puzzleOne],
};

const secondTitleRow: IRow = {
  height: titleHeight + titleTwoTopMargin,
  elements: [storyHeader('content-2', titleTwoTopMargin)],
};

const secondPuzzleRow: IRow = {
  height: config.contentBoxHalfHeight - 320,
  elements: [puzzleTwo],
};

// Layout
export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [
      storyBackground,
      contentBoxHalfHeight(),
      contentBoxHalfHeight(config.contentBoxHalfHeight + marginBetweenBoxes),
      ...artifacts,
      ...storyPageNumbers,
    ],
    rows: [rowTitleOne, firstPuzzleRow, secondTitleRow, secondPuzzleRow],
  },
};
