





















import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ILetterSudokuPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { WordPools } from '../../../../../functions/src/models/puzzles/IPuzzleGeneratorParams';
import RequireTechniqueEditor from './RequireTechniqueEditor.vue';

@Component({
  components: {
    RequireTechniqueEditor,
  },
})
export default class LetterSudokuPresetEditor extends Vue {
  @PropSync('presetData') preset!: ILetterSudokuPreset;

  wordPools = WordPools;

  mounted() {
    if (!this.preset.generatorParams.requireTechniques) {
      this.$set(this.preset.generatorParams, 'requireTechniques', {});
    }
    if (!this.preset.generatorParams.wordPool) {
      this.$set(this.preset.generatorParams, 'wordPool', 'nl');
    }
  }
}
