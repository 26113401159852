import { AnyElement, IElementContainer } from '../../../models/ITemplate';
import config from './config';

export function getHeader(
  top: number,
  left: number,
  width: number,
  height: number,
  contentId: string | null,
  format = 'regular',
  copy: string | null = null
): AnyElement[] {
  return [
    {
      type: 'box',
      top,
      left,
      width,
      height,
      backgroundColor: config.colorBlack,
    },
    {
      type: 'text',
      value: copy ? copy : '@content/header',
      contentId: contentId ?? undefined,
      dateFormat: 'EEEE d MMMM yyyy',
      color: config.colorWhite,
      font: config.fontHeader,
      fontSize: config.fontSizeLarge,
      baseline: 'middle',
      top: top + 40,
      left: left + (format === 'dense' ? 16 : 32),
      height: 0,
      width: width - (format === 'dense' ? 16 : 32),
    },
  ];
}

export function getHardcodedHeader(
  top: number,
  left: number,
  width: number,
  value: string
): AnyElement[] {
  return [
    {
      type: 'box',
      top,
      left,
      width,
      height: 80,
      backgroundColor: config.colorBlack,
    },
    {
      type: 'text',
      dateFormat: 'EEEE d MMMM yyyy',
      value,
      color: config.colorWhite,
      font: config.fontHeader,
      fontSize: config.fontSizeLarge,
      baseline: 'middle',
      top: top + 40,
      left: left + 32,
      height: 0,
      width: width - 32,
    },
  ];
}

export function getText(
  top: number,
  left: number,
  width: number,
  height: number,
  value: string
): AnyElement[] {
  return [
    {
      type: 'text',
      value,
      color: config.colorBlack,
      fontSize: config.fontSizeRegular,
      top,
      left,
      height,
      width,
    },
  ];
}

export function getSubTitle(
  top: number,
  left: number,
  width: number,
  height: number,
  value: string
): AnyElement[] {
  return [
    {
      type: 'text',
      value,
      color: config.colorBlack,
      font: config.fontHeader,
      fontSize: config.fontSizeSmall,
      top,
      left,
      height,
      width,
    },
  ];
}

export function makeLine(top: number, left: number, length: number): AnyElement {
  return {
    type: 'line',
    color: config.colorBlack,
    lineWidth: 4,
    lines: [
      {
        coordinates: [
          {
            x: left,
            y: top,
          },
          {
            x: left + length,
            y: top,
          },
        ],
      },
    ],
  };
}

export function getBox(top: number, left: number, width: number, height: number): AnyElement[] {
  return [
    {
      type: 'box',
      top,
      left,
      width,
      height,
      border: false,
      backgroundColor: config.colorBlueBackground,
    },
  ];
}

export function getDifficulty(
  top: number,
  left: number,
  width: number,
  height: number,
  contentId: string
): AnyElement[] {
  return [
    {
      type: 'box',
      top,
      left,
      width,
      height,
      backgroundColor: config.colorBlack,
    },
    {
      type: 'difficulty',
      contentId,
      difficulty: '@content/difficulty',
      publisher: 'hln',
      color: config.colorWhite,
      font: config.fontHeader,
      fontSize: config.fontSizeMedium,
      baseline: 'middle',
      left: left + 16,
      top: top + 30,
      width: width - 16,
      height,
    },
  ];
}

export function getStaticDifficulty(
  top: number,
  left: number,
  width: number,
  height: number,
  value: string
): AnyElement[] {
  return [
    {
      type: 'box',
      top,
      left,
      width,
      height,
      backgroundColor: config.colorBlack,
    },
    {
      type: 'text',
      value,
      color: config.colorWhite,
      font: config.fontHeader,
      fontSize: config.fontSizeMedium,
      baseline: 'middle',
      left: left + 16,
      top: top + 30,
      width,
      height,
    },
  ];
}

export const staticSmallText: IElementContainer = {
  elements: [
    {
      type: 'text',
      color: config.colorBlack,
      font: config.fontBold,
      fontSize: config.fontSizeSmall,
      baseline: 'top',
      lineGap: -1,
    },
  ],
};

export const smallText: IElementContainer = {
  elements: [
    {
      type: 'text',
      color: config.colorBlack,
      font: config.fontBold,
      fontSize: config.fontSizeSmall,
      baseline: 'top',
      lineGap: -1,
      value: '@content/header',
    },
  ],
};

export const smallDifficulty: IElementContainer = {
  elements: [
    {
      type: 'difficulty',
      difficulty: '@content/difficulty',
      publisher: 'hln',
      // value: '@content/header',
      color: config.colorBlack,
      font: config.fontBold,
      fontSize: config.fontSizeSmall,
      baseline: 'top',
    },
  ],
};

export const copyright: IElementContainer = {
  elements: [
    {
      type: 'text',
      value: '© De Puzzelaar',
      font: config.fontRegular,
      fontSize: config.fontSizeSmall,
      width: 32,
      height: 256,
      rotation: 270,
    },
  ],
};

export const copyrightQ42: IElementContainer = {
  elements: [
    {
      type: 'text',
      value: '© 42Puzzles',
      font: config.fontRegular,
      fontSize: config.fontSizeSmall,
      width: 32,
      height: 256,
      rotation: 270,
    },
  ],
};

export function getCopyrightLabel(top: number, left: number, value: 'Q42' | 'Puzzelaar'): any {
  switch (value) {
    case 'Q42':
      return { ...copyrightQ42.elements[0], top, left };
    case 'Puzzelaar':
      return { ...copyright.elements[0], top, left };
  }
}
