import { clone } from '../../utils/index';
import { storyPageNumbers } from './components/pageNumbers';
import { storyBackground } from './components/story-background';
import { storySettings } from './components/story-config';
import { storyCopyright } from './components/story-copyright';
import { storyLayout } from './components/story-layout';
import { config } from './config';
import { storySolutionsRowThree } from './solutions/storySolutionsRow-3';
import { storySolutionsRowFour } from './solutions/storySolutionsRow-4';
import { storySolutionsRowFive } from './solutions/storySolutionsRow-5';
import { storySolutionsRowSix } from './solutions/storySolutionsRow-6';

const layout = clone(storyLayout);

layout.columns = 100;

const totalHeightContent = config.solutionRowHeight * 4;
// Margins
layout.margin = {
  top: (layout.height - totalHeightContent) / 2 - config.topOfSolutionElement,
  right: config.leftContentBox,
  bottom: 0,
  left: config.leftContentBox,
};

export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground, ...storyPageNumbers, storyCopyright],
    rows: [
      storySolutionsRowThree(),
      storySolutionsRowFour(),
      storySolutionsRowFive(),
      storySolutionsRowSix(),
    ],
  },
};
