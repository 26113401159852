



















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MyPuzzlesPageComponent extends Vue {
  goToPuzzleMaker(route: string) {
    this.$router.push(route);
  }
}
