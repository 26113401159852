import { ITemplate } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import { FONTS } from '../../constants';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { mezzaFonts, mezzaTemplates } from '../components/generic';

const scale = DEFAULT_SCALE;
const layout: ITemplate = {
  page: {
    layout: {
      scale,
      width: 2480,
      height: 3508,

      // ___json_compatible_comment: 'these values are here for easy reference :)',
      // ___unused___InnerWidth: 2304,
      // ___unused___InnerHeight: 3200,

      // ___unused___nrOfColumnParts: 36,
      // ___unused___nrOfRowParts: 49,

      margin: {
        top: 192,
        right: 0,
        bottom: 0,
        left: 88,
      },
      columns: 36,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      fonts: mezzaFonts,
    },
    templates: mezzaTemplates,
    elements: [
      getCopyrightLabelByOrigin(2816, 1144, 'content-1-1'),
      {
        type: '@content/renderer',
        contentId: 'prize-banner',
        top: 48 + 25,
        left: 88,
        width: 2304,
        height: 1328,
      },
      {
        type: 'line',
        color: [0, 0, 0, 100],
        lineWidth: 0.8 / scale,
        lines: [
          {
            coordinates: [
              { x: 88, y: 1440 },
              { x: 2392, y: 1440 },
            ],
          },
        ],
      },
      {
        type: 'custom',
        name: 'header',
        value: '@content/header',
        contentId: 'content-1-1',
        top: 1416,
        left: 86,
      },
      {
        type: '@content/renderer',
        contentId: 'prize-text',
        top: 1480,
        left: 88,
        width: 2304,
        height: 360,
      },
    ],
    rows: [
      {
        height: 1536,
        elements: [
          // {
          //   type: 'image',
          //   path: '@content/image',
          //   contentId: 'puzzleAndWin',
          //   width: 2304,
          //   height: 1664,
          //   left: 0,
          // },
        ],
      },
      {
        height: 1408,
        elements: [
          {
            // __comment: 'grid',
            type: 'wordfind',
            name: '@content/renderer',
            top: 64,
            contentId: 'content-1-1',
            width: 1216,
            height: 1216,
            left: 1088,
            onlyGrid: true,
            borderColor: [18, 94, 86, 0],
            cellBorderWidth: 0.2,
            fontSize: 34, // supposed to be 9pt
          },
          {
            // __comment: 'hints',
            type: 'custom',
            name: '@content/renderer',
            top: 64,
            left: 0,
            contentId: 'content-1-1',
            width: 1024,
            height: 1408,
            wordsHeight: 1408,
            columns: 3,
            fontSize: 34, // supposed to be 9.6pt
            // line height supposed to be 10.8pt
            onlyText: true,
            hideSolutionGrid: true,
          },
          {
            type: 'wordfind',
            name: '@content/renderer',
            top: 1312 + 30,
            left: 1088,
            contentId: 'content-1-1',
            width: 1216,
            height: 80,
            borderColor: [18, 94, 86, 0],
            onlySolutionGrid: true,
          },
          {
            type: 'custom',
            name: 'page-identifier',
            top: 1339 + 30,
            left: 2113,
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
        ],
      },
    ],
  },
};

export default layout;
