

















































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Loading from '@/components/Loading.vue';

import { PuzzlePresetDb } from '../../../../functions/src/models/db/PuzzlePreset';
import { UserMixin } from '../../../mixins/User';
import { RouteName } from '../../../router/RouteName';
import { AmountOfWeeksLeft, getAmountOfWeeksLeft } from '../presets/preset-utils';
import AllPuzzles from './AllPuzzles.vue';
import PuzzlesWithAction from './PuzzlesWithAction.vue';
import UnusedPuzzles from './UnusedPuzzles.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

export type PuzzleItem = PuzzlePresetDb & { amountOfWeeksLeft: AmountOfWeeksLeft };

@Component({
  components: {
    Loading,
    PuzzlesWithAction,
    AllPuzzles,
    UnusedPuzzles,
  },
})
export default class PuzzlesOverview extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresets') allPuzzlePresets!: PuzzlePresetDb[];

  puzzlesView = 'action';
  loading = false;

  get routeName() {
    return RouteName;
  }

  get puzzlePresets() {
    if (this.currentUserTitles === 'ALL') return this.allPuzzlePresets;

    const puzzlePresetsWithoutTitle = this.allPuzzlePresets.filter(
      (puzzlePreset) => !puzzlePreset.titles
    );
    const puzzlePresetsUser = this.allPuzzlePresets.filter((puzzlePreset) => {
      if (!puzzlePreset.titles) return false;

      return puzzlePreset.titles.some((title) => this.currentUserTitles.includes(title));
    });

    return [...puzzlePresetsWithoutTitle, ...puzzlePresetsUser];
  }

  get tableTitle() {
    return this.puzzlesView === 'overview'
      ? 'Overzicht'
      : this.puzzlesView === 'action'
      ? 'Acties'
      : 'Niet gebruikt';
  }

  get puzzles(): PuzzleItem[] {
    return this.puzzlePresets.map((puzzlePreset) => {
      return {
        ...puzzlePreset,
        id: puzzlePreset.id,
        amountOfWeeksLeft: getAmountOfWeeksLeft(puzzlePreset),
      };
    }) as PuzzleItem[];
  }

  get puzzlePresetsWithAction() {
    return this.puzzles
      .filter(
        (preset) =>
          preset.amountOfWeeksLeft !== 'Niet gebruikt' &&
          preset.amountOfWeeksLeft < 2 &&
          (preset.needsApproval || preset.source === 'upload')
      )
      .sort((a, b) => (a.amountOfWeeksLeft as number) - (b.amountOfWeeksLeft as number));
  }

  get puzzlePresetsNotUsed() {
    return this.puzzles.filter((preset) => preset.amountOfWeeksLeft === 'Niet gebruikt');
  }

  setPuzzlesView(view: 'action' | 'overview' | 'notUsed') {
    this.puzzlesView = view;
  }

  async mounted() {
    this.loading = true;
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresets');
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresets');
  }
}
