export enum RouteName {
  Home = 'HOME',

  // Print
  PrintAgenda = 'PRINTSTRAAT_AGENDA',
  PrintAgendaItem = 'PRINTSTRAAT_AGENDA_ITEM',
  PrintArchive = 'PRINTSTRAAT_ARCHIVE',
  PrintArchiveItem = 'PRINTSTRAAT_ARCHIVE_ITEM',
  PrintPuzzlePageOverview = 'PRINTSTRAAT_PUZZLE_PAGE_OVERVIEW',
  PrintPuzzlePage = 'PRINTSTRAAT_PUZZLE_PAGE',
  PrintPuzzlePageNew = 'PRINTSTRAAT_PUZZLE_PAGE_NEW',

  // Media
  AssetOverview = 'MEDIA_ASSET_OVERVIEW',
  AssetDetail = 'MEDIA_ASSET_DETAIL',

  // Admin
  AdminUsers = 'ADMIN_USERS',
  AdminUserDetail = 'ADMIN_USER_DETAIL',
  AdminTitles = 'ADMIN_TITLES',
  AdminDebugActions = 'ADMIN_DEBUG_ACTIONS',
  AdminDebugDatabase = 'ADMIN_DEBUG_DATABASE',
  AdminDebugRenderers = 'ADMIN_DEBUG_RENDERERS',

  // Shop
  ShopHome = 'SHOP_HOME',
  ShopCart = 'SHOP_CART',
  ShopOrderOverview = 'SHOP_ORDER_OVERVIEW',
  ShopOrderDetail = 'SHOP_ORDER_DETAIL',
  ShopPuzzleTypeOverview = 'SHOP_PUZZLE_TYPE_OVERVIEW',
  ShopPuzzlePage = 'SHOP_PUZZLE_PAGE',

  // Puzzles
  AllPuzzles = 'PUZZLES_ALL',
  PresetOverview = 'PUZZLES_PRESET_OVERVIEW',
  EditPreset = 'PUZZLES_PRESET_EDIT',
  NewPreset = 'PUZZLES_PRESET_NEW',
  UploadSingle = 'PUZZLES_UPLOAD_SINGLE',
  PuzzlesUploadZip = 'PUZZLES_UPLOAD_ZIP',
  PuzzleContentDetail = 'PUZZLE_CONTENT_DETAIL',
  PuzzleCreateWordfind = 'PUZZLE_CREATE_WORDFIND',
}
