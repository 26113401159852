import { ITextElement } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import { storyConfig } from './story-config';
import { storyLayout } from './story-layout';

export const storyCopyright: ITextElement = {
  type: 'text',
  value: 'Foto: Getty Images\nPuzzels: Puzzeltijd & Q42',
  top: storyLayout.height - 180,
  left: (3 / 4) * storyLayout.width - 30,
  color: storyConfig.colorWhite,
  font: storyConfig.fontCopyright,
  fontSize: 7 / DEFAULT_SCALE,
};
