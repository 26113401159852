import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const crossWord: AnyElement[] = [
  ...getHeader(0, 0, 1808, 'Kruiswoord', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 128,
    left: 0,
    width: 752,
    height: 1408,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 128,
    left: 800,
    width: 1008,
    height: 1408,
  },
  getCopyrightLabel(1540, 1576, 'Puzzelaar'),
];

const numberCrossword: AnyElement[] = [
  ...getHeader(0, 1888, 1264, 'Cijfer', 'kruiswoord'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-6',
    color: config.colorBlack,
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    top: 128,
    left: 1888,
    width: 1232,
    height: 144,
  },

  {
    type: '@content/renderer',
    contentId: 'content-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    renderType: 'grid',
    top: 288,
    left: 1888,
    width: 1264,
    height: 1440,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'letters',
    top: 1760,
    left: 1888,
    width: 1248,
    height: 64,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'letterGrid',
    top: 1808,
    left: 1904,
    width: 1216,
    height: 208,
  },
  getCopyrightLabel(2020, 2900, 'Puzzelaar'),
];

const sudokus: AnyElement[] = [
  ...getHeader(1712, 0, 1808, 'Sudoku'),
  // Makkelijk
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1840,
    left: 0,
    width: 208,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1904,
    left: 0,
    width: 592,
    height: 592,
  },
  // Gemiddeld
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1840,
    left: 608,
    width: 208,
    height: 48,
  },
  {
    type: '@content/renderer',
    top: 1904,
    left: 608,
    width: 592,
    height: 592,
    contentId: 'content-3',
  },
  // Moeilijk
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1840,
    left: 1216,
    width: 208,
    height: 48,
  },
  {
    type: '@content/renderer',
    top: 1904,
    left: 1216,
    width: 592,
    height: 592,
    contentId: 'content-4',
  },
  getCopyrightLabel(2500, 1614, 'Q42'),
];

const playBanner: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'play-banner',
    top: 2160,
    left: 1888,
    width: 1264,
    height: 336,
  },
];

const honingraat: AnyElement[] = [
  ...getHeader(2672, 0, 1808, 'Honingraat'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-5',
    color: config.colorBlack,
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    top: 2832 - 48,
    left: 0,
    width: 1808,
    height: 96,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 2944,
    left: 0,
    width: 1808,
    height: 1312,
  },
  getCopyrightLabel(4220, 1608, 'Puzzelaar'),
];

const solutionsSudoku: AnyElement[] = [
  // Puzzle 1
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 1888,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 1888 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 2832,
    left: 1888,
    width: 384,
    height: 384,
  },
  // Puzzle 2
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 2320,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 2320 + 124,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 2832,
    left: 2322,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 2752,
    width: 115,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2832 - 48,
    left: 2752 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 2832,
    left: 2752,
    width: 384,
    height: 384,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3264,
    left: 2752,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    top: 3312,
    left: 2752,
    width: 384,
    height: 684,
  },
];

const solutionBinaire1: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3264,
    left: 1888,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3264,
    left: 1888 + 115,
    width: 384 - 115,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 3312,
    left: 1888,
    width: 384,
    height: 448,
  },
];

const solutionBinaire2: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3264,
    left: 2322,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3264,
    left: 2322 + 115,
    width: 384 - 115,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    renderType: 'grid',
    blackCellColor: config.colorBlack,
    top: 3312,
    left: 2322,
    width: 384,
    height: 512,
  },
];

const solutionLegpuzzel: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-7',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3804,
    left: 1888,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    solution: true,
    top: 3852,
    left: 1888,
    width: 384,
    height: 384,
  },
];

const solutions: AnyElement[] = [
  ...getHeader(2672, 1888, 1264, 'Oplossingen ', '@SS'),
  ...solutionsSudoku,
  ...solutionCrossword,
  ...solutionBinaire1,
  ...solutionBinaire2,
  ...solutionLegpuzzel,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Thursday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
      ],
      ...settings,
    },
    templates: {},
    elements: [
      ...crossWord,
      ...numberCrossword,
      ...playBanner,
      ...sudokus,
      ...honingraat,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
