import { IColumn, IRow, ISolutionElement } from '../../../models/ITemplate';
import { solutionTitle } from '../components/solutionTitles';
import { config } from '../config';

export const storySolutionsRowOne = (marginLeft = 0): IRow => {
  // Solution 1-1
  const firstSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-1',
    solution: true,
    contentPageIndex: 1,
    blackCellColor: [0, 0, 0, 100],
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const colFirstSolution: IColumn = {
    colSpan: 33,
    elements: [solutionTitle('Pagina @PO+1', marginLeft), firstSolution],
  };

  // Solution 1-2
  const secondSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-1-1',
    solution: true,
    contentPageIndex: 2,
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const colSecondSolution: IColumn = {
    colSpan: 22,
    elements: [solutionTitle('Pagina @PO+2', marginLeft), secondSolution],
  };

  // Solution 1-3
  const thirdSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-1-2',
    solution: true,
    contentPageIndex: 2,
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const colThirdSolution: IColumn = {
    colSpan: 22,
    elements: [solutionTitle('Pagina @PO+2', marginLeft), thirdSolution],
  };

  // Solution 1-4
  const fourthSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-2',
    contentPageIndex: 2,
    solution: true,
    markWordsVisually: true,
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const fourthSolutionWord: any = [
    {
      left: marginLeft,
      top: config.topOfSolutionElement + 460,
      type: 'text' as any,
      value: 'Oplossingswoord:',
      fontSize: 30,
    },
    {
      left: marginLeft + 255,
      top: config.topOfSolutionElement + 460,
      type: 'text' as any,
      contentPageIndex: 2,
      contentId: 'content-2',
      value: '@content/solution',
      fontSize: 30,
    },
  ];

  const colFourthSolution: IColumn = {
    colSpan: 22,
    elements: [solutionTitle('Pagina @PO+2', marginLeft), fourthSolution, ...fourthSolutionWord],
  };

  return {
    height: config.solutionRowHeight,
    columns: [colFirstSolution, colSecondSolution, colThirdSolution, colFourthSolution],
  };
};
