





















































































import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import PreviewPuzzle from '@/components/PreviewPuzzle.vue';

import { PuzzlePresetDb } from '../../../functions/src/models/db/PuzzlePreset';
import { PuzzlePresetContentItem } from '../../../functions/src/models/db/PuzzlePresetContent';
import { IPuzzleId } from '../../../functions/src/models/puzzles/IPuzzles';
import { getDisplayNameByRenderer } from '../../../functions/src/utils/puzzleUtils';
import { UserMixin } from '../../mixins/User';
import { logEvent } from '../../services/analytics';
import { auth } from '../../store/db';
import { functionsUrl } from '../../store/firestore-config';
import NoAccess from '../NoAccess.vue';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    PreviewPuzzle,
    NoAccess,
  },
})
export default class ShopPuzzlePresetDetail extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresetDetail') puzzlePresetDetail!: PuzzlePresetDb;
  @PuzzlePresetStore.State('puzzlePresetContent') puzzlePresetContent!: (PuzzlePresetContentItem & {
    id: string;
  })[];
  @Prop() renderer: IPuzzleId | undefined;
  @Prop() presetId: string | undefined;

  token: string | null = null;
  downloaded: string[] = [];
  index = 0;
  loading = true;

  get userHasAccess() {
    if (this.loading || !this.puzzlePresetDetail) return true;

    return (
      !this.puzzlePresetDetail.titles ||
      this.currentUserTitles === 'ALL' ||
      this.puzzlePresetDetail.titles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get puzzlePresetShopContent() {
    // explicit sorting because koprol is not ordered by number
    return this.puzzlePresetContent.sort((a, b) => (a.number < b.number ? -1 : 1));
  }

  get isDownloadable() {
    return this.preset.source === 'upload'
      ? this.allowDownloadExternalPuzzles
      : this.allowDownloadLicensedPuzzles;
  }

  get order() {
    return this.state.order;
  }
  get preset() {
    return this.puzzlePresetDetail;
  }
  get allContentItems() {
    return this.puzzlePresetShopContent.filter((c) => this.downloaded.indexOf(c.id) === -1);
  }
  get contentItemsLength() {
    return this.allContentItems.length;
  }
  get contentItems() {
    return this.allContentItems.filter((c, i) => i === this.index || i === this.index + 1);
  }
  get downloadUrl() {
    return `${functionsUrl}downloadOrder`;
  }

  getDisplayNameByRenderer(renderer: IPuzzleId) {
    return getDisplayNameByRenderer(renderer);
  }

  onDownload(ciId: string) {
    if (this.preset) {
      logEvent({
        event: 'download_from_shop',
        eventData: { preset_name: this.preset.name, puzzle_id: ciId },
      });
    }
    setTimeout(() => this.downloaded.push(ciId), 500);
  }

  async addToCurrentOrder(ci: PuzzlePresetContentItem & { id: string }) {
    if (this.order) {
      await this.$store.dispatch('addToCurrentOrder', {
        orderId: this.order.id,
        presetId: this.presetId,
        puzzleId: ci.id,
      });
    }
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetDetail', this.presetId);
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetShopContent', {
      presetId: this.presetId,
      renderer: this.renderer,
    });

    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetDetail');
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresetContent');
  }
}
