import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 0, y: 1656 },
          { x: 1752, y: 1656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 0, y: 2656 },
          { x: 3008, y: 2656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1752, y: 0 },
          { x: 1752, y: 2656 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 2192, y: 2656 },
          { x: 2192, y: 3992 },
        ],
      },
    ],
  },
];

const wordFind: AnyElement[] = [
  // header
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-1',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 0,
    left: 0,
    width: 576,
    height: 72,
  },
  // cluewords
  {
    type: '@content/renderer',
    contentId: 'content-1',
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    onlyText: true,
    hideSolutionGrid: true,
    columns: 5,
    lineGap: 25,
    top: 110,
    left: 0,
    width: 1688,
    height: 544,
  },
  // puzzle
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlyGrid: true,
    top: 624,
    left: 0,
    width: 1688,
    height: 888,
  },
  {
    type: 'text',
    value: 'Oplossing: ',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 1560,
    left: 0,
    width: 200,
    height: 88,
  },
  // solutiongrid
  {
    type: '@content/renderer',
    contentId: 'content-1',
    onlySolutionGrid: true,
    top: 1544,
    left: 200,
    width: 1480,
    height: 88,
  },
  getCopyrightLabelByOrigin(1256, 1692, 'content-1'),
];

const swedish: AnyElement[] = [
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-2',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 1672,
    left: 0,
    width: 816,
    height: 72,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'grid',
    top: 1752,
    left: 0,
    width: 1688,
    height: 856,
  },
  getCopyrightLabelByOrigin(2344, 1692, 'content-2'),
];

const filippine: AnyElement[] = [
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-3',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 0,
    width: 816,
    height: 72,
  },
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-3',
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 2752,
    left: 0,
    width: 2128,
    height: 96,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'clues',
    top: 2872,
    left: 0,
    width: 936,
    height: 1120,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'filippine',
    top: 2872,
    left: 968,
    width: 1163,
    height: 820,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'codeBar' as any,
    top: 3720,
    left: 968,
    width: 1160,
    height: 272,
  },
  getCopyrightLabelByOrigin(3730, 942, 'content-3'),
];

const crossword: AnyElement[] = [
  {
    type: '@content/renderer',
    contentId: 'content-4',
    blackCellColor: [0, 0, 0, 100],
    onlyGrid: true,
    top: 0,
    left: 1800,
    width: 1184,
    height: 1336,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    fontSize: config.fontSizeSmall,
    onlyText: true,
    continuous: false,
    top: 1350,
    left: 1794,
    width: 616,
    height: 1282,
  },
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-4',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1450,
    left: 2408,
    width: 568,
    height: 336,
  },
  // Solution
  {
    type: 'text',
    value: 'oplossingen @SS',
    dateFormat: 'd LLLL',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1856,
    left: 2408,
    width: 568,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    blackCellColor: [0, 0, 0, 100],
    top: 1912,
    left: 2416,
    width: 512,
    height: 568,
  },
  {
    type: 'text',
    value: 'Een boekenbon gaat naar:',
    font: config.fontBold,
    fontSize: config.fontSizeSmall,
    top: 2496,
    left: 2408,
    width: 568,
    height: 112,
  },
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'oplossingen vorige week',
    font: config.fontBold,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 2240,
    width: 750,
    height: 64,
  },
  // Wordfind
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-1',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 2760,
    left: 2240,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    onlyGrid: true,
    markWordsVisually: true,
    top: 2792,
    left: 2240,
    width: 712,
    height: 376,
  },
  {
    type: 'text',
    value: '@content/solution',
    contentId: 'solution-1-1',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 3176,
    left: 2240,
    width: 712,
    height: 32,
  },
  // Swedish
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-2',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3232,
    left: 2240,
    width: 712,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3272,
    left: 2240,
    width: 472,
    height: 232,
  },
  // Filippine
  {
    type: 'text',
    value: '@content/header',
    contentId: 'solution-1-3',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3528,
    left: 2240,
    width: 736,
    height: 40,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    hideSolutionText: true,
    top: 3568,
    left: 2240,
    width: 720,
    height: 250,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    onlySolutionText: true,
    top: 3824,
    left: 2240,
    width: 720,
    height: 160,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Trouw - Zaterdag pagina 1',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [...lines, ...wordFind, ...swedish, ...filippine, ...crossword, ...solutions],
  },
};

export default layoutTemplate;
