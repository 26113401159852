import { ICopyrightElement } from '../../models/ITemplate';

type Locale = 'NL' | 'BE';
type ContentId = `content-${number}-${number}` | `content-${number}`;

/**
 *
 * @param rotation defaults to 270
 * @param locale defaults to "NL"
 * @returns
 */
export const getCopyrightLabelByOrigin = (
  top: number,
  left: number,
  contentId: ContentId,
  rotation: 0 | 270 = 270,
  locale: Locale = 'NL'
) => {
  const copyright: ICopyrightElement = {
    locale,
    type: 'copyright',
    contentId,
    value: '@content/origin',
    fontSize: 20,
    width: rotation === 0 ? 256 : 32,
    height: rotation === 0 ? 32 : 256,
    top,
    left,
    rotation,
  };

  return copyright;
};
