import config from './config';

const block = [
  {
    type: 'custom',
    name: 'headerSmall',
    value: '@content/header',
    contentId: 'content-1',
    top: 0,
    left: 2560,
    width: 576,
    height: 64,
  } as any,
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    lineGap: config.lineGap,
    top: 64,
    left: 2560,
    width: 576,
    height: 416,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'original',
    top: 0,
    left: 0,
    width: 1248,
    height: 672,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    renderType: 'differences',
    top: 0,
    left: 1280,
    width: 1248,
    height: 672,
  },
];

export default block;
