import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader, smallText } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const frenchCrossword: AnyElement[] = [
  ...(getHeader(0, 0, 1552, 'Kruiswoord', 'puzzel', true) as any),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    fontBold: config.fontBold,
    onlyText: true,
    top: 112,
    left: 0,
    width: 624,
    height: 1104,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    blackCellColor: config.colorBlack,
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    onlyGrid: true,
    top: 112,
    left: 656,
    width: 896,
    height: 992,
  },
  getCopyrightLabel(1104, 1306, 'Puzzelaar'),
];

const sudokuBlock: AnyElement[] = [
  ...getHeader(1248, 0, 1552, 'Sudoku'),
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1424 - 64,
    left: 0,
    width: 592,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1424,
    left: 0,
    width: 496,
    height: 496,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1424 - 64,
    left: 528,
    width: 496,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1424,
    left: 528,
    width: 496,
    height: 496,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1424 - 64,
    left: 1056,
    width: 496,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1424,
    left: 1056,
    width: 496,
    height: 496,
  },
  getCopyrightLabel(1920, 1356, 'Q42'),
];

const cryptogram: AnyElement[] = [
  ...(getHeader(2064, 0, 1552, 'Cryptogram') as any),
  {
    type: '@content/renderer',
    contentId: 'content-5',
    onlyText: true,
    continuous: false,
    alignNumbers: 'right',
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    fontBold: config.fontBold,
    top: 2176,
    left: 0,
    width: 752,
    height: 1040,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    blackCellColor: config.colorBlack,
    fontSize: config.fontSizeRegular,
    font: config.fontRegular,
    onlyGrid: true,
    align: 'right',
    top: 2176,
    left: 768,
    width: 784,
    height: 944,
  },
  getCopyrightLabel(3120, 1306, 'Puzzelaar'),
];

const pacman: AnyElement[] = [
  ...getHeader(0, 1600, 1552, 'PAC-MAN™', ' Puzzel'),
  {
    type: '@content/renderer',
    contentId: 'content-6',
    hideSolutionGrid: true,
    top: 112,
    left: 1600,
    width: 800,
    height: 752,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'description',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 112,
    left: 2432,
    width: 720,
    height: 752,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    renderType: 'clues',
    font: config.fontRegular,
    fontBold: config.fontBold,
    fontSize: config.fontSizeRegular,
    valign: 'bottom',
    top: 612,
    left: 2432,
    width: 736,
    height: 240,
  },
  {
    type: 'custom',
    name: 'smallText',
    value: 'PAC-MAN™&©BANDAI NAMCO Entertainment Inc.',
    top: 880,
    left: 1600,
    width: 750,
    height: 32,
  },
  getCopyrightLabel(42, 2940, 'Q42'),
];

const quento: AnyElement[] = [
  ...getHeader(944, 1600, 1552, 'Quento'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-7',
    font: config.fontRegular,
    fontBold: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1056,
    left: 1600,
    width: 1552,
    height: 160,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    blackCellColor: config.colorBlack,
    top: 1232,
    left: 1600,
    width: 1000,
    height: 368,
  },
  getCopyrightLabel(985, 2940, 'Q42'),
];

const tectonic: AnyElement[] = [
  ...getHeader(1760, 1600, 1552, 'Cijferblok'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-8',
    font: config.fontRegular,
    fontBold: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 1872,
    left: 1600,
    width: 1552,
    height: 128,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-8',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1974,
    left: 1600,
    width: 752,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-8',
    top: 2032,
    left: 1600,
    width: 650,
    height: (650 / 8) * 11,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-9',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1974,
    left: 2400,
    width: 752,
    height: 48,
  },
  {
    type: '@content/renderer',
    contentId: 'content-9',
    top: 2032,
    left: 2400,
    width: 650,
    height: (650 / 8) * 11,
  },
  getCopyrightLabel(1801, 2940, 'Q42'),
];

const koprol: AnyElement[] = [
  ...getHeader(3088, 1600, 1552, 'Koprol'),
  getCopyrightLabel(3088 + 41, 2940, 'Q42'),
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-10',
    font: config.fontRegular,
    fontBold: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 3200,
    left: 2400,
    width: 650,
    height: 400,
  },
  {
    type: '@content/renderer',
    contentId: 'content-10',
    top: 3200,
    left: 1600,
    width: 720,
    height: 520,
  },
  {
    type: '@content/renderer',
    contentId: 'content-11',
    top: 3200 + 520 + 16,
    left: 1600,
    width: 720,
    height: 520,
  },
];

// Solutions below

const solutionSudokuBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 0,
    width: 320,
    height: 32,
  } as any,
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 0 + 124,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3424,
    left: 0,
    width: 320,
    height: 320,
  },
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 384,
    width: 320,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 384 + 124,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 3424,
    left: 384,
    width: 320,
    height: 320,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 768,
    width: 320,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 768 + 124,
    width: 320,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 3424,
    left: 768,
    width: 320,
    height: 320,
  },
];

const solutionCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3808 - 48,
    left: 0,
    width: 384,
    height: 32,
  } as any,
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    solution: true,
    top: 3808,
    left: 0,
    width: 384,
    height: 448,
  },
];

const solutionTentsTreesBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3920 - 48,
    left: 368,
    width: 336,
    height: 336,
  } as any,
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3920,
    left: 368,
    width: 336,
    height: 336,
  },
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3920 - 48,
    left: 752,
    width: 336,
    height: 336,
  } as any,
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    solution: true,
    top: 3920,
    left: 752,
    width: 336,
    height: 336,
  },
];

const solutionSwedish: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-7',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3424 - 48,
    left: 1152,
    width: 400,
    height: 693,
  } as any,

  {
    type: '@content/renderer',
    contentId: 'solution-1-7',
    textCellBackgroundColor: config.colorGrey,
    blackCellColor: config.colorBlack,
    renderType: 'grid',
    solution: true,
    top: 3424,
    left: 1152,
    width: 400,
    height: 693,
  },
  {
    type: 'text',
    contentId: 'solution-1-7',
    value: '@content/solutionWord',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 4117,
    left: 1152,
    width: 336,
    height: 62,
  },
];

const solutions: AnyElement[] = [
  ...(getHeader(3248, 0, 1552, 'Oplossingen ', '@SS') as any),
  ...solutionSudokuBlock,
  ...solutionCrossword,
  ...solutionTentsTreesBlock,
  ...solutionSwedish,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Saturday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
        'content-8',
        'content-9',
        'content-10',
        'content-11',
      ],
      ...settings,
    },
    templates: {
      smallText: smallText as any,
    },
    elements: [
      ...frenchCrossword,
      ...sudokuBlock,
      ...cryptogram,
      ...pacman,
      ...quento,
      ...tectonic,
      ...koprol,
      ...solutions,
    ],
  },
};

export default layoutTemplate;
