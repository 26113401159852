




















import { Component, Prop } from 'vue-property-decorator';

import { RouteName } from '../router/RouteName';
import { TypedVue } from '../store/typed-vue';

interface IBreadCrumb {
  name?: string;
  routeObject?: { name: RouteName; params?: object; query?: object };
  link?: string;
}

@Component
export default class BreadCrumbs extends TypedVue {
  @Prop() breadCrumbs!: IBreadCrumb[];

  getBreadCrumbLink(breadCrumb: IBreadCrumb) {
    return breadCrumb.routeObject
      ? breadCrumb.routeObject
      : { path: breadCrumb.link ? breadCrumb.link : '' };
  }
}
