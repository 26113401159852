





























































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { RouteName } from '../../../router/RouteName';
import { PuzzleItem } from './PuzzlesOverview.vue';

@Component
export default class PuzzlesWithAction extends Vue {
  @Prop() puzzlesWithAction!: PuzzleItem[];
  get routeName() {
    return RouteName;
  }

  get puzzlePresetWithActionUpload() {
    return this.puzzlesWithAction.filter((puzzle) => !puzzle.needsApproval);
  }

  get puzzlePresetWithActionApprove() {
    return this.puzzlesWithAction.filter((puzzle) => puzzle.needsApproval);
  }
}
