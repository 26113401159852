import { IColumn, IRow, ISolutionElement } from '../../../models/ITemplate';
import { solutionTitle } from '../components/solutionTitles';
import { config } from '../config';

export const storySolutionsRowTwo = (marginLeft = 0): IRow => {
  // Solution 2-1
  const firstSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    solution: true,
    contentId: 'content-1',
    contentPageIndex: 3,
    blackCellColor: [0, 0, 0, 100],
    gridBackgroundColor: [0, 0, 0, 0],
    renderType: 'grid',
  };

  const colFirstSolution: IColumn = {
    colSpan: 22,
    elements: [solutionTitle('Pagina @PO+3', marginLeft), firstSolution],
  };

  // Solution 2-2
  const secondSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-1',
    contentPageIndex: 4,
    solution: true,
    blackCellColor: [0, 0, 0, 100],
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const colSecondSolution: IColumn = {
    colSpan: 33,
    elements: [solutionTitle('Pagina @PO+4', marginLeft), secondSolution],
  };

  // Solution 2-3
  const thirdSolution: ISolutionElement = {
    left: marginLeft,
    top: config.topOfSolutionElement,
    type: '@content/renderer' as any,
    contentId: 'content-1',
    contentPageIndex: 5,
    solution: true,
    blackCellColor: [0, 0, 0, 100],
    gridBackgroundColor: [0, 0, 0, 0],
  };

  const colThirdSolution: IColumn = {
    colSpan: 33,
    elements: [solutionTitle('Pagina @PO+5', marginLeft), thirdSolution],
  };

  return {
    height: config.solutionRowHeight,
    columns: [colFirstSolution, colSecondSolution, colThirdSolution],
  };
};
