import { IWordfindPreset } from '../../db/PuzzlePreset';
import { IGeneratedWordfind } from '../IGeneratedPuzzle';

// Used in: functions/src/utils/puzzleUtils.ts
export const WordfindName = 'Woordzoeker';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedWordfindIdInput(generated: IGeneratedWordfind): string {
  return JSON.stringify(generated.values);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataWordfind(): IWordfindPreset {
  return {
    renderer: 'wordfind',
    name: WordfindName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: true,
    generatorParams: {
      wordPool: 'ad',
      width: 9,
      height: 9,
      maxWordCount: 100,
      minWordLength: 3,
      maxSolutionLength: 10,
    },
    rendererParams: {
      header: WordfindName.toUpperCase(),
      explanation: 'Streep de woorden weg. Welk woord vormen de overgebleven letters?',
    },
  };
}
