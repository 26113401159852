import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const puzzleOne: AnyElement[] = [
  // Heading
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-1',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 1272,
    width: 700,
    height: 48,
  },
  // Puzzle
  {
    type: '@content/renderer',
    contentId: 'content-1',
    top: 2768,
    left: 1272,
    width: 816,
    height: 816,
  },
  // Explanation
  {
    type: 'text',
    value: '@content/explanation',
    contentId: 'content-1',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3640,
    left: 1272,
    width: 816,
    height: 344,
  },
  // Copyright
  getCopyrightLabelByOrigin(3328, 2096, 'content-1'),
];

const puzzleTwo: AnyElement[] = [
  // Header
  {
    type: 'text',
    value: '@content/header',
    contentId: 'content-2',
    font: config.fontHeader,
    fontSize: config.fontSizeLarge,
    top: 2688,
    left: 2152,
    width: 288,
    height: 48,
  },
  // Difficulty
  {
    type: 'stars',
    contentId: 'content-2',
    stars: '@content/difficulty',
    activeStar: 'circle.png',
    align: 'right',
    padding: 8,
    top: 2704,
    left: 2704,
    width: 264,
    height: 30,
  },
  // Puzzle
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 2768,
    left: 2152,
    width: 816,
    height: 816,
    debug: true,
  },
  // Copyright
  getCopyrightLabelByOrigin(3328, 2976, 'content-1'),
];

const solutions: AnyElement[] = [
  // Title
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: 'Oplossingen @SS',
    dateFormat: 'dd LLLL',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3580,
    left: 2144,
    width: 48,
    height: 400,
    rotation: 270,
  },
  // Solution letter sudoku
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    top: 3648,
    left: 2200,
    width: 344,
    height: 344,
  },
  // Solution sudoku
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 3648,
    left: 2624,
    width: 344,
    height: 344,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Trouw - Dinsdag layout',
    layout: layout,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2'],
      ...settings,
    },
    templates: {},
    elements: [...puzzleOne, ...puzzleTwo, ...solutions],
  },
};

export default layoutTemplate;
