











import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IHashiPreset } from '../../../../../functions/src/models/puzzles/hashi/interfaces';

@Component
export default class HashiPresetEditor extends Vue {
  @PropSync('presetData') preset!: IHashiPreset;
}
