import { SchedulerDb } from 'functions/src/models/db/Scheduler';
import { UserTitles } from 'functions/src/models/db/Users';
import { ISchedulerState, IState } from 'src/models/models';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';

import { db } from '../db';

const schedulerStore: Module<ISchedulerState, IState> = {
  namespaced: true,
  state: {
    schedulerDetail: null,
    schedulers: [],
  },
  getters: {},
  mutations: {},
  actions: {
    saveScheduler: async (_context, data: SchedulerDb & { id: string }) =>
      await db.collection('schedulers').doc(data.id).set(data),
    saveNewScheduler: async (_context, data: SchedulerDb) => {
      const newDoc = db.collection('schedulers').doc();
      if (data.solutionSchedulerId === '__current__') {
        data.solutionSchedulerId = newDoc.id;
      }
      return await newDoc.set(data);
    },
    bindSchedulerDetail: firestoreAction(({ bindFirestoreRef }, id) =>
      bindFirestoreRef('schedulerDetail', db.collection('schedulers').doc(id))
    ),
    unbindSchedulerDetail: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('schedulerDetail')
    ),
    bindSchedulers: firestoreAction(({ bindFirestoreRef }, userTitles: UserTitles) => {
      if (userTitles.length <= 0) return;

      const baseQuery = db.collection('schedulers').orderBy('name');
      const query =
        userTitles === 'ALL'
          ? baseQuery
          : baseQuery.where('titles', 'array-contains-any', userTitles);
      return bindFirestoreRef('schedulers', query);
    }),
    unbindSchedulers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('schedulers')),
    deleteScheduler: (_context, id: string) => db.collection('schedulers').doc(id).delete(),
  },
};

export default schedulerStore;
