















import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Loading from '@/components/Loading.vue';
import Menu from '@/components/Menu.vue';
import NoAccess from '@/components/NoAccess.vue';

import { PuzzlePresetDb } from '../../functions/src/models/db/PuzzlePreset';
import { PuzzleIds } from '../../functions/src/models/puzzles/IPuzzles';
import { getDisplayNameByRenderer } from '../../functions/src/utils/puzzleUtils';
import { UserMixin } from '../mixins/User';
import { IMenuItem } from '../models/models';

const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    Menu,
    NoAccess,
    Loading,
  },
})
export default class PuzzlesPage extends mixins(UserMixin) {
  @PuzzlePresetStore.State('puzzlePresets') allPuzzlePresets!: PuzzlePresetDb[];

  get puzzlePresets() {
    if (this.currentUserTitles === 'ALL') return this.allPuzzlePresets;

    const puzzlePresetsWithoutTitle = this.allPuzzlePresets.filter(
      (puzzlePreset) => !puzzlePreset.titles
    );
    const puzzlePresetsUser = this.allPuzzlePresets.filter((puzzlePreset) => {
      if (!puzzlePreset.titles) return false;

      return puzzlePreset.titles.some((title) => this.currentUserTitles.includes(title));
    });

    return [...puzzlePresetsWithoutTitle, ...puzzlePresetsUser];
  }

  get menuItems() {
    const presets = this.puzzlePresets.filter((pp) => !pp.titles || pp.titles.length === 0);
    const menuItems: IMenuItem[] = [
      {
        label: 'Puzzels',
        links: PuzzleIds.filter((p) =>
          presets.find(
            (preset) =>
              preset.renderer === p &&
              preset.stock.available > 0 &&
              (preset.source !== 'upload' || this.allowDownloadExternalPuzzles)
          )
        )
          .map((p) => ({
            link: '/shop/' + p,
            label: getDisplayNameByRenderer(p),
          }))
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
      },
    ];
    menuItems.push({
      label: 'Mijn puzzels',
      links: [
        // { label: 'Winkelmandje', link: '/shop/cart' },
        { label: 'Bestellingen', link: '/shop/orders' },
      ],
    });
    return menuItems;
  }

  get loading() {
    return this.puzzlePresets.length === 0;
  }

  async mounted() {
    await this.$store.dispatch('bindOrders');
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresets');
  }

  beforeDestroy() {
    this.$store.dispatch('unbindOrders');
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresets');
  }
}
