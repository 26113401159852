






import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ISudokuMixPreset } from '../../../../../functions/src/models/puzzles/sudokumix/interfaces';

@Component
export default class SudokuMixPresetEditor extends Vue {
  @PropSync('presetData') preset!: ISudokuMixPreset;
}
