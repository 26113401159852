import { AnyElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import {
  getCopyrightLabel,
  getHardcodedHeader,
  getText,
  smallDifficulty,
  smallText,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const filippine: AnyElement[] = [
  ...getHardcodedHeader(0, 0, 3152, 'Filippine'),
  // Date of today
  {
    type: 'text',
    value: '@DD-@MM-@YY',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    color: config.colorWhite,
    align: 'right',
    baseline: 'middle',
    top: 52,
    left: 2800,
    width: 320,
    height: 48,
  },
  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 80,
    left: 0,
    width: 3152,
    height: 2464,
  },
  ...getText(
    128,
    32,
    2336,
    80,
    'Vul het diagram in aan de hand van de vragen. Gelijke cijfers zijn gelijke letters. De letters in de verticale balk vormen de oplossing.'
  ), // puzzle
  {
    type: 'filippine',
    contentId: 'content-1',
    renderType: 'clueBlocks',
    clueBackgroundColor: config.colorWhite,
    indexBackgroundColor: config.colorGreyLight,

    indexFontSize: 8,
    indexFont: settings.fonts.textbold,
    clueFont: settings.fonts.text,
    clueFontSize: 8,

    top: 224,
    left: 5,
    width: 1360,
    height: 2304,

    columns: 3,
    rows: 8,
  },
  {
    type: 'filippine',
    contentId: 'content-1',
    renderType: 'filippine',
    gridBackgroundColor: config.colorWhite,
    blackCellColor: config.colorGreyDark,
    highlightColor: config.colorGreyLight,
    top: 224,
    left: 1408,
    width: 1728,
    height: 2304,
  },
  getCopyrightLabel(2250, 3104, 'Puzzelaar'),
];

const legPuzzel: AnyElement[] = [
  ...getHardcodedHeader(2544, 0, 2096, 'Legpuzzel'),
  ...getText(
    2656,
    16,
    2096,
    112,
    'Bij elke legpuzzel staan woorden alfabetisch gerangschikt naar woordlengte. Plaats de woorden uit de lijst op hun juiste plaats in het diagram, te beginnen met de woorden die kruisen met de weggever.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-2',
    onlyText: true,
    cluesColumns: 5,
    fontSize: config.fontSizeRegular,
    top: 2768,
    left: 64,
    width: 2032,
    height: 576,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    onlyGrid: true,
    blackCellColor: config.colorGreyDark,
    top: 3376,
    left: 0,
    width: 2096,
    height: 1040,
  },
  getCopyrightLabel(4150, 2096, 'Puzzelaar'),
];

const ikura: AnyElement[] = [
  ...getHardcodedHeader(2544, 2144, 1008, 'Ikura'),
  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 2624,
    left: 2144,
    width: 1008,
    height: 928,
  },
  ...getText(
    2656,
    2160,
    960,
    144,
    'Plaats de cijfers 1 t/m 9 zodanig dat de optelsom in zowel de rij als de kolom klopt. Elk cijfer mag maar één keer gebruikt worden. Naast het diagram staan de nog te plaatsen cijfers.'
  ),
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'clues',
    top: 2816,
    left: 2240,
    width: 128,
    height: 688,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    renderType: 'grid',
    top: 2816,
    left: 2384,
    width: 688,
    height: 688,
  },
  getCopyrightLabel(3274, 3104, 'Q42'),
];

const kakuro: AnyElement[] = [
  ...getHardcodedHeader(3552, 2144, 1008, 'Kakuro'),
  {
    type: 'box',
    backgroundColor: config.colorBlueBackground,
    top: 3632,
    left: 2144,
    width: 1008,
    height: 784,
  },
  ...getText(
    3656,
    2160,
    960,
    240,
    'Elk in te vullen getal is de som van de cijfers in het zwarte vakje. Het getal boven een diagonale lijn heeft betrekking op de horizontale cijfers. Het getal onder de lijn is de som van de cijfers die verticaal moeten worden ingevuld. De 0 wordt niet gebruikt en een getal bevat uitsluitend verschillende cijfers.'
  ),
  {
    type: 'kakuro',
    contentId: 'content-4',
    blackCellColor: config.colorBlack,
    oddCellBackgroundColor: config.colorWhite,
    gridBackgroundColor: config.colorWhite,
    top: 3840 + 32,
    left: 2368,
    width: 576,
    height: 576 - 32,
  },
  getCopyrightLabel(4154, 3104, 'Puzzelaar'),
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'HLN - Saturday 2 template',
    layout: layout,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2', 'content-3', 'content-4'],
      ...settings,
    },
    templates: {
      smallText,
      smallDifficulty,
    },
    elements: [...filippine, ...legPuzzel, ...ikura, ...kakuro],
  },
};

export default layoutTemplate;
