








import { Component, PropSync, Vue } from 'vue-property-decorator';

import { I0hn0Preset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class Ohn0PresetEditor extends Vue {
  @PropSync('presetData') preset!: I0hn0Preset;
}
