import { compact } from 'lodash';

import {
  ILineElement,
  IRicherTextElement,
  IRicherTextPart,
  ITextElement,
} from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';
import config from './config';

export function getHeader(
  top: number,
  left: number,
  width: number,
  headerBold: string,
  headerRegular: string | null = null,
  includeDate = false
): (IRicherTextElement | ILineElement)[] {
  const defaultTextOptions: Partial<IRicherTextPart> = {
    color: config.colorBlack,
    fallbacks: [
      { fontSize: 17.5 / DEFAULT_SCALE },
      { fontSize: 17 / DEFAULT_SCALE },
      { fontSize: 16.5 / DEFAULT_SCALE },
    ],
  };

  return [
    {
      type: 'richerText',
      parts: compact([
        {
          ...defaultTextOptions,
          font: config.fontBold,
          fontSize: config.fontSizeLarge,
          text: headerBold,
        },
        headerRegular && {
          ...defaultTextOptions,
          font: config.fontLight,
          fontSize: config.fontSizeLarge,
          text: headerRegular,
          dateFormat: 'EEEE d MMMM',
        },
        includeDate && {
          ...defaultTextOptions,
          font: config.fontRegular,
          fontSize: config.fontSizeSmall,
          text: '  @DD-@MM-@YYYY',
        },
      ]),
      top,
      left,
      width,
      height: 64,
    },
    getLine(top + 96, left, width),
  ];
}

export function getLine(top: number, left: number, width: number): ILineElement {
  return {
    type: 'line' as const,
    color: config.colorBlack,
    lines: [
      {
        coordinates: [
          { x: left, y: top },
          { x: left + width, y: top + 1 },
        ],
      },
    ],
  };
}

export const smallText = {
  elements: [
    {
      type: 'text',
      color: config.colorBlack,
      font: config.fontBold,
      fontSize: config.fontSizeSmall,
      baseline: 'top',
      lineGap: -1,
      value: '@content/header',
    },
  ],
};

export function getText(
  top: number,
  left: number,
  width: number,
  height: number,
  value: string
): ITextElement[] {
  return [
    {
      type: 'text',
      value,
      color: config.colorBlack,
      fontSize: config.fontSizeRegular,
      font: config.fontRegular,
      top,
      left,
      height,
      width,
    },
  ];
}

export const copyright = {
  elements: [
    {
      type: 'text',
      value: '© De Puzzelaar',
      font: config.fontRegular,
      fontSize: config.fontSizeSmall,
      height: 32,
    },
  ],
};

export const copyrightQ42 = {
  elements: [
    {
      type: 'text',
      value: '© 42Puzzles',
      font: config.fontRegular,
      fontSize: config.fontSizeSmall,
      height: 32,
    },
  ],
};

export function getCopyrightLabel(top: number, left: number, value: 'Q42' | 'Puzzelaar'): any {
  switch (value) {
    case 'Q42':
      return { ...copyrightQ42.elements[0], top, left };
    case 'Puzzelaar':
      return { ...copyright.elements[0], top, left };
  }
}
