import { IBoxElement } from '../../../models/ITemplate';
import { config } from '../config';

export const contentBoxHalfHeight = (offsetTop = 0): IBoxElement => ({
  type: 'box',
  top: config.topContentBox + offsetTop,
  left: config.leftContentBox,
  height: config.contentBoxHalfHeight,
  width: config.widthContentBox,
  backgroundColor: [0, 0, 0, 0],
  borderSize: 3,
});
