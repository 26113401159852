




















import { Component, Prop, Watch } from 'vue-property-decorator';

import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class RequireTechniqueEditor extends TypedVue {
  @Prop() value!: { [index: number]: boolean | number };
  @Prop() amount!: number;

  techniques: { [index: number]: boolean | number } = {};

  @Watch('value', { immediate: true, deep: true })
  onValueChanged(newVal: { [index: number]: boolean | number }) {
    this.techniques = new Array(this.amount).fill(0).reduce((acc, next, i) => {
      acc[i + 1] = newVal[i + 1] ?? 0;
      return acc;
    }, {} as { [index: number]: boolean | number });
  }

  setTechnique(key: number, value: string | 'false') {
    const typedVal = value === 'false' ? false : +value;
    if (typedVal === 0) {
      delete this.techniques[key];
    } else {
      this.techniques[key] = typedVal;
    }

    this.$emit('input', this.techniques);
  }
}
