import { Route } from 'vue-router';

import puzzleMakerComponent from '../components/puzzlemaker/PuzzleMaker.vue';
import wordfindMakerComponent from '../components/puzzlemaker/wordfind/WordfindMaker.vue';
import puzzlesOverviewComponent from '../components/puzzles/overview/PuzzlesOverview.vue';
import presetEditorComponent from '../components/puzzles/presets/editor/PresetEditor.vue';
import presetOverviewComponent from '../components/puzzles/presets/overview/PresetOverview.vue';
import puzzlesContentDetailComponent from '../components/puzzles/PuzzlesContentDetail.vue';
import puzzlesUploadDetailComponent from '../components/puzzles/PuzzlesUploadDetail.vue';
import puzzleZipsOverviewComponent from '../components/puzzles/puzzleZips/PuzzleZipsUploadOverview.vue';
import puzzlesPageComponent from '../views/PuzzlesPage.vue';
import { RouteName } from './RouteName';

export default [
  {
    path: '/puzzles',
    component: puzzlesPageComponent,
    redirect: '/puzzles/all',
    children: [
      {
        name: RouteName.AllPuzzles,
        path: 'all',
        component: puzzlesOverviewComponent,
        meta: { title: 'Alle puzzels' },
      },
      {
        name: RouteName.NewPreset,
        path: 'new',
        component: presetEditorComponent,
        meta: { title: 'Nieuwe puzzel' },
      },
      {
        name: RouteName.EditPreset,
        path: ':renderer/:id/edit',
        component: presetEditorComponent,
        props: true,
        meta: { title: 'Puzzel aanpassen' },
      },
      {
        name: RouteName.UploadSingle,
        path: 'upload/:id',
        component: puzzlesUploadDetailComponent,
        props: true,
        meta: { title: 'Puzzel aanleveren' },
      },
      {
        name: RouteName.PuzzlesUploadZip,
        path: 'zip-upload',
        component: puzzleZipsOverviewComponent,
        meta: { title: 'Puzzel zips aanleveren' },
      },
      {
        name: RouteName.PuzzleContentDetail,
        path: ':renderer/:id/content/:contentId',
        component: puzzlesContentDetailComponent,
        props: true,
        meta: { title: 'Puzzel voorraad' },
      },
      {
        path: 'create',
        component: puzzleMakerComponent,
        props: true,
        redirect: '/puzzles/create/wordfind',
        children: [
          {
            name: RouteName.PuzzleCreateWordfind,
            path: 'wordfind',
            component: wordfindMakerComponent,
            props: true,
            meta: { title: 'Puzzel maken' },
          },
        ],
      },
      // Keep this route last, so it won't hit when going to create/wordfind for example.
      {
        name: RouteName.PresetOverview,
        path: ':renderer/:id',
        component: presetOverviewComponent,
        props: (route: Route) => ({ id: route.params.id, view: route.query.view }),
        meta: { title: 'Puzzel voorraad' },
      },
    ],
  },
];
