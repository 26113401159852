import { ITemplate } from '../../../models/ITemplate';
import config, { configSaturday } from '../components/config';
import {
  difficulty,
  difficultySmall,
  headerLarge,
  headerSmall,
  headerSolution,
} from '../components/layoutParts';
import { layoutSaturday, settings } from '../components/pageParts';

const layoutTemplate: ITemplate = {
  page: {
    name: 'Parool - Saturday template',
    layout: layoutSaturday,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2', 'content-3', 'content-4'],
      ...settings,
    },
    templates: {
      headerSmall: headerSmall as any,
      headerLarge: headerLarge as any,
      headerSolution: headerSolution as any,
      difficulty: difficulty as any,
      difficultySmall: difficultySmall as any,
    },
    elements: [
      {
        type: 'custom',
        name: 'headerSmall',
        value: '@content/header',
        contentId: 'content-1',
        top: 32,
        left: 0,
        width: 3136,
        height: 64,
      } as any,
      {
        type: '@content/renderer',
        contentId: 'content-1',
        renderType: 'grid',
        font: config.fontRegular,
        top: 128,
        left: 0,
        width: 3136,
        height: 1888,
      },
      {
        type: 'line',
        color: [0, 0, 0, 100],
        lineWidth: 64,
        lines: [
          {
            coordinates: [
              { x: 0, y: 2048 },
              { x: 3136, y: 2048 },
            ],
          },
        ],
      },
      {
        type: 'custom',
        name: 'headerSmall',
        value: '@content/header',
        contentId: 'content-2',
        top: 2112,
        left: 0,
        width: 864,
        height: 64,
      },
      {
        type: 'custom',
        name: 'difficulty',
        contentId: 'content-2',
        top: 2208,
        left: 0,
        width: 864,
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        top: 2272,
        left: 0,
        width: 864,
        height: 864,
      },
      {
        type: 'custom',
        name: 'difficulty',
        contentId: 'content-3',
        top: 3200,
        left: 0,
        width: 864,
      },
      {
        type: '@content/renderer',
        contentId: 'content-3',
        top: 3264,
        left: 0,
        width: 864,
        height: 864,
      },
      {
        type: 'line',
        color: [0, 0, 0, 100],
        lineWidth: 1,
        lines: [
          {
            coordinates: [
              { x: 928, y: 2112 },
              { x: 928, y: 4288 },
            ],
          },
        ],
      },
      {
        type: 'custom',
        name: 'headerSmall',
        value: '@content/header',
        contentId: 'content-4',
        top: 2112,
        left: 960,
        width: 864,
        height: 64,
      },
      {
        type: '@content/renderer',
        contentId: 'content-4',
        blackCellColor: config.colorBlack,
        onlyGrid: true,
        top: 2112,
        left: 1856,
        width: 1280,
        height: 1472,
      },
      {
        type: '@content/renderer',
        contentId: 'content-4',
        fontSize: config.fontSizeRegular,
        lineGap: config.lineGap,
        onlyText: true,
        top: 2208,
        left: 960,
        width: 864,
        height: 1376,
      },
      {
        type: 'line',
        color: [0, 0, 0, 100],
        lineWidth: 1,
        lines: [
          {
            coordinates: [
              { x: 960, y: 3648 },
              { x: 3136, y: 3648 },
            ],
          },
        ],
      },
      // SOLUTIONS
      {
        type: 'custom',
        name: 'headerSmall',
        value: 'OPLOSSINGEN VORIGE WEEK',
        top: 3648,
        left: 960,
        width: 2176,
        height: 64,
      },
      {
        // Best effort for now...
        type: 'custom',
        name: 'headerSmall',
        contentId: 'play-more',
        value: '@content/text',
        fontSize: configSaturday.fontSizeLarge,
        top: 4172,
        left: 960,
        width: 672,
        height: 96,
        lineGap: 0,
      },
      // Filippine/ Zweedse puzzel
      {
        type: 'custom',
        name: 'headerSolution',
        value: '@content/header',
        contentId: 'solution-1-1',
        top: 3712,
        left: 960,
        width: 672,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-1',
        renderType: 'grid',
        blackCellColor: config.colorBlack,
        solution: true,
        top: 3776,
        left: 960,
        width: 672,
        height: 384,
      },
      // Cryptogram
      {
        type: 'custom',
        name: 'headerSolution',
        value: '@content/header',
        contentId: 'solution-1-4',
        top: 3712,
        left: 1664,
        width: 480,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-4',
        blackCellColor: config.colorBlack,
        onlyGrid: true,
        solution: true,
        top: 3776,
        left: 1664,
        width: 480,
        height: 512,
      },
      // Sudoku's
      {
        type: 'custom',
        name: 'difficultySmall',
        contentId: 'solution-1-2',
        top: 3776,
        left: 2208,
        width: 448,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-2',
        solution: true,
        top: 3840,
        left: 2208,
        width: 448,
      },
      {
        type: 'custom',
        name: 'difficultySmall',
        contentId: 'solution-1-2',
        top: 3776,
        left: 2688,
        width: 448,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-3',
        solution: true,
        top: 3840,
        left: 2688,
        width: 448,
        height: 448,
      },
      {
        type: 'custom',
        name: 'headerSolution',
        value: '@content/header',
        contentId: 'solution-1-2',
        top: 3776,
        left: 2208,
        width: 448,
      },
    ],
  },
};

export default layoutTemplate;
