





















































import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';
import { IPuzzleId } from 'functions/src/models/puzzles/IPuzzles';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { PuzzlePresetContentItem } from '../../../../../functions/src/models/db/PuzzlePresetContent';
import { getDisplayNameByRenderer } from '../../../../../functions/src/utils/puzzleUtils';
import { functions } from '../../../../store/db';
import { showError } from '../../../../utils/errors';
import Loading from '../../../Loading.vue';
import { batchCheckablePuzzles } from './stockTableKeys';

const PuzzlePresetStore = namespace('puzzlePresetStore');
@Component({
  components: { Loading },
})
export default class BatchPuzzleChecker extends Vue {
  @Prop() puzzlePresetDetail!: PuzzlePresetDb & { id: string };
  @PuzzlePresetStore.State('checkablePuzzlePresetContent')
  puzzlePresetContent!: (PuzzlePresetContentItem & {
    id: string;
  })[];

  currentPage = 1;
  loading = true;

  get puzzleData(): (PuzzlePresetContentItem & { id: string })[] {
    return this.puzzlePresetContent.filter((p) => p.approved === 'initial');
  }

  get keysToCheck() {
    return batchCheckablePuzzles[this.$route.params.renderer];
  }

  async approve(e: Event, data: Partial<PuzzlePresetContentItem>) {
    e.stopPropagation();
    await this.save({ ...data, approved: 'approved' }, 'Puzzel goedgekeurd');
  }

  async reject(e: Event, data: Partial<PuzzlePresetContentItem>) {
    e.stopPropagation();
    await this.save({ ...data, approved: 'rejected', status: 'used' }, 'Puzzel afgekeurd');
  }

  getFormattedDate(date: string) {
    return format(new Date(date), 'd MMMM Y', { locale: nl });
  }

  getFormattedValue(value: any) {
    if (typeof value === 'object') {
      return Object.values(value).join(', ');
    }

    if (typeof value === 'string') {
      return value.replace('@', 'ij');
    }

    return value;
  }

  goToDetailPage(row: { id: string }) {
    const path = `/puzzles/${this.puzzlePresetDetail.renderer}/${this.puzzlePresetDetail.id}/content/${row.id}`;
    this.$router.push({ path });
  }

  getStatusType(status: 'initial' | 'reserved' | 'used') {
    if (status !== 'initial') {
      return 'is-warning is-light';
    }
  }

  getStatusLabel(status: 'initial' | 'reserved' | 'used') {
    switch (status) {
      case 'initial':
        return 'Beschikbaar';
      case 'reserved':
        return 'Gereserveerd';
      case 'used':
        return 'Gebruikt';
    }
  }

  getDisplayNameByRenderer(renderer: IPuzzleId) {
    return getDisplayNameByRenderer(renderer);
  }

  async save(data: Partial<PuzzlePresetContentItem>, successMessage: string) {
    try {
      await this.$store.dispatch('savePuzzlePresetContentDetail', {
        presetId: this.$route.params.id,
        contentId: data.id,
        contentData: { approved: data.approved, status: data.status },
      } as Partial<PuzzlePresetContentItem>);

      // don't await, it can just finish in the background
      functions.httpsCallable('calculateAllStockRest')({
        preset: this.puzzlePresetDetail,
      });

      this.$buefy.snackbar.open({
        message: successMessage,
        type: 'is-success',
        actionText: 'Ongedaan maken',
        onAction: () => {
          this.revertApproval(data);
        },
      });
    } catch (err: unknown) {
      return showError(err);
    }
  }

  async revertApproval(data: Partial<PuzzlePresetContentItem>) {
    try {
      await this.$store.dispatch('savePuzzlePresetContentDetail', {
        presetId: this.$route.params.id,
        contentId: data.id,
        contentData: { approved: 'initial' },
      } as Partial<PuzzlePresetContentItem>);
    } catch (err: unknown) {
      return showError(err);
    }
  }

  async mounted() {
    await this.$store.dispatch('puzzlePresetStore/bindCheckablePuzzlePresetContent', {
      presetId: this.$route.params.id,
      orderByDirection: this.$route.params.renderer === 'koprol' ? 'desc' : 'asc',
    });

    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePresetStore/unbindCheckablePuzzlePresetContent');
  }
}
