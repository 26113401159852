import { IRow } from '../../models/ITemplate';
import { clone } from '../../utils';
import { artifacts } from './components/artifacts';
import { contentBoxFullHeight } from './components/contentBoxFullHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';

// Clone the layout so you can override settings
const layout = clone(storyLayout);
const titleHeight = 100;
const titleTopMargin = 70;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};

// Rows
const titleRow: IRow = {
  height: titleHeight,
  elements: [storyHeader('content-1')],
};

// Note: Because the Swedish puzzle and Wordfind have their own layout, this layout basically is for the Crossword
const puzzleRow: IRow = {
  height: config.contentBoxFullHeight - titleHeight - titleTopMargin * 2, // leaves some margin at the bottom
  elements: [
    {
      type: '@content/renderer' as any,
      contentId: 'content-1',
      value: '@content/text',
      font: storyConfig.fontText,
      fontSize: 41,
      borderSize: 5,
      blackCellColor: [0, 0, 0, 100],
    },
  ],
};

// Layout
export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground, contentBoxFullHeight, ...artifacts, ...storyPageNumbers],
    rows: [titleRow, puzzleRow],
  },
};
