import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';

export type AmountOfWeeksLeft = number | 'Niet gebruikt';

export function getAmountOfWeeksLeft(puzzlePreset: PuzzlePresetDb): AmountOfWeeksLeft {
  if (puzzlePreset.usage.expectedWeeklyUse === 0) {
    return 'Niet gebruikt';
  }

  const approvedWeeks =
    Math.round((puzzlePreset.stock.approved / puzzlePreset.usage.expectedWeeklyUse) * 10) / 10;
  const availableWeeks =
    Math.round((puzzlePreset.stock.available / puzzlePreset.usage.expectedWeeklyUse) * 10) / 10;

  return puzzlePreset.needsApproval ? approvedWeeks : availableWeeks;
}
