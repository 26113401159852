import { ITextElement } from '../../../models/ITemplate';
import { storyConfig } from './story-config';
import { storyLayout } from './story-layout';

const pageNumberLeft: ITextElement = {
  type: 'text',
  contentId: 'page-number-left',
  value: '@content/text',
  top: storyLayout.height - 100,
  left: 165, // (2610 - 2280) / 2
  font: storyConfig.fontFooter,
  fontSize: storyConfig.fontSizeSmall,
  color: storyConfig.colorWhite,
  ignoreRenderWarning: true,
};

const pageNumberRight: ITextElement = {
  type: 'text',
  contentId: 'page-number-right',
  value: '@content/text',
  top: storyLayout.height - 100,
  left: storyLayout.width - 213, // (2610 - 2280) / 2 + (97 / 2)
  font: storyConfig.fontFooter,
  fontSize: storyConfig.fontSizeSmall,
  color: storyConfig.colorWhite,
  ignoreRenderWarning: true,
};

export const storyPageNumbers = [pageNumberLeft, pageNumberRight];
