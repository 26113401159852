import config from './config';
import { getBox, getHeader } from './layoutParts';

export function getBlockWWW(contentId = '') {
  return [
    // Content-3: WWW
    ...(getHeader(0, 1728, 1424, 80, contentId, 'regular') as any),
    ...getBox(80, 1728, 1424, 1264),
    {
      type: '@content/renderer',
      contentId,
      renderType: 'hint',
      font: config.fontHandwriting,
      fontSize: 38.5,
      top: 4,
      left: 2464,
      width: 672,
      height: 192,
    },
    {
      type: '@content/renderer',
      contentId,
      renderType: 'bar',
      gridBackgroundColor: config.colorWhite,
      top: 272,
      left: 2432,
      width: 704,
      height: 112,
    },
    {
      type: 'text',
      contentId,
      value: '@content/explanation',
      font: config.fontRegular,
      fontSize: config.fontSizeRegular,
      color: config.colorBlack,
      top: 112,
      left: 1760,
      width: 656,
      height: 288,
    },
    {
      type: '@content/renderer',
      contentId,
      renderType: 'questions',
      font: config.fontRegular,
      fontSize: config.fontSizeRegular,
      top: 464,
      left: 1760,
      width: 1360,
      height: 896,
    },
    // Copyright
    {
      type: 'custom',
      name: 'copyright',
      top: 1074,
      left: 3120,
    },
  ];
}
