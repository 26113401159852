import { ITemplate } from '../../../models/ITemplate';
import getAnswersBlock from '../components/block-answers';
import sudokuBlock from '../components/block-sudoku';
import swedishBlock from '../components/block-swedish';
import config from '../components/config';
import {
  difficulty,
  difficultySmall,
  headerLarge,
  headerSmall,
  headerSolution,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const layoutTemplate: ITemplate = {
  page: {
    name: 'Parool - Thursday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2', 'content-3', 'content-4'],
      ...settings,
    },
    templates: {
      headerSmall: headerSmall as any,
      headerLarge: headerLarge as any,
      headerSolution: headerSolution as any,
      difficulty: difficulty as any,
      difficultySmall: difficultySmall as any,
    },
    elements: [
      {
        type: '@content/renderer',
        contentId: 'content-1',
        onlyGrid: true,
        top: 0,
        left: 0,
        width: 1440,
        height: 672,
      } as any,
      {
        type: 'custom',
        name: 'headerSmall',
        value: '@content/header',
        contentId: 'content-1',
        top: 0,
        left: 1472,
        width: 1664,
        height: 64,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        fontSize: config.fontSizeRegular,
        lineGap: config.lineGap,
        onlyText: true,
        top: 96,
        left: 1472,
        width: 1664,
        height: 544,
      },

      ...swedishBlock,

      ...sudokuBlock,

      // SOLUTIONS
      ...getAnswersBlock(),

      // Codekraker
      {
        type: 'custom',
        name: 'headerSolution',
        value: '@content/header',
        contentId: 'solution-1-1',
        top: 3168,
        left: 0,
        width: 1216,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-1',
        top: 3232,
        left: 0,
        width: 1216,
        height: 320,
        solution: true,
      },
    ],
  },
};

export default layoutTemplate;
