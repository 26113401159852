import { IRow } from '../../models/ITemplate';
import { ISwedishCrosswordElement } from '../../models/puzzles/swedishcrossword/interfaces';
import { clone } from '../../utils';
import { artifacts } from './components/artifacts';
import { contentBoxFullHeight } from './components/contentBoxFullHeight';
import { storyPageNumbers } from './components/pageNumbers';
import { puzzleExplanation } from './components/puzzleExplanation';
import { storyBackground } from './components/story-background';
import { storyConfig, storySettings } from './components/story-config';
import { storyLayout } from './components/story-layout';
import { storyHeader } from './components/storyHeader';
import { config } from './config';

// Clone the layout so you can override settings
const layout = clone(storyLayout);

// Heights and widths
const titleHeight = 100;
const titleTopMargin = 70;

// Margins
layout.margin = {
  top: config.topContentBox + titleTopMargin,
  right: config.leftContentBox + config.widthContentBox * 0.02,
  bottom: 0,
  left: config.leftContentBox + config.widthContentBox * 0.02,
};

// Elements
const puzzle: ISwedishCrosswordElement = {
  type: '@content/renderer' as any,
  contentId: 'content-1',
  explanationAbove: puzzleExplanation('content-1'),
  blackCellColor: [0, 11, 6, 0],
  highlightType: 'none',
  renderType: 'grid',
  // font: storyConfig.fontText, // Something weird in this font...
  font: storyConfig.fontSwedish,
  fontSize: 30,
  fontSizeFactor: 0.1526100127, // makes 5
};

// Rows
const titleRow: IRow = {
  height: titleHeight,
  elements: [storyHeader('content-1')],
};

const puzzleRow: IRow = {
  height: config.contentBoxFullHeight - titleHeight - titleTopMargin * 2, // leaves some margin at the bottom
  elements: [puzzle],
};

// Layout
export default {
  page: {
    layout: layout,
    settings: storySettings,
    elements: [storyBackground, contentBoxFullHeight, ...artifacts, ...storyPageNumbers],
    rows: [titleRow, puzzleRow],
  },
};
