import config from './config';

const block = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 0, y: 704 },
          { x: 3136, y: 704 },
        ],
      },
    ],
  },
  {
    type: 'custom',
    name: 'headerLarge',
    value: '@content/header',
    contentId: 'content-2',
    top: 704,
    left: 0,
    width: 1568,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'grid',
    font: config.fontRegular,
    top: 800,
    left: 0,
    width: 3136,
    height: 1024,
  },
];

export default block;
