











import { Component, PropSync } from 'vue-property-decorator';

import { IToevoegAnagramPreset } from '../../../../../functions/src/models/puzzles/toevoeganagram/interfaces';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class ToevoeganagramPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: IToevoegAnagramPreset;
}
