import { AnyElement, IImageElement, ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { getCopyrightLabel, getHeader } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const crossword: AnyElement[] = [
  ...getHeader(0, 0, 1792, 'Kruiswoord', 'puzzel', true),
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    onlyText: true,
    top: 112,
    left: 0,
    width: 768,
    height: 1424,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 112,
    left: 800,
    width: 992,
    height: 1408,
  },
  getCopyrightLabel(1504, 1560, 'Puzzelaar'),
];

const legPuzzel: AnyElement[] = [
  ...getHeader(0, 1872, 1280, 'Leg', 'puzzel'),
  {
    type: 'text',
    contentId: 'content-7',
    value: '@content/explanation',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 112,
    left: 1872,
    width: 1152,
    height: 192,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    cluesColumns: 3,
    onlyText: true,
    top: 352,
    left: 1888,
    width: 1056,
    height: 720,
  },
  {
    type: '@content/renderer',
    contentId: 'content-7',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 1136,
    left: 1888,
    width: 1256,
    height: 1264,
  },
  getCopyrightLabel(2400, 2904, 'Puzzelaar'),
];

const sudoku: AnyElement[] = [
  ...getHeader(1680, 0, 1792, 'Sudoku'),
  // puzzle 1
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 0,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    top: 1856,
    left: 0,
    width: 576,
    height: 576,
  },
  // puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 608,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 1856,
    left: 608,
    width: 576,
    height: 576,
  },
  // puzzle 3
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 1856 - 60,
    left: 1216,
    width: 208,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1856,
    left: 1216,
    width: 576,
    height: 576,
  },
  getCopyrightLabel(2436, 1588, 'Q42'),
];

const prizeBanner: IImageElement[] = [
  {
    type: 'image',
    contentId: 'play-banner',
    path: '@content/image',
    top: 2592,
    left: 0,
    width: 1792,
    height: 336,
    valign: 'bottom',
  },
];

const binaire: AnyElement[] = [
  ...getHeader(3056, 0, 1792, 'Binaire'),
  {
    type: 'text',
    contentId: 'content-5',
    value: '@content/explanation',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    top: 3184,
    left: 0,
    width: 1792,
    height: 192,
  },

  // puzzle 1
  {
    type: 'difficulty',
    difficulty: 1,
    publisher: 'de-morgen',
    contentId: 'content-5',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3375,
    left: 0,
    width: 832,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 3424,
    left: 0,
    width: 832,
    height: 832,
  },
  // puzzle 2
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'content-6',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3375,
    left: 960,
    width: 832,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 3424,
    left: 960,
    width: 832,
    height: 832,
  },
  getCopyrightLabel(3375, 1588, 'Q42'),
];

const solutionSudokuBlock: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 1888,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-2',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 1888 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 2656,
    left: 1888,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 2328,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-3',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 2328 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 2656,
    left: 2328,
    width: 384,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-4',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 2768,
    width: 384,
    height: 32,
  },
  {
    type: 'difficulty',
    difficulty: '@content/difficulty',
    publisher: 'de-morgen',
    contentId: 'solution-1-4',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 2656 - 48,
    left: 2768 + 124,
    width: 384 - 124,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-4',
    solution: true,
    top: 2656,
    left: 2768,
    width: 384,
    height: 384,
  },
];

const solutionsCrossword: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3168 - 48,
    left: 1888,
    width: 432,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    blackCellColor: config.colorBlack,
    solution: true,
    top: 3168,
    left: 1888,
    width: 432,
    height: 512,
  },
];

const solutionQuizFilippine: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3120,
    left: 2448,
    width: 192,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-6',
    blackCellColor: config.colorBlack,
    solutionType: 'grid',
    solution: true,
    top: 3168,
    left: 2448,
    width: 512,
    height: 512,
  },
  {
    type: 'text',
    contentId: 'solution-1-6',
    value: '@content/solutionTxt',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3696,
    left: 2448,
    width: 512,
    height: 32,
  },
];

const solutionToevoegAnagram: AnyElement[] = [
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/header',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3760,
    left: 1888,
    width: 900,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-5',
    solution: true,
    top: 3808,
    left: 1888,
    width: 900,
    height: 384,
  },
  {
    type: 'text',
    contentId: 'solution-1-5',
    value: '@content/solutionWord',
    color: config.colorBlack,
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 4200,
    left: 1888,
    width: 704,
    height: 32,
  },
];

const solutions: AnyElement[] = [
  ...getHeader(2496, 1888, 1264, 'Oplossingen ', '@SS'),
  ...solutionSudokuBlock,
  ...solutionsCrossword,
  ...solutionQuizFilippine,
  ...solutionToevoegAnagram,
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'DeMorgen - Tuesday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: [
        'content-1',
        'content-2',
        'content-3',
        'content-4',
        'content-5',
        'content-6',
        'content-7',
      ],
      ...settings,
    },
    templates: {},
    elements: [...crossword, ...legPuzzel, ...sudoku, ...prizeBanner, ...binaire, ...solutions],
  },
};

export default layoutTemplate;
