import { ITextElement } from '../../../models/ITemplate';
import { storyConfig } from './story-config';

export const solutionTitle = (value: string, offsetLeft = 0): ITextElement => ({
  left: offsetLeft,
  type: 'text',
  value,
  font: storyConfig.fontSubTitle,
  fontSize: 50,
});
