/**
 * globally accessible in vue templates
 */
export function clone<T>(obj: T): T {
  if (typeof obj === 'undefined') {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

export function intersect<T>(array1?: T[], array2?: T[]): T[] {
  if (!array1 || array1.length === 0) return [];
  if (!array2 || array2.length === 0) return [];
  return array1.filter((value) => array2.includes(value));
}

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let result = null;
      if (typeof reader.result === 'string') {
        result = reader.result.replace('data:', '').replace(/^.+,/, '');
      } else {
        result = reader.result;
      }
      resolve(result);
    };
    reader.onerror = (error) => reject(error);
  });

export const splitPascalCase = (word: string) => {
  const wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  return word.match(wordRe)?.join(' ') ?? word;
};
