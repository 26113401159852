






























































































































































import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { Component, Vue } from 'vue-property-decorator';

import { PuzzleIds } from '../../../../functions/src/models/puzzles/IPuzzles';
import { getDisplayNameByRenderer } from '../../../../functions/src/utils/puzzleUtils';
import { functions } from '../../../store/db';
import { showError } from '../../../utils/errors';

@Component
export default class DebugActions extends Vue {
  archivalDate = subDays(new Date(), 1);
  invalidatePdfCacheSelection = ['puzzles:all', 'pages:all'];
  loading: Record<string, boolean> = {
    generateKoprolItemsRest: false,
    calculateAllStockRest: false,
    archivePuzzlePagesRest: false,
    generatePuzzlePresetContentItemsRest: false,
    generatePuzzlePagesRest: false,
    exportToCdnRest: false,
    invalidatePdfCache: false,
    updateAllPresetUsageRest: false,
  };

  get archivalMaxDate() {
    return subDays(new Date(), 1);
  }
  get formattedArchivalDate() {
    return format(this.archivalDate, 'yyyy-MM-dd');
  }
  get invalidatePdfCacheOptions() {
    return [
      { value: 'pages:all', label: 'Printstraat: alles' },
      { value: 'puzzles:all', label: 'Shop: alles' },
      ...PuzzleIds.map((p) => ({
        value: 'puzzles:' + p,
        label: '  Shop: ' + getDisplayNameByRenderer(p),
      })).sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
    ];
  }

  async callFunction(name: string, data?: any) {
    try {
      this.loading[name] = true;
      console.log('call', name, data);
      const response = await functions.httpsCallable(name)(data);
      console.log(`Response van ${name}`, response);
      this.$buefy.snackbar.open({
        message: `Uitgevoerd: ${name}(${data ? JSON.stringify(data) : ''})`,
        type: 'is-success',
      });
    } catch (err) {
      showError(err, 'Uh-oh... check the console');
    }
    this.loading[name] = false;
  }

  areArraysEqual(array1: string[], array2: string[]) {
    return array1.length === array2.length && array1.every((v1) => array2.find((v2) => v1 === v2));
  }
}
