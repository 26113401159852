
















import { IState } from 'src/models/models';
import { Component, Vue } from 'vue-property-decorator';

import AssetOverview from './AssetOverview.vue';
@Component({
  components: { AssetOverview },
})
export default class AssetModal extends Vue {
  get modalActive() {
    return (this.$store.state as IState).isModalActive;
  }

  set modalActive(val) {
    void this.$store.dispatch('resetAsset');
  }
}
