import { IImageElement } from '../../../models/ITemplate';
import { storyLayout } from './story-layout';

export const storyBackground: IImageElement = {
  name: 'background',
  type: 'image',
  path: '@content/image',
  contentId: 'background',
  top: -(storyLayout.bleedBox ?? 0),
  left: -(storyLayout.bleedBox ?? 0),
  width: storyLayout.width + (storyLayout.bleedBox ?? 0) * 2,
  height: storyLayout.height + (storyLayout.bleedBox ?? 0) * 2,
  ignoreRenderWarning: true,
};
