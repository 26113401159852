import { SnackbarProgrammatic as Snackbar } from 'buefy';

export function showError(error: unknown, fallback?: string) {
  const message = getErrorMessage(error, fallback);
  console.warn(message, error);
  Snackbar.open({ message, type: 'is-danger' });
}

function getErrorMessage(error: unknown, fallback?: string): string {
  if (error instanceof Error) {
    return error.message;
  } else if (error instanceof String) {
    return error.toString();
  } else if (fallback) {
    return fallback;
  } else {
    return 'Er ging iets fout.';
  }
}
