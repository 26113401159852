import { IGeneratedWoordKraker, IWoordKrakerPreset } from './interfaces';

// Used in: functions/src/utils/puzzleUtils.ts
export const WoordKrakerName = 'WoordKraker';

// Used in: functions/src/db/puzzlePresetContentItemCreate.ts
export function getGeneratedWoordKrakerIdInput(generated: IGeneratedWoordKraker): string {
  return JSON.stringify(generated.solution);
}

// Used in: functions/src/models/puzzles/PresetHelper.ts
export function getDefaultPresetDataWoordKraker(): IWoordKrakerPreset {
  return {
    renderer: 'woordkraker',
    name: WoordKrakerName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'upload',
    needsApproval: false,
    generatorParams: {
      width: 8,
      height: 15,
      difficulty: 3,
      cluesWidth: 3,
      cluesHeight: 5,
    },
    rendererParams: {
      header: WoordKrakerName.toUpperCase(),
      explanation: 'Alle opgaven zijn kleine puzzels. H is horizontaal, V is verticaal.',
    },
  };
}
