export type IPuzzleId = typeof PuzzleIds[number];

export type IContentId = IPuzzleId | 'image' | 'text';

export const PuzzleIds = [
  '0hh1',
  '0hn0',
  'battleship',
  'blokjeom',
  'calcudoku',
  'codekraker', // AKA cijferkruiswoord
  'crossword',
  'cryptofilippine', // AKA QuizFilippine
  'doorloper',
  'filippine',
  'groterkleiner',
  'hashi',
  'horoscopesudoku',
  'ikura',
  'jigsawCrossword', // AKA woordlegger, legpuzzel
  'kakuro',
  'killersudoku', // AKA sudoku mix
  'knightmove',
  'koprol',
  'lettersudoku',
  'quento',
  'samuraisudoku', // AKA vijfling sudoku
  'spotthedifferences',
  'sudoku',
  'sudokumix',
  'sudokutwins',
  'swedishcrossword',
  'tectonic',
  'tentstrees',
  'whowhatwhere',
  'woordkraker',
  'wordfind',
  'toevoeganagram',
  'pacman',
  'imagepuzzle',
  'stepladder',
] as const;

export const isPuzzleId = (tbd: any): tbd is IPuzzleId => {
  return typeof tbd === 'string' && PuzzleIds.indexOf(tbd as IPuzzleId) >= 0;
};
