import { VueConstructor } from 'vue';

import { IPuzzleId } from '../../../../functions/src/models/puzzles/IPuzzles';
import { assertNever } from '../../../../functions/src/utils';
import BattleshipPresetEditor from './editor/BattleshipPresetEditor.vue';
import BlokjeOmPresetEditor from './editor/BlokjeOmPresetEditor.vue';
import CalcudokuPresetEditor from './editor/CalcudokuPresetEditor.vue';
import CodekrakerPresetEditor from './editor/CodekrakerPresetEditor.vue';
import CrosswordPresetEditor from './editor/CrosswordPresetEditor.vue';
import CryptofilippinePresetEditor from './editor/CryptofilippinePresetEditor.vue';
import DoorloperCrosswordPresetEditor from './editor/DoorloperCrosswordPresetEditor.vue';
import FilippinePresetEditor from './editor/FilippinePresetEditor.vue';
import GroterkleinerPresetEditor from './editor/GroterKleinerPresetEditor.vue';
import HashiPresetEditor from './editor/HashiPresetEditor.vue';
import HoroscopeSudokuPresetEditor from './editor/HoroscopeSudokuPresetEditor.vue';
import IkuraPresetEditor from './editor/IkuraPresetEditor.vue';
import ImagePuzzlePresetEditor from './editor/ImagePuzzlePresetEditor.vue';
import JigsawCrosswordPresetEditor from './editor/JigsawCrosswordPresetEditor.vue';
import KakuroPresetEditor from './editor/KakuroPresetEditor.vue';
import KillerSudokuPresetEditor from './editor/KillerSudokuPresetEditor.vue';
import KnightmovePresetEditor from './editor/KnightmovePresetEditor.vue';
import KoprolPresetEditor from './editor/KoprolPresetEditor.vue';
import LetterSudokuPresetEditor from './editor/LetterSudokuPresetEditor.vue';
import Ohh1PresetEditor from './editor/Ohh1PresetEditor.vue';
import Ohn0PresetEditor from './editor/Ohn0PresetEditor.vue';
import PacManPresetEditor from './editor/PacManPresetEditor.vue';
import QuentoPresetEditor from './editor/QuentoPresetEditor.vue';
import SamuraiSudokuPresetEditor from './editor/SamuraiSudokuPresetEditor.vue';
import SpotTheDifferencesEditor from './editor/SpotTheDifferencesPresetEditor.vue';
import StepLadderPresetEditor from './editor/StepLadderPresetEditor.vue';
import SudokuMixPresetEditor from './editor/SudokuMixPresetEditor.vue';
import SudokuPresetEditor from './editor/SudokuPresetEditor.vue';
import SwedishCrosswordPresetEditor from './editor/SwedishCrosswordPresetEditor.vue';
import TectonicPresetEditor from './editor/TectonicPresetEditor.vue';
import TentsTreesPresetEditor from './editor/TentsTreesPresetEditor.vue';
import ToevoegAnagramPresetEditor from './editor/ToevoegAnagramPresetEditor.vue';
import WhoWhatWherePresetEditor from './editor/WhoWhatWherePresetEditor.vue';
import WoordKrakerPresetEditor from './editor/WoordKrakerPresetEditor.vue';
import WordfindPresetEditor from './editor/WordfindPresetEditor.vue';

export function getPresetEditorComponent(renderer: IPuzzleId): VueConstructor<Vue> {
  switch (renderer) {
    case 'wordfind':
      return WordfindPresetEditor;
    case 'tectonic':
      return TectonicPresetEditor;
    case 'quento':
      return QuentoPresetEditor;
    case 'koprol':
      return KoprolPresetEditor;
    case 'sudokutwins':
    case 'sudokumix':
      return SudokuMixPresetEditor;
    case '0hh1':
      return Ohh1PresetEditor;
    case '0hn0':
      return Ohn0PresetEditor;
    case 'crossword':
      return CrosswordPresetEditor;
    case 'sudoku':
      return SudokuPresetEditor;
    case 'lettersudoku':
      return LetterSudokuPresetEditor;
    case 'horoscopesudoku':
      return HoroscopeSudokuPresetEditor;
    case 'tentstrees':
      return TentsTreesPresetEditor;
    case 'battleship':
      return BattleshipPresetEditor;
    case 'doorloper':
      return DoorloperCrosswordPresetEditor;
    case 'codekraker':
      return CodekrakerPresetEditor;
    case 'knightmove':
      return KnightmovePresetEditor;
    case 'cryptofilippine':
      return CryptofilippinePresetEditor;
    case 'swedishcrossword':
      return SwedishCrosswordPresetEditor;
    case 'samuraisudoku':
      return SamuraiSudokuPresetEditor;
    case 'killersudoku':
      return KillerSudokuPresetEditor;
    case 'filippine':
      return FilippinePresetEditor;
    case 'jigsawCrossword':
      return JigsawCrosswordPresetEditor;
    case 'spotthedifferences':
      return SpotTheDifferencesEditor;
    case 'kakuro':
      return KakuroPresetEditor;
    case 'calcudoku':
      return CalcudokuPresetEditor;
    case 'hashi':
      return HashiPresetEditor;
    case 'ikura':
      return IkuraPresetEditor;
    case 'groterkleiner':
      return GroterkleinerPresetEditor;
    case 'whowhatwhere':
      return WhoWhatWherePresetEditor;
    case 'blokjeom':
      return BlokjeOmPresetEditor;
    case 'woordkraker':
      return WoordKrakerPresetEditor;
    case 'toevoeganagram':
      return ToevoegAnagramPresetEditor;
    case 'pacman':
      return PacManPresetEditor;
    case 'imagepuzzle':
      return ImagePuzzlePresetEditor;
    case 'stepladder':
      return StepLadderPresetEditor;
    default:
      assertNever(renderer);
  }
}
