











import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IKakuroPreset } from '../../../../../functions/src/models/puzzles/kakuro/interfaces';

@Component
export default class KakuroPresetEditor extends Vue {
  @PropSync('presetData') preset!: IKakuroPreset;
}
