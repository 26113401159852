import config from './config';
import { getDifficulty, getHeader, getStaticDifficulty } from './layoutParts';

const block = [
  // Content 4 & 5: Binaire
  ...(getHeader(1392, 1728, 688, 80, 'content-4', 'dense') as any),
  {
    type: 'text',
    contentId: 'content-4',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 1488,
    left: 1728,
    width: 688,
    height: 240,
  },
  ...getStaticDifficulty(1744, 1728, 688, 56, 'Gemiddeld'),
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 1744 + 56,
    left: 1728,
    width: 688,
    height: 688,
  },
  ...getStaticDifficulty(2512, 1728, 688, 56, 'Gevorderden'),
  {
    type: '@content/renderer',
    contentId: 'content-5',
    top: 2512 + 56,
    left: 1728,
    width: 688,
    height: 688,
  },
  // Copyright
  {
    type: 'custom',
    name: 'copyrightQ42',
    top: 1744 - 32,
    left: 1724,
    width: 688,
    align: 'right',
    rotation: 0,
  },

  // Content 6 & 7: Sudoku
  ...(getHeader(1392, 2464, 688, 80, 'content-6', 'dense') as any),
  {
    type: 'text',
    contentId: 'content-6',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeRegular,
    color: config.colorBlack,
    top: 1488,
    left: 2464,
    width: 688,
    height: 240,
  },
  ...getDifficulty(1744, 2464, 688, 56, 'content-6'),
  {
    type: '@content/renderer',
    contentId: 'content-6',
    top: 1744 + 56,
    left: 2464,
    width: 688,
    height: 688,
  },

  ...getDifficulty(2512, 2464, 688, 56, 'content-7'),
  {
    type: '@content/renderer',
    contentId: 'content-7',
    top: 2512 + 56,
    left: 2464,
    width: 688,
    height: 688,
  },
  // Copyright
  {
    type: 'custom',
    name: 'copyrightQ42',
    top: 1744 - 32,
    left: 2842,
    width: 256,
    align: 'right',
    rotation: 0,
  },
];

export default block;
