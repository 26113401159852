import { PuzzlePresetDb } from 'functions/src/models/db/PuzzlePreset';
import { IPuzzleId } from 'functions/src/models/puzzles/IPuzzles';
import { IPuzzlePresetState, IState } from 'src/models/models';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';

import { PuzzlePresetContentItem } from '../../../functions/src/models/db/PuzzlePresetContent';
import { serializeWithId } from '..';
import { db } from '../db';

const puzzlePresetStore: Module<IPuzzlePresetState, IState> = {
  namespaced: true,
  state: {
    puzzlePresetDetail: null,
    puzzlePresetContent: [],
    rejectedPuzzlePresetContent: [],
    checkablePuzzlePresetContent: [],
    puzzlePresets: [],
  },
  getters: {},
  mutations: {},
  actions: {
    savePuzzlePresetDetail: async (
      _context,
      data: { presetId: string; presetData: Partial<PuzzlePresetDb> }
    ) => await db.collection('puzzlepresets').doc(data.presetId).update(data.presetData),
    bindPuzzlePresetDetail: firestoreAction(({ bindFirestoreRef }, id) =>
      bindFirestoreRef('puzzlePresetDetail', db.collection('puzzlepresets').doc(id))
    ),
    unbindPuzzlePresetDetail: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzlePresetDetail', true)
    ),
    bindPuzzlePresetShopContent: firestoreAction(
      ({ bindFirestoreRef }, { presetId, renderer }: { presetId: string; renderer: IPuzzleId }) =>
        bindFirestoreRef(
          'puzzlePresetContent',
          db
            .collection('puzzlepresets')
            .doc(presetId)
            .collection('content')
            .where('status', '==', 'initial')
            .orderBy('number', renderer === 'koprol' ? 'desc' : 'asc')
            .limit(20),
          { serialize: serializeWithId }
        )
    ),
    bindPuzzlePresetContent: firestoreAction(
      (
        { bindFirestoreRef },
        {
          presetId,
          status,
          page,
          perPage,
          orderByDirection,
        }: {
          presetId: string;
          page: number;
          perPage: number;
          status?: PuzzlePresetContentItem['status'];
          orderByDirection: 'asc' | 'desc';
        }
      ) => {
        let query: any = db.collection('puzzlepresets').doc(presetId).collection('content');
        if (status) {
          query = query.where('status', '==', status);
        }
        return bindFirestoreRef(
          'puzzlePresetContent',
          query.orderBy('number', orderByDirection).limit(Math.max(200, page * perPage) + 1),
          { serialize: serializeWithId }
        );
      }
    ),
    unbindPuzzlePresetContent: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('puzzlePresetContent');
    }),
    bindRejectedPuzzlePresetContent: firestoreAction(
      (
        { bindFirestoreRef },
        { presetId, orderByDirection }: { presetId: string; orderByDirection: 'asc' | 'desc' }
      ) => {
        return bindFirestoreRef(
          'rejectedPuzzlePresetContent',
          db
            .collection('puzzlepresets')
            .doc(presetId)
            .collection('content')
            .where('approved', '==', 'rejected')
            .orderBy('number', orderByDirection)
            .limit(200),
          { serialize: serializeWithId }
        );
      }
    ),
    unbindRejectedPuzzlePresetContent: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('rejectedPuzzlePresetContent');
    }),
    bindCheckablePuzzlePresetContent: firestoreAction(
      (
        { bindFirestoreRef },
        { presetId, orderByDirection }: { presetId: string; orderByDirection: 'asc' | 'desc' }
      ) => {
        return bindFirestoreRef(
          'checkablePuzzlePresetContent',
          db
            .collection('puzzlepresets')
            .doc(presetId)
            .collection('content')
            .where('approved', '==', 'initial')
            .where('status', '==', 'initial')
            .orderBy('number', orderByDirection)
            .limit(200),
          { serialize: serializeWithId }
        );
      }
    ),
    unbindCheckablePuzzlePresetContent: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('checkablePuzzlePresetContent');
    }),
    bindPuzzlePresetsUpload: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef(
        'puzzlePresets',
        db.collection('puzzlepresets').where('source', '==', 'upload').orderBy('name')
      )
    ),
    bindPuzzlePresets: firestoreAction(({ bindFirestoreRef }) =>
      bindFirestoreRef('puzzlePresets', db.collection('puzzlepresets').orderBy('name'))
    ),
    unbindPuzzlePresets: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzlePresets')
    ),
  },
};

export default puzzlePresetStore;
