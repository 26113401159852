import { ITemplate } from '../../../models/ITemplate';
import getAnswersBlock from '../components/block-answers';
import sudokuBlock from '../components/block-sudoku';
import swedishBlock from '../components/block-swedish';
import config from '../components/config';
import {
  difficulty,
  difficultySmall,
  headerLarge,
  headerSmall,
  headerSolution,
} from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const layoutTemplate: ITemplate = {
  page: {
    name: 'Parool - Monday template',
    layout: layout,
    settings: {
      solutionsForThisSchema: ['content-1', 'content-2', 'content-3', 'content-4'],
      ...settings,
    },
    templates: {
      headerSmall: headerSmall as any,
      headerLarge: headerLarge as any,
      headerSolution: headerSolution as any,
      difficulty: difficulty as any,
      difficultySmall: difficultySmall as any,
    },
    elements: [
      {
        type: 'custom',
        name: 'headerSmall',
        value: '@content/header',
        contentId: 'content-1',
        top: 0,
        left: 1568,
        width: 1568,
      } as any,
      {
        type: '@content/renderer',
        contentId: 'content-1',
        renderType: 'filippine',
        blackCellColor: config.colorBlack,
        top: 0,
        left: 0,
        width: 1536,
        height: 672,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        renderType: 'clues',
        fontSize: config.fontSizeRegular,
        lineGap: config.lineGap,
        top: 64,
        left: 1568,
        width: 1568,
        height: 384,
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        renderType: 'quote',
        blackCellColor: config.colorBlack,
        top: 480,
        left: 1568,
        width: 1504,
        height: 192,
        valign: 'bottom',
      },
      ...swedishBlock,

      ...sudokuBlock,

      // SOLUTIONS
      ...getAnswersBlock('VRIJDAG'),

      // Crossword
      {
        type: 'custom',
        name: 'headerSolution',
        value: '@content/header',
        contentId: 'solution-1-1',
        top: 3168,
        left: 0,
        width: 1216,
      },
      {
        type: '@content/renderer',
        contentId: 'solution-1-1',
        blackCellColor: config.colorBlack,
        solution: true,
        top: 3232,
        left: 0,
        width: 1216,
        height: 320,
      },
    ],
  },
};

export default layoutTemplate;
