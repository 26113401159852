import { ITemplate } from '../../../models/ITemplate';
import { ISwedishCrosswordElement } from '../../../models/puzzles/swedishcrossword/interfaces';
import { DEFAULT_SCALE } from '../../config';
import { FONTS } from '../../constants';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { mezzaFonts, mezzaTemplates } from '../components/generic';

const scale = DEFAULT_SCALE;
const layout: ITemplate = {
  page: {
    layout: {
      scale: DEFAULT_SCALE,
      width: 2480,
      height: 3508,

      // ___json_compatible_comment: 'these values are here for easy reference :)',
      // ___unused___InnerWidth: 2304,
      // ___unused___InnerHeight: 3136,

      // ___unused___nrOfColumnParts: 36,
      // ___unused___nrOfRowParts: 49,

      margin: {
        top: 192,
        right: 0,
        bottom: 0,
        left: 88,
      },
      columns: 36,
      cellPadding: {
        horizontal: 64,
        vertical: 64,
      },
    },
    settings: {
      solutionsFromOtherSchema: true,
      solutionsForThisSchema: ['content-1-1', 'content-2-1'],
      fonts: mezzaFonts,
    },
    templates: {
      ...mezzaTemplates,
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 32,
            height: 24,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
        ],
      },
    },
    elements: [
      // crossword
      getCopyrightLabelByOrigin(1472, 828, 'content-1-1'),
      // sudoku vijfling
      getCopyrightLabelByOrigin(2984, 1496, 'content-2-1'),
      // {
      //   type: '@content/renderer',
      //   contentId: 'sanders-logo',
      //   top: 3072,
      //   left: 1888 + 88,
      //   width: 320,
      //   height: 120,
      // },
    ],
    rows: [
      {
        height: 1504,
        elements: [
          {
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 10, y: 0.84 + 24 },
                  { x: 1664, y: 0.84 + 24 },
                ],
              },
            ],
          },
          {
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 1696, y: 0 },
                  { x: 1696, y: 1570 },
                ],
              },
            ],
            color: [0, 0, 0, 42],
          },
          {
            type: 'custom',
            name: 'header',
            value: '@content/header',
            contentId: 'content-1-1',
            width: 392,
          },
          {
            type: 'custom',
            name: '@content/renderer',
            fontSize: 8 / DEFAULT_SCALE,
            top: 80,
            contentId: 'content-1-1',
            blackCellColor: [58, 48, 47, 37],
            width: 1664,
            height: 1456,
            align: 'right',
          },
          {
            // line behind OPLOSSINGEN
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 1730, y: 0.84 + 24 },
                  { x: 2302, y: 0.84 + 24 },
                ],
              },
            ],
          },
          {
            type: 'custom',
            name: 'header',
            left: 1728,
            value: 'OPLOSSINGEN',
          },
          {
            type: 'text',
            contentId: 'solution-date',
            value: '@content/text',
            left: 1728,
            top: 70,
            font: FONTS.LFT_ETICA_SEMI_BOLD,
            fontSize: 44,
            characterSpacing: 0.7,
          },
          {
            // cryptogram
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-1-1-1',
            width: 576,
            top: 160,
            left: 1728,
            textTransform: 'first-capital',
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
          {
            // cryptogram
            type: '@content/renderer',
            top: 160 + 15,
            left: 1728,
            width: 576,
            height: 512,
            valign: 'top',
            contentId: 'solution-1-1-1',
            blackCellColor: [58, 48, 47, 37],
            fontSize: 8 / DEFAULT_SCALE,
            fontBold: FONTS.LFT_ETICA_SEMI_BOLD,
            lineGap: 0.1,
            solution: true,
            onlyText: true,
            valueSeparator: ',',
          },
          {
            // quiz woordzoeker
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-3-1-1',
            width: 576,
            top: 610,
            left: 1728,
            textTransform: 'first-capital',
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
          {
            // quiz woordzoeker
            type: '@content/renderer',
            top: 610 + 15,
            left: 1728,
            width: 576,
            height: 416,
            contentId: 'solution-3-1-1',
            fontSize: 8 / DEFAULT_SCALE,
            lineGap: 0.1,
            fontBold: FONTS.LFT_ETICA_SEMI_BOLD,
            solution: true,
            solutionType: 'text',
            valueSeparator: ',',
          },
          {
            // crypto fillippine
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-2-2-1',
            width: 576,
            top: 1114,
            left: 1728,
            textTransform: 'first-capital',
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
          {
            // crypto fillippine
            type: '@content/renderer',
            contentId: 'solution-2-2-1',
            top: 1114 + 15,
            left: 1728,
            width: 576,
            height: 320,
            fontSize: 8 / DEFAULT_SCALE,
            fontBold: FONTS.LFT_ETICA_SEMI_BOLD,
            lineGap: 0.1,
            solution: true,
            compact: true,
            valueSeparator: ',',
          },
        ],
      },
      {
        height: 1568,
        elements: [
          {
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 10, y: 0.84 + 24 },
                  { x: 2304, y: 0.84 + 24 },
                ],
              },
            ],
          },
          {
            type: 'line',
            lineWidth: 0.8 / scale,
            lines: [
              {
                coordinates: [
                  { x: 1472, y: 80 },
                  { x: 1472, y: 1488 },
                ],
              },
            ],
            color: [0, 0, 0, 42],
          },
          {
            type: 'custom',
            name: 'header',
            value: '@content/header',
            contentId: 'content-2-1',
            width: 512,
            top: 0,
          },
          {
            type: 'custom',
            name: '@content/renderer',
            top: 80,
            contentId: 'content-2-1',
            width: 1408,
            height: 1408,
            alternatingColor: [0, 4, 15, 0],
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
          {
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-1-2-1',
            top: 208,
            left: 1536,
            width: 672,
          },
          {
            type: '@content/renderer',
            top: 240,
            left: 1536,
            width: 672,
            height: 672,
            valign: 'top',
            contentId: 'solution-1-2-1',
            solution: true,
            alternatingColor: [0, 4, 15, 0],
          },
          {
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-2-1-1',
            top: 944,
            left: 1536,
            width: 320,
          },
          {
            type: 'swedishcrossword',
            top: 976,
            left: 1536,
            width: 320,
            height: 562,
            valign: 'top',
            contentId: 'solution-2-1-1',
            solution: true,
            renderType: 'grid',
            textCellBackgroundColor: [0, 0, 0, 0],
            blackCellColor: [58, 48, 47, 37],
          } as ISwedishCrosswordElement,
          {
            type: 'custom',
            name: 'solution-header',
            value: '@content/header',
            contentId: 'solution-3-2-1',
            top: 944,
            left: 1888,
            width: 320,
          },
          {
            type: '@content/renderer',
            top: 976,
            left: 1888,
            width: 320,
            height: 320,
            valign: 'top',
            contentId: 'solution-3-2-1',
            solution: true,
            alternatingColor: [0, 4, 15, 0],
          },
          {
            // floating image, does not have to be in grid system
            type: 'image',
            path: '@content/image',
            contentId: 'brand-image',
            width: 672,
            height: 192,
            left: 1536,
            top: 70,
          },
          {
            type: 'custom',
            name: 'page-identifier',
            top: 1450,
            left: 2018,
            font: FONTS.LFT_ETICA_SEMI_BOLD,
          },
        ],
      },
    ],
  },
};

export default layout;
