




























































import { PageDb } from 'functions/src/models/db/Page';
import uniq from 'lodash/uniq';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Loading from '@/components/Loading.vue';

import { UserMixin } from '../../mixins/User';
import Agenda from './Agenda.vue';

const PuzzlePageStore = namespace('puzzlePageStore');

@Component({
  components: {
    Agenda,
    Loading,
  },
})
export default class PuzzlePagesAgenda extends mixins(UserMixin) {
  @PuzzlePageStore.State('puzzlePages') pages!: PageDb[];
  startDate = new Date();
  title = null;
  loading = false;

  get titleOptions() {
    return uniq(this.pages.map((p) => p.schedulerTitles).flat()).sort();
  }

  get puzzlePages() {
    return this.pages.filter((p) => this.title === null || p.schedulerTitles.includes(this.title));
  }

  async mounted() {
    this.loading = true;
    await this.$store.dispatch('puzzlePageStore/bindPuzzlePageAgenda', this.currentUserTitles);
    this.loading = false;
  }

  get yesterday() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }

  beforeDestroy() {
    this.$store.dispatch('puzzlePageStore/undbindPuzzlePages');
  }
}
