


















































import { Component, Vue } from 'vue-property-decorator';

import { db, functions } from '../../../store/db';
import { splitPascalCase } from '../../../utils';
import { showError } from '../../../utils/errors';

@Component
export default class DebugActions extends Vue {
  databaseCheckRestResponse: any = null;

  settings: ({ id: string } & Record<string, number>)[] = [];

  loading: Record<string, boolean> = {
    databaseCheckRest: false,
  };

  get keys() {
    if (!this.settings.length) return [];
    return Object.keys(this.settings[0])
      .filter((k) => k !== 'id' && k !== 'date' && k !== 'ttl')
      .map((key) => ({ key, pretty: splitPascalCase(key) }));
  }

  async expand(id: string) {
    this.databaseCheckRestResponse = { loading: '...' };

    const result: Record<string, any[]> = {};

    for (const setting in this.settings.find((s) => s.id === id)) {
      if (setting === 'id' || setting === 'date' || setting === 'ttl') {
        continue;
      }
      const items = await db.collection('databasecheck').doc(id).collection(setting).get();
      result[setting] = items.docs
        .map((item) => item.data())
        .map((data) => {
          const { ttl, ...props } = data;
          return props;
        })
        .sort((a, b) => {
          if (a.date && b.date) {
            return a.date < b.date ? -1 : 1;
          }
          return 0;
        });
    }

    this.databaseCheckRestResponse = result;
  }

  listener: null | (() => void) = null;
  async mounted() {
    this.listener = await db
      .collection('databasecheck')
      .orderBy('date', 'desc')
      .limit(14)
      .onSnapshot((snap) => {
        this.settings = snap.docs.map(
          (i) => ({ ...i.data(), id: i.id } as { id: string } & Record<string, number>)
        );
      });
  }

  beforeDestroy() {
    this.listener && this.listener();
  }

  async checkDatabase() {
    const name = 'databaseCheckRest';
    try {
      this.loading[name] = true;
      const response = await functions.httpsCallable(name)();
      console.log(`Response van ${name}`, response);
      this.databaseCheckRestResponse = response.data;
    } catch (err) {
      showError(err, 'Uh-oh... check the console');
    }
    this.loading[name] = false;
  }
}
