import { ITentsTreesPreset } from '../../db/PuzzlePreset';

// Used in: functions/src/utils/puzzleUtils.ts
export const TentsTreesName = 'Tentje Boompje';

export function getDefaultPresetDataTentsTrees(): ITentsTreesPreset {
  return {
    renderer: 'tentstrees',
    name: TentsTreesName,
    stock: {
      available: 0,
      approved: 0,
    },
    usage: {
      expectedWeeklyUse: 0,
    },
    source: 'seed',
    needsApproval: false,
    generatorParams: {
      height: 10,
      width: 10,
      difficulty: 1,
    },
    rendererParams: {
      header: TentsTreesName.toUpperCase(),
      explanation:
        'Elke boom is verbonden met een tentje dat er direct naast, boven of onder staat. De te plaatsen tentjes raken elkaar niet, ook niet diagonaal. De cijfers naast en onder het diagram geven aan hoeveel tentjes zich in de betreffende rij of kolom bevindt.',
    },
  };
}
