









































































































































import { OrderDb } from 'functions/src/models/db/Order';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BreadCrumbs from '@/components/BreadCrumbs.vue';

import { PuzzlePresetDb } from '../../../functions/src/models/db/PuzzlePreset';
import { WithId } from '../../../functions/src/utils';
import { UserMixin } from '../../mixins/User';
import { IUser } from '../../models/models';
import { RouteName } from '../../router/RouteName';
import { showError } from '../../utils/errors';
import Loading from '../Loading.vue';
import TitlePicker from '../TitlePicker.vue';

const UserStore = namespace('userStore');
const PuzzlePresetStore = namespace('puzzlePresetStore');

@Component({
  components: {
    BreadCrumbs,
    Loading,
    TitlePicker,
  },
})
export default class UserDetail extends mixins(UserMixin) {
  @Prop() id!: string;
  @UserStore.State('user') user!: IUser;
  @UserStore.State('userOrders') orders!: OrderDb[];
  @PuzzlePresetStore.State('puzzlePresets') presets!: WithId<PuzzlePresetDb>[];

  loading = false;
  userTitles: 'SELECT' | 'ALL' = 'ALL';
  previouslySelectedTitles: string[] = [];

  get presetOptions() {
    const checks = this.user.allowedPresetsUpload ?? [];
    return this.presets.map((preset) => {
      let name = preset.name;
      const amount = preset.usage.schedulers?.length ?? 0;
      if (amount > 0 && preset.usage.schedulers) {
        name += ` (${preset.usage.schedulers[0].name}${amount > 1 ? ` + ${amount - 1}` : ''})`;
      } else {
        name += ` (ongebruikt)`;
      }
      return { id: preset.id, name, checked: checks.indexOf(preset.id) >= 0 };
    });
  }

  get breadCrumbs() {
    return [{ name: 'Gebruikers', routeObject: { name: RouteName.AdminUsers } }, { name: this.id }];
  }

  get shopDownloads() {
    return this.orders.reduce((prev, next) => prev + next.puzzles.length, 0);
  }

  async mounted() {
    this.loading = true;
    await this.$store.dispatch('userStore/bindUser', { userId: this.id });
    await this.$store.dispatch('userStore/bindUserOrders', { userId: this.id });
    await this.$store.dispatch('puzzlePresetStore/bindPuzzlePresetsUpload');
    this.setUserTitles();
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('userStore/unbindUser');
    this.$store.dispatch('userStore/unbindUserOrders');
    this.$store.dispatch('puzzlePresetStore/unbindPuzzlePresets');
  }

  setUserTitles() {
    if (!this.user.titles) {
      this.user.titles = [];
    }
    this.userTitles = this.user.titles === 'ALL' ? 'ALL' : 'SELECT';
  }

  async saveUser() {
    const { id, ...fields } = this.user;

    console.log(this.presetOptions);

    fields.allowedPresetsUpload = this.presetOptions.filter((p) => p.checked).map((p) => p.id);

    try {
      await this.$store.dispatch('userStore/saveUser', { userId: id, userData: fields });
      this.$buefy.snackbar.open({
        message: 'Gebruiker opgeslagen!',
        type: 'is-success',
      });
    } catch (err: unknown) {
      showError(err, 'Wijziging niet opgeslagen!');
    }
  }

  async deleteUser() {
    const { result } = await this.$buefy.dialog.confirm({
      title: 'Weet je het zeker?',
      message: 'Deze gebruiker zal geen toegang meer hebben tot deze omgeving.',
      confirmText: 'Verwijder',
      cancelText: 'Behoud',
      type: 'is-danger',
    });
    if (result) {
      await this.$store.dispatch('userStore/deleteUser', this.id);
      this.$router.push({ name: RouteName.AdminUsers });
      this.$buefy.snackbar.open({
        message: 'Gebruiker verwijderd!',
        type: 'is-success',
      });
    }
  }

  @Watch('userTitles')
  onUserTitlesChange(userTitles: 'ALL' | 'SELECT') {
    if (userTitles === 'ALL') {
      this.previouslySelectedTitles = this.user.titles !== 'ALL' ? this.user.titles : [];
      this.user.titles = 'ALL';
      return;
    }

    if (this.user.titles !== 'ALL' && this.user.titles.length >= 0) {
      // Don't reset selected titles
      return;
    }

    this.user.titles = this.previouslySelectedTitles;
  }
}
