import { CMYKColor, ILayout, IPage, IPageFonts, ITextElement } from '../../../models/ITemplate';
import { millimetersToPoints } from '../../../utils/conversion';
import { DEFAULT_SCALE } from '../../config';
import { FONTS } from '../../constants';

export const pageLayout: ILayout = {
  scale: DEFAULT_SCALE,
  width: 3508,
  height: 4961,

  // ___json_compatible_comment: 'these values are here for easy reference :)',
  // ___unused___InnerWidth: 3200,
  // ___unused___InnerHeight: 4856,

  // ___unused___nrOfColumnParts: 50,
  // ___unused___nrOfRowParts: 70,

  margin: {
    top: 256,
    right: 0,
    bottom: 0,
    left: 155,
  },
  columns: 40,
  cellPadding: {
    horizontal: 64,
    vertical: 64,
  },
  gridSize: 64,
};

export const pageFonts: IPageFonts = {
  header: 'HaasGroteskTextBold-Regular.otf',
  text: 'HaasGroteskDisplayRm-Rg.otf',
  textbold: 'HaasGroteskDisplayBd-Rg.otf',
  cell: 'HaasGroteskDisplayMd-Rg.otf',
};

export const mezzaFonts: IPageFonts = {
  header: FONTS.LFT_ETICA_BOLD,
  text: FONTS.LFT_ETICA_REGULAR,
  textbold: FONTS.LFT_ETICA_BOLD,
  cell: FONTS.LFT_ETICA_REGULAR,
};

export const colorCellLight = [0, 3, 5, 9] as CMYKColor;

export const pageIdentifier: [ITextElement] = [
  {
    type: 'text',
    value: '@content/text',
    contentId: 'pageIdentifier',
    color: [0, 0, 0, 0],
    fontSize: 25,
    height: 50,
    valign: 'center',
    box: {
      backgroundColor: [0, 0, 0, 100],
      height: 50,
      padding: {
        left: 4 / DEFAULT_SCALE,
        right: 4 / DEFAULT_SCALE,
      },
    },
  },
];

export const pageIdentifierTextOnly: [ITextElement] = [
  {
    type: 'text',
    value: '@content/text',
    contentId: 'pageIdentifier',
    color: [0, 0, 0, 0],
    align: 'right',
    width: 200,
    fontSize: 25,
  },
];

export const mezzaTemplates: IPage['templates'] = {
  header: {
    elements: [
      {
        type: 'text',
        font: '@page/settings/fonts/header',
        fontSize: 10 / DEFAULT_SCALE,
        color: [0, 0, 0, 0],
        left: 0,
        top: 0,
        baseline: 'top',
        characterSpacing: 0.8,
        box: {
          height: millimetersToPoints(4),
          padding: {
            left: 4 / DEFAULT_SCALE,
            right: 4 / DEFAULT_SCALE,
          },
          backgroundColor: [0, 0, 0, 100],
        },
      },
    ],
  },
  'solution-header': {
    elements: [
      {
        type: 'box',
        height: 128,
        backgroundColor: [0, 0, 0, 100],
      },
      {
        type: 'text',
        font: '@page/settings/fonts/header',
        fontSize: 40,
        color: [0, 0, 0, 0],
        top: 14,
        baseline: 'top',
        align: 'center',
        textTransform: 'first-capital',
      },
    ],
  },
  wordfind: {
    elements: [
      {
        type: 'wordfind',
        value: '@content/wordfind',
        font: '@page/settings/fonts/text',
        lineGap: 24,
        wordsHeight: 705,
        cellBorderWidth: 0.2 / DEFAULT_SCALE,
      },
    ],
  },
  crossword: {
    elements: [
      {
        type: 'crossword',
        font: '@page/settings/fonts/text',
        fontBold: '@page/settings/fonts/textbold',
        fontSize: 38,
      },
    ],
  },
  quento: {
    elements: [
      {
        log: true,
        type: 'text',
        font: '@page/settings/fonts/text',
        value: '@content/quento',
        fontSize: 38,
        lineGap: 10,
        color: [0, 0, 0, 100],
        left: 0,
        top: 0,
      },
      {
        type: 'quento',
        valign: 'bottom',
      },
    ],
  },
  koprol: {
    elements: [
      {
        type: 'text',
        font: '@page/settings/fonts/text',
        value: '@content/koprol',
        fontSize: 38,
        lineGap: 10,
        color: [0, 0, 0, 100],
        left: 0,
        top: 0,
      },
      {
        type: 'koprol',
        font: '@page/settings/fonts/text',
        valign: 'bottom',
      },
    ],
  },
  '0hn0': {
    elements: [
      {
        type: 'text',
        font: '@page/settings/fonts/text',
        value: '@content/0hn0',
        fontSize: 38,
        lineGap: 10,
        color: [0, 0, 0, 100],
        width: 689,
        left: 759,
      },
      {
        type: '0hn0',
      },
    ],
  },
  codekraker: {
    elements: [
      {
        type: 'codekraker',
        font: '@page/settings/fonts/text',
        fontBold: '@page/settings/fonts/textbold',
        fontSize: 34,
      },
    ],
  },
  'page-identifier': {
    elements: pageIdentifier,
  },
};
