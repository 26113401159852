














import { Component, PropSync, Vue } from 'vue-property-decorator';

import { ITentsTreesPreset } from '../../../../../functions/src/models/db/PuzzlePreset';

@Component
export default class TentsTreesPresetEditor extends Vue {
  @PropSync('presetData') preset!: ITentsTreesPreset;
}
