import { ITemplate } from '../../../models/ITemplate';
import config from '../components/config';
import { header } from '../components/layoutParts';
import { layout, settings } from '../components/pageParts';

const layoutTemplate: ITemplate = {
  page: {
    name: 'Margriet - 2 - 1',
    layout: layout,
    settings: {
      ...settings,
    },
    templates: {
      header: header as any,
    },
    elements: [
      // Sudoku 1
      {
        type: 'custom',
        name: 'header',
        value: '@content/header',
        contentId: 'content-1',
        top: 0,
        left: 64,
        width: 1792,
        height: 64,
      },
      {
        type: 'text',
        value: '@content/explanation',
        contentId: 'content-1',
        font: config.fontRegular,
        fontSize: config.fontSizeRegular,
        color: config.colorBlack,
        top: 64,
        left: 64,
        width: 1792,
        height: 32,
        baseline: 'top',
        align: 'center',
      },
      {
        type: 'stars',
        contentId: 'content-1',
        stars: '@content/difficulty',
        activeStar: 'margiet-ster.png',
        top: 140,
        left: 64,
        width: 800,
        height: 40,
        padding: 0,
        max: 4,
        align: 'left',
      },
      {
        type: '@content/renderer',
        contentId: 'content-1',
        top: 192,
        left: 64,
        width: 800,
        height: 800,
      },

      // Sudoku 2
      {
        type: 'stars',
        contentId: 'content-2',
        stars: '@content/difficulty',
        activeStar: 'margiet-ster.png',
        top: 140,
        left: 1056,
        width: 800,
        height: 40,
        padding: 0,
        max: 4,
        align: 'right',
      },
      {
        type: '@content/renderer',
        contentId: 'content-2',
        top: 192,
        left: 1056,
        width: 800,
        height: 800,
      },

      // Wordfind
      {
        type: 'custom',
        name: 'header',
        value: '@content/header',
        top: 1056,
        left: 64,
        width: 1792,
        height: 64,
        contentId: 'content-3',
      },
      {
        type: '@content/renderer',
        contentId: 'content-3',
        value: '@content/explanation',
        fontSize: config.fontSizeSmall,
        fontSizeFactor: 0.6,
        font: config.fontLight,
        top: 1152,
        left: 64,
        width: 1792,
        height: 960,
        columns: 2,
        lineGap: 1,
        // max 36 words
      },
    ],
  },
};

export default layoutTemplate;
