import { CMYKColor } from '../models/ITemplate';

/**
 * We're measuring at 300 dpi but PDF measures in points, who always have 72 dpi
 * To be able to use the numbers that Pixelmator outputs but output in the correct
 * pdf size, we multiply each number we use by this scale
 */
export const DEFAULT_SCALE = 72 / 300;

export const ADR = {
  HighlightHeaderBackgroundColor: [18, 94, 86, 0] as CMYKColor,
  NogNietUitgepuzzeldAD:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2Fverwijzing%20aD%20puzzel.png-1626170857162?alt=media&token=4c84d9e3-4760-4d3e-bd25-f80c0ebd3555',
  NogNietUitgepuzzeldRegio:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FNog%20niet%20uitgepuzzeld-algemeen-2.png-1626170851650?alt=media&token=5c6f54a8-5406-4188-971b-16b94a97893c',
  NogNietUitgepuzzeldWeekend:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FNog%20niet%20uitgepuzzeld-weekend300.png-1626170863972?alt=media&token=f43a6f51-f40c-4c31-94d2-a8f3c77568d7',
  SandersAdvertentie:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FSanders%20advertentie-v2.png-1626275611549?alt=media&token=de68f729-3318-4758-9c4c-09fa56b0ce17',
  PrizePuzzle:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FADR%20weekprijs-template1.jpg-1623422606096?alt=media&token=162c647a-5467-4827-977c-32850909f7e5',
};

export const Story = {
  BackgroundRight:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FStory-achtergrond-rechts_Tekengebied%201.png-1623311703394?alt=media&token=ec196ed8-87f2-4899-9a4b-2dc81f016942',
  BackgroundLeft:
    'https://firebasestorage.googleapis.com/v0/b/q42puzzels-test.appspot.com/o/media%2FStory-achtergrond-links_Tekengebied%201.png-1623311694910?alt=media&token=8e12d547-6579-4a58-b1ae-f6a7bb8eb6be',
};
