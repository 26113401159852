import { layoutIds } from '../layoutIds';
import layout2 from './adr-kruiswoord';
import layout2Content from './adr-kruiswoord-content.json';
import layoutPrize from './adr-prize';
import layoutPrizeContent from './adr-prize-content.json';
import layout1 from './adr-woordzoeker';
import layout1Content from './adr-woordzoeker-content.json';
import huisAanHuis11 from './huis-aan-huis_1-1';
import huisAanHuis11Content from './huis-aan-huis_1-1-content.json';
import huisaanhuis12 from './huis-aan-huis_1-2';
import huisaanhuis12Content from './huis-aan-huis_1-2-content.json';

const adrLayouts = [
  // initiele ADR
  {
    id: layoutIds.adrMetWoordzoeker,
    name: 'ADR met Woordzoeker',
    json: layout1,
    content: layout1Content,
  },
  {
    id: layoutIds.adrMetKruiswoord,
    name: 'ADR met Kruiswoord',
    json: layout2,
    content: layout2Content,
  },
  {
    id: layoutIds.adrMetPrijsBlok,
    name: 'ADR met Prijsblok',
    json: layoutPrize,
    content: layoutPrizeContent,
  },

  // huis aan huis
  {
    id: layoutIds.huisAanHuisHalf,
    name: 'Huis-aan-huis 1/2',
    json: huisaanhuis12,
    content: huisaanhuis12Content,
  },
  {
    id: layoutIds.huisAanHuisHeel,
    name: 'A3 - Huis-aan-huis 1/1',
    json: huisAanHuis11,
    content: huisAanHuis11Content,
  },
];

export default adrLayouts;
