



















import { Component, PropSync } from 'vue-property-decorator';

import { ISwedishCrosswordPreset } from '../../../../../functions/src/models/db/PuzzlePreset';
import { TypedVue } from '../../../../store/typed-vue';

@Component
export default class SwedishCrosswordPresetEditor extends TypedVue {
  @PropSync('presetData') preset!: ISwedishCrosswordPreset;
}
