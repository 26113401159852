// TODO production should use minified
// import "buefy/dist/buefy.css";
import 'buefy/dist/buefy.min.css';

import Buefy from 'buefy';
import Vue from 'vue';
import TreeView from 'vue-json-tree-view';
import { firestorePlugin } from 'vuefire';

import App from './App.vue';
import router from './router';
import store from './store';
import { clone } from './utils';

Vue.use(Buefy, {
  defaultProgrammaticPromise: true,
});

Vue.use(firestorePlugin);

Vue.use(TreeView);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['q42'];

// globally accessible functions
Vue.mixin({
  methods: {
    clone,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
