


























































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Loading from '@/components/Loading.vue';

import { SchedulerDb } from '../../../functions/src/models/db/Scheduler';
import { UserMixin } from '../../mixins/User';
import { Weekdays } from '../../models/Weekdays';
import { RouteName } from '../../router/RouteName';

const SchedulerStore = namespace('schedulerStore');

@Component({
  components: {
    Loading,
  },
})
export default class SchedulersOverview extends mixins(UserMixin) {
  @SchedulerStore.State('schedulers') schedulers!: SchedulerDb & { id: string }[];

  currentPage = 1;
  weekdays = Weekdays;
  loading = false;

  get routeName() {
    return RouteName;
  }

  async mounted() {
    this.loading = true;
    await this.$store.dispatch('schedulerStore/bindSchedulers', this.currentUserTitles);
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('schedulerStore/unbindSchedulers');
  }
}
