import AssetDetailsComponent from '../components/media/AssetDetails.vue';
import AssetOverviewComponent from '../components/media/AssetOverview.vue';
import mediaPageComponent from '../views/MediaPage.vue';
import { RouteName } from './RouteName';

export default [
  {
    path: '/media',
    component: mediaPageComponent,
    redirect: '/media/assets',
    children: [
      {
        name: RouteName.AssetOverview,
        path: 'assets',
        component: AssetOverviewComponent,
        meta: { title: 'Mediabibliotheek' },
      },
      {
        name: RouteName.AssetDetail,
        path: 'assets/:id',
        component: AssetDetailsComponent,
        meta: { title: 'Mediabibliotheek' },
      },
    ],
  },
];
