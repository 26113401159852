const block = [
  {
    type: 'custom',
    name: 'headerSmall',
    value: '@content/header',
    contentId: 'content-3',
    top: 2048,
    left: 1280,
    width: 1856,
  },
  {
    type: 'custom',
    name: 'difficulty',
    contentId: 'content-3',
    top: 2496,
    left: 1280,
    width: 864,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    top: 2592,
    left: 1280,
    width: 864,
    height: 864,
  },
  {
    type: 'custom',
    name: 'difficulty',
    contentId: 'content-4',
    top: 2496,
    left: 2272,
    width: 864,
  },
  {
    type: '@content/renderer',
    contentId: 'content-4',
    top: 2592,
    left: 2272,
    width: 864,
    height: 864,
  },
  {
    // Best effort for now...
    type: 'custom',
    name: 'headerSmall',
    contentId: 'play-more',
    value: '@content/text',
    top: 3476,
    left: 1280,
    width: 1856,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1248, y: 2048 },
          { x: 1248, y: 3552 },
        ],
      },
    ],
  },
];

export default block;
