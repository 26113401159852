import format from 'date-fns/format';
import { IPuzzlePageState, IState } from 'src/models/models';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';

import { UserTitles } from '../../../functions/src/models/db/Users';
import { db } from './../db';

const puzzlePageStore: Module<IPuzzlePageState, IState> = {
  namespaced: true,
  state: {
    puzzlePages: [],
    puzzlePageVersions: [],
  },
  getters: {},
  mutations: {},
  actions: {
    bindPuzzlePageAgenda: firestoreAction(({ bindFirestoreRef }, userTitles: UserTitles) => {
      if (userTitles.length <= 0) return;

      const baseQuery = db
        .collection('pages')
        .where('date', '>=', format(new Date(), 'yyyy-MM-dd'));
      const query =
        userTitles === 'ALL'
          ? baseQuery
          : baseQuery.where('schedulerTitles', 'array-contains-any', userTitles);

      return bindFirestoreRef('puzzlePages', query);
    }),
    bindPuzzlePageArchive: firestoreAction(
      (
        { bindFirestoreRef },
        { startdate, userTitles }: { startdate: Date; userTitles: UserTitles }
      ) => {
        if (userTitles.length <= 0) return;

        const baseQuery = db
          .collection('pages')
          .where('date', '<=', format(startdate, 'yyyy-MM-dd'));
        const query =
          userTitles === 'ALL'
            ? baseQuery
            : baseQuery.where('schedulerTitles', 'array-contains-any', userTitles);

        return bindFirestoreRef('puzzlePages', query.orderBy('date', 'desc').limit(100));
      }
    ),
    bindPuzzlePageVersions: firestoreAction(({ bindFirestoreRef }, schedulerId) =>
      bindFirestoreRef(
        'puzzlePageVersions',
        db
          .collection('pages')
          .where('schedulerId', '==', schedulerId)
          .orderBy('date', 'desc')
          .limit(20)
      )
    ),
    undbindPuzzlePages: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzlePages')
    ),
    undbindPuzzlePageVersions: firestoreAction(({ unbindFirestoreRef }) =>
      unbindFirestoreRef('puzzlePageVersions')
    ),
  },
};

export default puzzlePageStore;
