import { IStepLadderPreset } from './interfaces';

export const getDefaultPresetDataStepLadder = (): IStepLadderPreset => {
  return {
    renderer: 'stepladder',
    source: 'upload',
    name: 'stepladder',
    stock: { approved: 1, available: 1 },
    usage: {
      expectedWeeklyUse: 0,
    },
    needsApproval: false,
    generatorParams: {
      height: 7,
      width: 6,
    },
    rendererParams: {
      header: '',
      explanation: '',
    },
  };
};
