import { CMYKColor } from '../../../models/ITemplate';
import { DEFAULT_SCALE } from '../../config';

const config = {
  scale: DEFAULT_SCALE,
  width: 3152,
  height: 4416,
  gridSize: 16,
  fontRegular: 'MyriadPro-Regular.otf',
  fontBold: 'MyriadPro-Bold.otf',
  fontHandwriting: 'PenTipDTPro-BoldOblique.otf',
  fontHeader: 'FranklinGothicATF-Bold.otf',
  colorBlueBackground: [15, 0, 0, 0] as CMYKColor,
  colorBlack: [0, 0, 0, 100] as CMYKColor,
  colorWhite: [0, 0, 0, 0] as CMYKColor,
  colorGreyDark: [0, 0, 0, 50] as CMYKColor,
  colorGreyLight: [0, 0, 0, 15] as CMYKColor,
  fontSizeLarge: 14 / DEFAULT_SCALE,
  fontSizeMedium: 9 / DEFAULT_SCALE,
  fontSizeRegular: 8.65 / DEFAULT_SCALE,
  fontSizeSmall: 7.1224 / DEFAULT_SCALE,
};

export default config;
