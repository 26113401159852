








import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IWhoWhatWherePreset } from '../../../../../functions/src/models/puzzles/whowhatwhere/interfaces';

@Component
export default class QuentoPresetEditor extends Vue {
  @PropSync('presetData') preset!: IWhoWhatWherePreset;
}
