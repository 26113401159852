import config, { configSaturday } from './config';

export const layout = {
  scale: config.scale,
  width: config.width,
  height: config.height,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  columns: 1,
  gridSize: config.gridSize,
  cellPadding: {
    horizontal: config.gridSize,
    vertical: config.gridSize,
  },
};

export const settings = {
  solutionsFromOtherSchema: true,
  fonts: {
    header: config.fontBold,
    text: config.fontRegular,
    textbold: config.fontBold,
    cell: config.fontRegular,
    explanation: config.fontBold,
  },
};

export const layoutSaturday = {
  ...layout,
  height: configSaturday.height,
};
