








import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IGroterKleinerPreset } from '../../../../../functions/src/models/puzzles/groterkleiner/interfaces';

@Component
export default class GroterkleinerPresetEditor extends Vue {
  @PropSync('presetData') preset!: IGroterKleinerPreset;
}
