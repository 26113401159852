export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function assertNever(x: never, msg?: string): never {
  const log = typeof x === 'object' ? JSON.stringify(x) : x;
  throw new Error(msg ?? 'Unexpected type' + log);
}

export function clone<T>(obj: T): T {
  if (typeof obj === 'undefined') {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

export function filterDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type WithId<T> = { id: string } & T;
