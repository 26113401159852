import { PuzzlePresetDb } from '../models/db/PuzzlePreset';
import { PuzzlePresetContentItem } from '../models/db/PuzzlePresetContent';
import { BattleshipName } from '../models/puzzles/battleship/utils';
import { BlokjeOmName } from '../models/puzzles/blokjeom/utils';
import { CalcudokuName } from '../models/puzzles/calcudoku/utils';
import { GroterKleinerName } from '../models/puzzles/groterkleiner/utils';
import { HashiName } from '../models/puzzles/hashi/utils';
import { IkuraName } from '../models/puzzles/ikura/utils';
import { ImagePuzzleName } from '../models/puzzles/imagepuzzle/utils';
import { IPuzzleId } from '../models/puzzles/IPuzzles';
import { KakuroName } from '../models/puzzles/kakuro/utils';
import { PacManName } from '../models/puzzles/pacman/utils';
import { SamuraiSudokuName } from '../models/puzzles/samuraisudoku/utils';
import { SudokuMixName, SudokuTwinsName } from '../models/puzzles/sudokumix/utils';
import { TectonicName } from '../models/puzzles/tectonic/utils';
import { TentsTreesName } from '../models/puzzles/tentstrees/utils';
import { WhoWhatWhereName } from '../models/puzzles/whowhatwhere/utils';
import { WoordKrakerName } from '../models/puzzles/woordkraker/utils';
import { WordfindName } from '../models/puzzles/wordfind/utils';
import { assertNever } from '.';

export function getDisplayNameByRenderer(renderer: IPuzzleId | 'image' | 'text'): string {
  switch (renderer) {
    case 'wordfind':
      return WordfindName;
    case 'tectonic':
      return TectonicName;
    case 'quento':
      return 'Quento';
    case 'koprol':
      return 'Koprol';
    case '0hh1':
      return 'Binaire';
    case '0hn0':
      return '0h n0';
    case 'crossword':
      return 'Kruiswoord / Crypto';
    case 'sudoku':
      return 'Sudoku';
    case 'lettersudoku':
      return 'Letter Sudoku';
    case 'horoscopesudoku':
      return 'Sudoku Horoscoop';
    case 'tentstrees':
      return TentsTreesName;
    case 'battleship':
      return BattleshipName;
    case 'knightmove':
      return 'Paardensprong';
    case 'image':
      return 'Afbeelding';
    case 'text':
      return 'Tekst';
    case 'doorloper':
      return 'Doorloper';
    case 'codekraker':
      return 'Codekraker';
    case 'cryptofilippine':
      return 'Filippine tekst';
    case 'swedishcrossword':
      return 'Zweedse puzzel';
    case 'toevoeganagram':
      return 'Toevoeg Anagram';
    case 'samuraisudoku':
      return SamuraiSudokuName;
    case 'killersudoku':
      return 'Killer Sudoku';
    case 'filippine':
      return 'Filippine code';
    case 'jigsawCrossword':
      return 'Legpuzzel';
    case 'spotthedifferences':
      return 'Zoek de verschillen';
    case 'kakuro':
      return KakuroName;
    case 'calcudoku':
      return CalcudokuName;
    case 'hashi':
      return HashiName;
    case 'groterkleiner':
      return GroterKleinerName;
    case 'whowhatwhere':
      return WhoWhatWhereName;
    case 'blokjeom':
      return BlokjeOmName;
    case 'ikura':
      return IkuraName;
    case 'sudokutwins':
      return SudokuTwinsName;
    case 'sudokumix':
      return SudokuMixName;
    case 'woordkraker':
      return WoordKrakerName;
    case 'pacman':
      return PacManName;
    case 'imagepuzzle':
      return ImagePuzzleName;
    case 'stepladder':
      return 'stepladder';
    default:
      assertNever(renderer);
  }
}

export function getFileName(puzzlePreset: PuzzlePresetDb, puzzle: PuzzlePresetContentItem) {
  return `${puzzlePreset.name.replace(/\*/g, 'x')} - ${puzzle.id}.pdf`;
}

/**
 * replace IJ with a single character Ĳ, case sensitive!
 * previously used characters: @ and Ÿ
 */
export function singleCharY(c: string): string {
  // if (c.match(/[Ĳ@Ÿÿ]/)) {
  // logWarn('The original string has characters that are (or have been) used as IJ replacement')
  // }
  return c.replace(/(IJ|iJ|Ij)|[Ĳ@Ÿÿ]/g, 'Ĳ').replace(/ij/g, 'ĳ');
}

/**
 * replace Ĳ back to original IJ, case sensitive!
 * previously used characters: @ and Ÿ and ÿ
 */
export function multiCharY(c: string): string {
  return c.replace(/[Ĳ@Ÿÿ]/g, 'IJ').replace(/ĳ/g, 'ij');
}
