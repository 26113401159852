import { ITemplate } from '../../../models/ITemplate';
import { ADR, DEFAULT_SCALE } from '../../config';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import { pageFonts, pageIdentifierTextOnly, pageLayout } from '../components/generic';

const layout: ITemplate = {
  page: {
    layout: pageLayout,
    settings: {
      solutionsFromOtherSchema: true,
      solutionsForThisSchema: ['content-2-1', 'content-3-1', 'content-4-1', 'content-4-2'],
      fonts: pageFonts,
    },
    templates: {
      header: {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: [0, 20, 0, 72],
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 12,
            baseline: 'top',
          },
        ],
      },
      'highlight-header': {
        elements: [
          {
            type: 'box',
            height: 96,
            backgroundColor: ADR.HighlightHeaderBackgroundColor,
          },
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 60,
            color: [0, 0, 0, 0],
            left: 32,
            top: 12,
            baseline: 'top',
          },
        ],
      },
      'solution-header': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/header',
            fontSize: 25,
            height: 24,
            color: [0, 0, 0, 100],
            top: 0,
            baseline: 'middle',
          },
        ],
      },
      wordfind: {
        elements: [
          {
            type: 'wordfind',
            font: '@page/settings/fonts/text',
            value: '@content/wordfind',
            fontSize: 38,
            lineGap: 24,
            wordsHeight: 705,
          },
        ],
      },
      crossword: {
        elements: [
          {
            type: 'crossword',
            font: '@page/settings/fonts/text',
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 8 / DEFAULT_SCALE,
          },
        ],
      },
      quento: {
        elements: [
          {
            log: true,
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/quento',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'quento',
            valign: 'bottom',
          },
        ],
      },
      koprol: {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/koprol',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            left: 0,
            top: 0,
          },
          {
            type: 'koprol',
            font: '@page/settings/fonts/text',
            valign: 'bottom',
          },
        ],
      },
      '0hn0': {
        elements: [
          {
            type: 'text',
            font: '@page/settings/fonts/text',
            value: '@content/0hn0',
            fontSize: 38,
            lineGap: 10,
            color: [0, 0, 0, 100],
            width: 689,
            left: 759,
          },
          {
            type: '0hn0',
          },
        ],
      },
      codekraker: {
        elements: [
          {
            type: 'codekraker',
            font: '@page/settings/fonts/text',
            fontBold: '@page/settings/fonts/textbold',
            fontSize: 34,
          },
        ],
      },
      'page-identifier': {
        elements: pageIdentifierTextOnly,
      },
    },
    rows: [
      {
        height: 1024,
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'highlight-header',
                value: '@content/header',
                contentId: 'content-1-1',
                width: 1664,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                top: 128,
                contentId: 'content-1-1',
                width: 1664,
                height: 896,
                columns: 2,
                align: 'right',
                hideSolutionGrid: true,
              },
              {
                // __comment: 'floating image, does not have to be in grid system',
                type: 'image',
                path: '@content/image',
                contentId: 'brand-image',
                width: 600,
                height: 200,
                left: 5,
                top: 825,
              },
              {
                type: 'image',
                path: '@content/image',
                contentId: 'puzzleAndWin',
                width: 1460,
                height: 1024,
                left: 1728,
              },
            ],
          },
        ],
      },

      {
        height: 896,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: -32 },
                  { x: 3200, y: -32 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-2-1',
                width: 1664,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-2-1',
                height: 768,
                width: 3200,
                top: 128,
                align: 'right',
                offsetPuzzle: [-128, 0],
                cluesColumns: 2,
                lineGap: 10 * DEFAULT_SCALE,
              },
            ],
          },
        ],
      },

      {
        height: 896,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: -30 },
                  { x: 3200, y: -30 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              // Codekraker
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-3-1',
                width: 1280,
                left: 1920,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-3-1',
                renderType: 'grid',
                height: 896,
                width: 3200,
                align: 'left',
              },
              {
                type: 'text',
                value: '@content/explanation',
                contentId: 'content-3-1',
                font: '@page/settings/fonts/textbold',
                fontSize: 38,
                color: [0, 0, 0, 100],
                top: 128,
                left: 1920,
                width: 1280,
                height: 224,
                baseline: 'top',
              },
              {
                type: 'text',
                value: 'Streep hier de gevonden letters weg.',
                font: '@page/settings/fonts/text',
                baseline: 'top',
                align: 'center',
                fontSize: 38,
                color: [0, 0, 0, 100],
                top: 544,
                left: 1920,
                width: 1280,
                height: 64,
              },
              {
                type: '@content/renderer',
                contentId: 'content-3-1',
                renderType: 'letters',
                top: 640,
                left: 1928,
                width: 1264,
                height: 64,
              },
              {
                type: '@content/renderer',
                contentId: 'content-3-1',
                renderType: 'letterGrid',
                letterGridSize: [13, 2],
                valign: 'bottom',
                top: 672,
                left: 1920,
                width: 1280,
                height: 224,
                cluesColumns: 2,
                lineGap: 10 * DEFAULT_SCALE,
              },
            ],
          },
        ],
      },

      {
        height: 896,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: -32 },
                  { x: 3200, y: -32 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-1',
                width: 768,
              },
              {
                type: '@content/renderer',
                contentId: 'content-4-1',
                top: 128,
                left: 0,
                width: 768,
                height: 768,
                valign: 'top',
              },
              {
                type: 'custom',
                name: 'header',
                value: '@content/header',
                contentId: 'content-4-2',
                width: 1088,
                left: 832,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-4-2',
                height: 768,
                width: 1148,
                top: 128,
                left: 832,
                onlyText: true,
                useImagePuzzle: true,
              },
              {
                type: 'custom',
                name: '@content/renderer',
                contentId: 'content-4-2',
                height: 768,
                width: 1222,
                top: 128,
                left: 1978,
                offsetPuzzle: [-128, 0],
                onlyGrid: true,
              },
            ],
          },
        ],
      },

      {
        height: 512,
        elements: [
          {
            type: 'line',
            lines: [
              {
                coordinates: [
                  { x: 0, y: 0 },
                  { x: 3200, y: 0 },
                ],
              },
            ],
          },
        ],
        columns: [
          {
            colSpan: 1,
            elements: [
              {
                type: 'custom',
                name: 'header',
                value: 'OPLOSSINGEN PUZZELS AFGELOPEN VRIJDAG',
                width: 1500,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-3-1',
                width: 384,
                top: 128,
              },
              {
                type: '@content/renderer',
                top: 160,
                width: 352,
                height: 352,
                valign: 'top',
                contentId: 'solution-1-3-1',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-3-2',
                width: 352,
                top: 128,
                left: 416,
              },
              {
                type: '@content/renderer',
                top: 160,
                left: 416,
                width: 352,
                height: 352,
                valign: 'top',
                contentId: 'solution-1-3-2',
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-3-3',
                width: 832,
                top: 128,
                left: 832,
              },
              {
                type: '@content/renderer',
                top: 160,
                left: 832,
                width: 832,
                height: 352,
                valign: 'top',
                contentId: 'solution-1-3-3',
                fontSize: 25,
                solution: true,
              },
              {
                type: 'custom',
                name: 'solution-header',
                value: '@content/header',
                contentId: 'solution-1-2-1',
                width: 576,
                top: 128,
                left: 1728,
              },
              {
                type: '@content/renderer',
                top: 160,
                left: 1728,
                width: 576,
                height: 352,
                valign: 'top',
                contentId: 'solution-1-2-1',
                solution: true,
              },
              {
                type: 'image',
                path: '@content/image',
                contentId: 'advertising-1',
                width: (512 / 735) * 1280, // match aspect ratio of image
                height: 512,
                left: 3200 - (512 / 735) * 1280,
                top: 0.5,
              },
              {
                type: 'custom',
                name: 'page-identifier',
                top: 462,
                left: 2970,
              },
            ],
          },
        ],
      },
    ],
    elements: [
      // copyright wordfind
      getCopyrightLabelByOrigin(1024, 1824, 'content-1-1'),
      // copyright crypto
      getCopyrightLabelByOrigin(1984, 1856, 'content-2-1'),
      // copyright codekraker
      getCopyrightLabelByOrigin(2944, 2032, 'content-3-1'),
      // copyright kruiswoord
      getCopyrightLabelByOrigin(3904, 2096, 'content-4-2'),
      // copyright sudoku
      getCopyrightLabelByOrigin(3904, 928, 'content-4-1'),
    ],
  },
};

export default layout;
