import { AnyElement, ITemplate } from '../../../models/ITemplate';
import { getCopyrightLabelByOrigin } from '../../utils/getCopyRightByPuzzleOrigin';
import config from '../components/config';
import { layout, settings } from '../components/pageParts';

const lines: AnyElement[] = [
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 416 },
          { x: 3141, y: 416 },
        ],
      },
    ],
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1808 },
          { x: 3141, y: 1808 },
        ],
      },
    ],
  },

  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 12,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3240 },
          { x: 3141, y: 3240 },
        ],
      },
    ],
  },
];

const crossWord: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-1',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 448,
    left: 1272,
    width: 432,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 536 },
          { x: 3141, y: 536 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    onlyText: true,
    top: 552,
    left: 1272,
    width: 744,
    height: 1040,
    debug: true,
  },
  {
    type: '@content/renderer',
    contentId: 'content-1',
    blackCellColor: config.colorBlack,
    onlyGrid: true,
    top: 560,
    left: 2032,
    width: 1104,
    height: 1032,
  },
  {
    type: '@content/renderer',
    contentId: 'prize-banner',
    top: 1614,
    left: 1224,
    width: 1024,
    height: 160,
    debug: true,
  },
  getCopyrightLabelByOrigin(1592, 2032, 'content-1', 0),
];

const cijferCode: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 1888,
    left: 1280,
    width: 384,
    height: 64,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 1960 },
          { x: 3141, y: 1960 },
        ],
      },
    ],
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'letterGrid',
    letterGridSize: [2, 13], // cols, rows
    top: 1984,
    left: 1272,
    width: 168,
    height: 1072,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'grid',
    blackCellColor: config.colorBlack,
    top: 1984,
    left: 1488,
    width: 1648,
    height: 1072,
  },
  {
    type: '@content/renderer',
    contentId: 'content-2',
    renderType: 'letters',
    top: 3084,
    left: 1272,
    width: 1864,
    height: 56,
  },
  {
    type: 'text',
    contentId: 'content-2',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3152,
    left: 1272,
    width: 1864,
    height: 80,
  },
  getCopyrightLabelByOrigin(3056, 1272, 'content-2', 0),
];

const sudokuTwins: AnyElement[] = [
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/header',
    font: config.fontRegular,
    fontSize: config.fontSizeLarge,
    top: 3304,
    left: 1272,
    width: 520,
    height: 80,
  },
  {
    type: 'line',
    color: [0, 0, 0, 100],
    lineWidth: 1,
    lines: [
      {
        coordinates: [
          { x: 1272, y: 3392 },
          { x: 3141, y: 3392 },
        ],
      },
    ],
  },
  {
    type: 'text',
    contentId: 'content-3',
    value: '@content/explanation',
    font: config.fontRegular,
    fontSize: config.fontSizeSmall,
    top: 3448,
    left: 1272,
    width: 264,
    height: 500,
  },
  {
    type: '@content/renderer',
    contentId: 'content-3',
    showBorder: true,
    top: 3416,
    left: 1584,
    width: 1552,
    height: 936,
  },
  getCopyrightLabelByOrigin(4360, 1584, 'content-2', 0),
];

const solutions: AnyElement[] = [
  {
    type: 'text',
    value: 'OPLOSSINGEN',
    font: config.fontBold,
    fontSize: config.fontSizeLargeAlt,
    characterSpacing: config.letterSpacingLarge,
    rotation: 270,
    top: 0,
    left: 1272,
    width: 40,
    height: 380,
  },
  // crossword
  {
    type: 'text',
    contentId: 'solution-1-1',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1368,
    width: 384,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-1',
    solution: true,
    blackCellColor: config.colorBlack,
    top: 40,
    left: 1368,
    width: 384,
    height: 352,
  },
  // filippine
  {
    type: 'text',
    contentId: 'solution-1-2',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 1800,
    width: 728,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-2',
    solution: true,
    top: 40,
    left: 1800,
    width: 728,
    height: 352,
  },
  // sudoku twins
  {
    type: 'text',
    contentId: 'solution-1-3',
    value: '@content/header',
    font: config.fontBold,
    fontSize: config.fontSizeRegular,
    top: 0,
    left: 2552,
    width: 584,
    height: 32,
  },
  {
    type: '@content/renderer',
    contentId: 'solution-1-3',
    solution: true,
    top: 40,
    left: 2552,
    width: 584,
    height: 352,
  },
];

const layoutTemplate: ITemplate = {
  page: {
    name: 'Volkskrant - Maandag layout',
    layout: layout,
    settings: {
      solutionsForThisSchema: [],
      ...settings,
    },
    templates: {},
    elements: [...lines, ...solutions, ...crossWord, ...cijferCode, ...sudokuTwins],
  },
};

export default layoutTemplate;
