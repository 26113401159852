import { LayoutDb } from '../../models/db/Layout';
import { layoutIds } from '../layoutIds';
import mainContent7 from './content/hln-main-content-7';
import q42PageContent from './content/hln-q42';
import saturday1Content from './content/hln-sat-1-content';
import saturday2Content from './content/hln-sat-2-content';
import saturday3Content from './content/hln-sat-3-content';
import friday from './layout/hln-friday';
import fridayWithWed from './layout/hln-friday-with-wednesday-solutions';
import monday from './layout/hln-monday';
import mondayWithFri from './layout/hln-monday-with-friday-solutions';
import q42Page from './layout/hln-q42';
import saturday1 from './layout/hln-saturday-1';
import saturday1WithDo from './layout/hln-saturday-1-with-thursday-solutions';
import saturday2 from './layout/hln-saturday-2';
import saturday3 from './layout/hln-saturday-3';
import thursday from './layout/hln-thursday';
import thursdayWithTue from './layout/hln-thursday-with-tuesday-solutions';
import tuesday from './layout/hln-tuesday';
import tuesdayWithSat from './layout/hln-tuesday-with-saturday-solutions';
import wednesday from './layout/hln-wednesday';
import wednesdayWithMon from './layout/hln-wednesday-with-monday-solutions';

const hlnLayouts: LayoutDb[] = [
  {
    id: layoutIds.hlnMaandag,
    name: 'HLN - 1. Maandag',
    json: monday,
    content: mainContent7,
  },
  {
    id: layoutIds.hlnMaandagMetOplossingenVrijdag,
    name: 'HLN - 1. Maandag met oplossingen vrijdag',
    json: mondayWithFri,
    content: mainContent7,
    exceptionForLayoutId: layoutIds.hlnMaandag,
  },
  {
    id: layoutIds.hlnDinsdag,
    name: 'HLN - 2. Dinsdag',
    json: tuesday,
    content: { ...mainContent7, 'play-image': {} },
  },
  {
    id: layoutIds.hlnDinsdagMetOplossingenZaterdag,
    name: 'HLN - 10. Dinsdag met oplossingen zaterdag',
    json: tuesdayWithSat,
    content: mainContent7,
    exceptionForLayoutId: layoutIds.hlnDinsdag,
  },
  {
    id: layoutIds.hlnWoensdag,
    name: 'HLN - 3. Woensdag',
    json: wednesday,
    content: { ...mainContent7, 'play-image': {} },
  },
  {
    id: layoutIds.hlnWoensdagMetOplossingenMaandag,
    name: 'HLN - 3. Woensdag met oplossingen maandag',
    json: wednesdayWithMon,
    content: { ...mainContent7, 'play-image': {} },
    exceptionForLayoutId: layoutIds.hlnWoensdag,
  },
  {
    id: layoutIds.hlnDonderdag,
    name: 'HLN - 4. Donderdag',
    json: thursday,
    content: { ...mainContent7, 'play-image': {} },
  },
  {
    id: layoutIds.hlnDonderdagMetOplossingenDinsdag,
    name: 'HLN - 4. Donderdag met oplossingen dinsdag',
    json: thursdayWithTue,
    content: { ...mainContent7, 'play-image': {} },
    exceptionForLayoutId: layoutIds.hlnDonderdag,
  },
  {
    id: layoutIds.hlnVrijdag,
    name: 'HLN - 5. Vrijdag',
    json: friday,
    content: { ...mainContent7, 'play-image': {} },
  },
  {
    id: layoutIds.hlnVrijdagMetOplossingenWoensdag,
    name: 'HLN - 5. Vrijdag met oplossingen woensdag',
    json: fridayWithWed,
    content: { ...mainContent7, 'play-image': {} },
    exceptionForLayoutId: layoutIds.hlnVrijdag,
  },
  {
    id: layoutIds.hlnZaterdag1,
    name: 'HLN - 6. Zaterdag 1',
    json: saturday1,
    content: saturday1Content,
  },
  {
    id: layoutIds.hlnZaterdag2,
    name: 'HLN - 7. Zaterdag 2',
    json: saturday2,
    content: saturday2Content,
  },
  {
    id: layoutIds.hlnZaterdag3,
    name: 'HLN - 8. Zaterdag 3',
    json: saturday3,
    content: saturday3Content,
  },
  {
    id: layoutIds.hlnZaterdagMetOplossingenDonderdag,
    name: 'HLN - 6. Zaterdag 1 met oplossingen donderdag',
    json: saturday1WithDo,
    content: saturday1Content,
    exceptionForLayoutId: layoutIds.hlnZaterdag1,
  },

  {
    id: layoutIds.hlnQ42,
    name: 'HLN - 9. Q42 pagina',
    json: q42Page,
    content: q42PageContent,
    exceptionForLayoutId: layoutIds.hlnQ42,
  },
];

export default hlnLayouts;
