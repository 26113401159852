import { layoutIds } from '../layoutIds';
import prizepuzzleFri from './layout/prizepuzzle-friday';
import prizepuzzleFriWithWed from './layout/prizepuzzle-friday-with-wednesday-solutions';
import prizepuzzleFridayContent from './content/prizepuzzle-friday-content.json';
import prizepuzzleMon from './layout/prizepuzzle-monday';
import prizepuzzleMondayContent from './content/prizepuzzle-monday-content.json';
import prizepuzzleSatMonthPrize from './layout/prizepuzzle-sat-monthprize';
import prizepuzzleSatMonthPrizeContent from './content/prizepuzzle-sat-monthprize-content.json';
import prizepuzzleSatNoPrize from './layout/prizepuzzle-sat-noprize';
import prizepuzzleSatNoPrizeContent from './content/prizepuzzle-sat-noprize-content.json';
import prizepuzzleSatWeekendPrize from './layout/prizepuzzle-sat-weekendprize';
import prizepuzzleSatWeekendPrizeContent from './content/prizepuzzle-sat-weekendprize-content.json';
import prizepuzzleSatWeekPrize from './layout/prizepuzzle-sat-weekprize';
import prizepuzzleSatWeekPrizeContent from './content/prizepuzzle-sat-weekprize-content.json';
import prizepuzzleThu from './layout/prizepuzzle-thursday';
import prizepuzzleThuContent from './content/prizepuzzle-thursday-content.json';
import prizepuzzleTue from './layout/prizepuzzle-tuesday';
import prizepuzzleTueContent from './content/prizepuzzle-tuesday-content.json';
import prizePuzzleTueWithFri from './layout/prizepuzzle-tuesday-with-friday-solutions';
import prizepuzzleWed from './layout/prizepuzzle-wednesday';
import prizepuzzleWedWithFri from './layout/prizepuzzle-wednesday-with-friday-solutions';
import prizepuzzleWedContent from './content/prizepuzzle-wednesday-content.json';

const adrPrizePuzzleLayouts = [
  {
    id: layoutIds.adrPrijspuzzelMaandag,
    name: 'ADR prijspuzzel - Maandag',
    json: prizepuzzleMon,
    content: prizepuzzleMondayContent,
  },
  {
    id: layoutIds.adrPrijspuzzelDinsdag,
    name: 'ADR prijspuzzel - Dinsdag',
    json: prizepuzzleTue,
    content: prizepuzzleTueContent,
  },
  {
    id: layoutIds.adrPrijspuzzelDinsdagMetVrijdagOplossingen,
    name: 'ADR prijspuzzel - Dinsdag met vrijdag oplossingen',
    json: prizePuzzleTueWithFri,
    content: prizepuzzleTueContent,
    exceptionForLayoutId: layoutIds.adrPrijspuzzelDinsdag,
  },
  {
    id: layoutIds.adrPrijspuzzelWoensdag,
    name: 'ADR prijspuzzel - Woensdag',
    json: prizepuzzleWed,
    content: prizepuzzleWedContent,
  },
  {
    id: layoutIds.adrPrijspuzzelWoensdagMetVrijdagOplossingen,
    name: 'ADR prijspuzzel - Woensdag met vrijdag oplossingen',
    json: prizepuzzleWedWithFri,
    content: prizepuzzleWedContent,
    exceptionForLayoutId: layoutIds.adrPrijspuzzelWoensdag,
  },
  {
    id: layoutIds.adrPrijspuzzelDonderdag,
    name: 'ADR prijspuzzel - Donderdag',
    json: prizepuzzleThu,
    content: prizepuzzleThuContent,
  },
  {
    id: layoutIds.adrPrijspuzzelVrijdag,
    name: 'ADR prijspuzzel - Vrijdag',
    json: prizepuzzleFri,
    content: prizepuzzleFridayContent,
  },
  {
    id: layoutIds.adrPrijspuzzelVrijdagMetWoensdagOplossingen,
    name: 'ADR prijspuzzel - Vrijdag met woensdag oplossingen',
    json: prizepuzzleFriWithWed,
    content: prizepuzzleFridayContent,
    exceptionForLayoutId: layoutIds.adrPrijspuzzelVrijdag,
  },
  {
    id: layoutIds.adrMagazineZonderPrijs,
    name: 'ADR Magazine - Zonder Prijs',
    json: prizepuzzleSatNoPrize,
    content: prizepuzzleSatNoPrizeContent,
  },
  {
    id: layoutIds.adrMagazineWeekPrijs,
    name: 'ADR Magazine - Week Prijs',
    json: prizepuzzleSatWeekPrize,
    content: prizepuzzleSatWeekPrizeContent,
  },
  {
    id: layoutIds.adrMagazineWeekendPrijs,
    name: 'ADR Magazine - Weekend Prijs',
    json: prizepuzzleSatWeekendPrize,
    content: prizepuzzleSatWeekendPrizeContent,
  },
  {
    id: layoutIds.adrMagazineMaandPrijs,
    name: 'ADR Magazine - Maand Prijs',
    json: prizepuzzleSatMonthPrize,
    content: prizepuzzleSatMonthPrizeContent,
  },
];

export default adrPrizePuzzleLayouts;
