















































































































































































import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

import BreadCrumbs from '@/components/BreadCrumbs.vue';
import PreviewPageServerside from '@/components/PreviewPageServerside.vue';
import ActionLogEntry from '@/components/print/ActionLogEntry.vue';
import PageContentChecker from '@/components/print/PageContentChecker.vue';

import { PageDb } from '../../../functions/src/models/db/Page';
import { TitleDb } from '../../../functions/src/models/db/Titles';
import { getPageActionMessage } from '../../../functions/src/models/db/UserActionLogs';
import { Content } from '../../../functions/src/models/puzzles/Content';
import { contentArrayIsAssigned } from '../../../functions/src/models/TypeGuards';
import { getVariantContent } from '../../../functions/src/utils/variants';
import { UserMixin } from '../../mixins/User';
import { db, functions } from '../../store/db';
import { auth } from '../../store/db';
import { functionsUrl } from '../../store/firestore-config';
import { showError } from '../../utils/errors';
import { addUserActionLog } from '../../utils/userActionLogs';
import NoAccess from '../NoAccess.vue';

@Component({
  components: {
    PreviewPageServerside,
    BreadCrumbs,
    PageContentChecker,
    ActionLogEntry,
    NoAccess,
  },
})
export default class PuzzlePageDetail extends mixins(UserMixin) {
  @Prop() id!: string;

  loading = true;
  previewIsRefreshing = false;
  activeTab = 0;
  activePagesTab = 0;
  activeVariantTab = 0;
  variantContent: Content[] = [];
  token: string | null = null;
  assignPuzzlesLoading = false;
  reloadPreview = 0;
  downloadAsZip = false;

  setDownloadAsZip() {
    this.downloadAsZip = true;
    this.setTokenForDownload();
  }

  async setPageStatusInitial() {
    await db
      .collection('pages')
      .doc(this.id)
      .update({ status: 'initial' } as Partial<PageDb>);

    await addUserActionLog({
      actionType: 'resetPageToUnused',
      user: auth.currentUser?.email ?? '',
      schedulerId: this.pp!.schedulerId,
      publicationDate: this.pp!.date,
    });
  }

  setDownloadAsPdf() {
    this.downloadAsZip = false;
    this.setTokenForDownload();
  }

  async refreshPreview() {
    this.previewIsRefreshing = true;
    await functions.httpsCallable('invalidatePdfCacheOfSinglePage')({ pageId: this.pp.id });
    window.location.reload();
  }

  async setTokenForDownload() {
    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }
  }

  get pp(): PageDb {
    return this.$store.state.puzzlePageDetail;
  }

  get userHasAccess() {
    if (this.loading) return true;

    return (
      this.currentUserTitles === 'ALL' ||
      this.pp.schedulerTitles.some((title) => this.currentUserTitles.includes(title))
    );
  }

  get getPageActionMessage() {
    return getPageActionMessage;
  }

  get userActionLogs() {
    return this.$store.state.pageUserActionLogs;
  }

  get titles(): TitleDb[] {
    return this.$store.state.titles;
  }

  get variantsArray() {
    const pp = this.pp;
    if (!pp) {
      return [];
    }

    return Object.keys(pp.variants)
      .sort()
      .map((key) => {
        return { key, content: getVariantContent(pp, key) };
      });
  }

  get isHistory() {
    if (!this.pp) {
      return false;
    }

    const dt = this.pp.date;
    return dt < format(new Date(), 'yyyy-MM-dd');
  }

  get isUsed() {
    if (!this.pp) {
      return false;
    }

    return this.pp.status === 'used';
  }

  get hasWarnings() {
    if (!this.pp || !this.pp.renderWarnings || !this.pp.renderWarnings.length) {
      return false;
    }
    return true;
  }

  get hasVariants() {
    if (!this.pp) {
      return false;
    }
    return Object.keys(this.pp?.variants).length > 0;
  }

  get contentArrayIsAssigned() {
    if (!this.pp) {
      return false;
    }

    return contentArrayIsAssigned(this.pp.content);
  }

  get date() {
    if (!this.pp) {
      return '';
    }

    const dt = this.pp.date;

    if (!dt) {
      return '';
    }

    return format(new Date(dt), 'EEEE d MMMM yyyy', { locale: nl });
  }

  get downloadUrl() {
    return `${functionsUrl}downloadPuzzlePage`;
  }

  get variantsActive() {
    if (!this.pp) {
      return false;
    }

    return Object.keys(this.pp.variants).length > 1;
  }

  getVariantDisplayName(key: string) {
    const title = this.titles.find((title) => title.id === key);
    return title ? title.name : key;
  }

  async assignPuzzles() {
    try {
      this.assignPuzzlesLoading = true;
      await functions.httpsCallable('assignPuzzlesRest')({ id: this.id });
      this.$buefy.snackbar.open({
        message: 'Puzzels aangemaakt!',
        type: 'is-success',
      });
    } catch (err) {
      showError(err);
    }
    this.assignPuzzlesLoading = false;
  }

  get activeVariant() {
    if (!this.variantsArray || !this.variantsArray[this.activeVariantTab]) {
      return null;
    }
    return this.variantsArray[this.activeVariantTab].key;
  }

  async mounted() {
    await this.$store.dispatch('bindPuzzlePageDetail', this.id);
    await this.$store.dispatch('bindTitles');
    await this.$store.dispatch('bindPageUserActions', {
      schedulerId: this.pp?.schedulerId,
      date: this.pp?.date,
    });
    this.loading = false;
  }

  beforeDestroy() {
    this.$store.dispatch('unbindPuzzlePageDetail');
    this.$store.dispatch('unbindTitles');
  }
}
