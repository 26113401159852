










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { PageDb } from '../../functions/src/models/db/Page';
import { IPage } from '../../functions/src/models/ITemplate';
import { auth, storage } from '../store/db';
import { db, env, functionsUrl } from '../store/firestore-config';

@Component
export default class PreviewPage extends Vue {
  // Or have data to preview (templates, schedulers)
  @Prop() page: (PageDb & { id: string }) | undefined;
  @Prop() pages: (IPage | undefined)[] | undefined;
  @Prop() reload!: number;
  @Prop() pageNr!: number;
  @Prop() variant: string | undefined;

  token = '';
  loading = true;
  initializing = true;
  random = Math.random();
  cacheUrl: string | null = null;

  get isLocal() {
    return env === 'LOCAL' || db === 'EMULATOR';
  }

  get previewUrl() {
    if (!this.page || !this.token) {
      return undefined;
    }

    const url = new URL(
      `${functionsUrl}previewPage#toolbar=1&navpanes=0&pagemode=none&statusbar=0`
    );
    url.searchParams.append('token', this.token);
    url.searchParams.append('pageId', this.page.id);
    url.searchParams.append('pageNr', this.pageNr.toString());
    this.variant && url.searchParams.append('variant', this.variant);

    return url.toString();
  }

  get cacheFilename() {
    return this.page?.pdfCache?.find(
      (p) => p.pageIndex === this.pageNr && (!p.variant || p.variant === this.variant)
    )?.filename;
  }

  async mounted() {
    if (auth.currentUser) {
      this.token = await auth.currentUser.getIdToken();
    }

    await this.getPreviewUrl();
  }

  onLoad() {
    // Wait some time for the PDF preview to render
    setTimeout(() => (this.loading = false), 500);
  }

  @Watch('cacheFilename')
  async getPreviewUrl() {
    this.loading = true;

    if (this.cacheFilename) {
      const ref = storage.ref(this.cacheFilename);
      const downloadUrl = await ref.getDownloadURL();

      this.cacheUrl = `${downloadUrl}#toolbar=1&navpanes=0&pagemode=none&statusbar=0`;
    } else {
      this.previewUrl && (await fetch(this.previewUrl));
    }

    this.initializing = false;
  }
}
