














import { Component, PropSync, Vue } from 'vue-property-decorator';

import { IBlokjeOmPreset } from '../../../../../functions/src/models/puzzles/blokjeom/interfaces';

@Component
export default class BlokjeOmPresetEditor extends Vue {
  @PropSync('presetData') preset!: IBlokjeOmPreset;
}
